const REDIRECTS = [
  { from: '/customer-benefits', to: '/localflex', code: 301, isValid: true },
  { from: '/hotsale', to: '/outlet', code: 301, isValid: true },
  {
    from: '/buen-fin-viajes',
    to: '/buen-fin',
    code: 301,
    isValid: process.env.APP_URL !== process.env.APP_URL_ES_MX,
  },
  {
    from: '/landingz/caza-de-auroras-grupo-en-espanol',
    to: '/404',
    code: 301,
    isValid: process.env.APP_URL !== process.env.APP_URL_ES_MX,
  },
  {
    from: '/landing/tours-en-vancouver-con-vuelos-desde-guadalajara',
    to: '/404',
    code: 301,
    isValid: process.env.APP_URL !== process.env.APP_URL_ES_MX,
  },
]

/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default (req, res, next) => {
  const url = req.url.split('?')[0]
  const urlParams = req.url.includes('?') ? '?' + req.url.split('?')[1] : null
  const redirect = REDIRECTS.find((t) => t.from === url && t.isValid)

  if (redirect) {
    const newLocation = urlParams ? redirect.to + urlParams : redirect.to

    res.writeHead(redirect.code, { Location: newLocation })
    return res.end()
  } else {
    return next()
  }
}
