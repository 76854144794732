import load from '@/utils/load-promise'

export default (ctx, inject) => {
  const version = 'v1'

  const openPay = {
    instance: null,
    loaded: false,
    device_data_id: null,
    init: () => {
      openPay.loaded = true
      let limit = 30

      const interval = setInterval(() => {
        if (window.OpenPay) {
          clearInterval(interval)
          const sandbox = 'false' === 'true'

          window.OpenPay.setId('myprtbvnjupaqixjhc35')
          window.OpenPay.setApiKey('pk_52ee935922d441de9e29d44ca78e80f5')
          window.OpenPay.setSandboxMode(sandbox)

          openPay.instance = window.OpenPay

          load
            .js(
              `https://js.openpay.mx/openpay-data.${version}.min.js`,
              'openpay-data-src',
              'defer'
            )
            .then(() => {
              openPay.device_data_id = window.OpenPay.deviceData.setup(
                'form-payment'
              )
            })
            .catch(() => {})
        } else {
          limit--
        }

        if (limit === 0) {
          clearInterval(interval)
        }
      }, 200)
    },
    load: () => {
      if (!openPay.loaded) {
        load
          .js(
            `https://js.openpay.mx/openpay.${version}.min.js`,
            'openpay-src',
            'defer'
          )
          .then(() => openPay.init())
          .catch(() => {})
      }
    },
  }

  inject('openPay', openPay)
}
