export default ({ isHMR, store, route, redirect, params }) => {
  if (
    /** isHMR || process.static || */ route.name !== 'invitation-referral_code'
  )
    return

  const query = route.query || {}
  const openWizard = query.dialog === 'findMyTour'

  if (store.state.auth.loggedIn && !openWizard) {
    redirect(`/user/profile`)
  }
}
