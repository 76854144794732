export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  levels: [],
})

export const mutations = {
  SET_INIT: (state, data) => {
    state.levels = []
  },
  SET_LEVELS: (state, data) => {
    state.levels = data
  },
}

export const getters = {}

export const actions = {
  async getLevels({ commit }) {
    const { data } = await this.$axios.get(`/api/levels/1`)
    commit('SET_LEVELS', data)
  },
}
