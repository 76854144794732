import Cookies from 'js-cookie'
// import {
//   chatSendMessage,
//   chatFetchList,
//   chatMarkMessagesAsRead,
//   chatTranslate,
//   loadNotifications,
//   chatFetchAside,
//   chatSendAddChateable,
// } from './../api'

import { getMessageFromResponse } from '@/components/frontends/Messenger/utils/index'
// import { initEcho } from '@/components/frontends/Messenger/utils/pusher'

const dayjs = require('dayjs')
const DEFAULT_AVATAR_CHAT_USER =
  'https://cdn.localadventures.io/traveler_avatar.jpg'

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  default_avatar: {
    src: DEFAULT_AVATAR_CHAT_USER,
    srcset: DEFAULT_AVATAR_CHAT_USER,
  },
  notifications: [] /** listado de notificaciones del sistema */,
  unread: [] /** notificaciones sin leer */,

  messenger: {
    groups: [],
    total_groups: -1,
    show_aside: !!Cookies.get('lad-messenger-show-aside'),
    show_modal_auth: !!Cookies.get('lad-messenger-auth-login'),
    is_new_visit: !!Cookies.get('lad-messenger-new-visit'),
    tabs: {
      items: {
        messages: ['user', 'group', 'question'],
        // reservation: ['chat'],
        // trending: ['trending'],
        // support: ['support'],
      },
      current: 'messages',
    },
    floating: {
      open: false,
      parent: {
        uuid: null,
      },
    },
  },
})

export const mutations = {
  INIT_GROUP: (state, data) => {
    if (
      !!data.group.uuid &&
      !state.messenger.groups.some((t) => t.uuid === data.group.uuid)
    ) {
      state.messenger.groups.push({
        uuid: data.group.uuid /** identificador del grupo */,
        loading: false /** cargando mensajes, haciendo traducciones, etc */,
        visible: true /** define si el grupo esta visible */,
        scroll: 0 /** actualizando scroll del grupo de mensajes para que salga visible siempre el ultimo mensaje */,
        page: Reflect.has(data.group, 'page')
          ? data.group.page
          : 0 /** paginado de items */,
        /** @todo revisar bien este calculo que se esta haciendo aqui */
        unread: 0 /** qty de elementos que estan sin leer */,
        ave_response: 0 /** tiempo promedio de respuesta del proveedor */,
        is_public: false /** define si un grupo es publico/privado */,
        type: data.group.type ? data.group.type : 'user',
        typing: {
          event: true /** se lanza evento de que alguien esta escribiendo */,
          value: null /** nombre del usuario que esta escribiendo */,
        },
        header: {
          title: null /** conversacion con... */,
          user: {
            /** uuid del usuario principal de la conversacion */ uuid: null,
          },
          avatar: {
            /** imagen del usuario o grupo */ src: DEFAULT_AVATAR_CHAT_USER,
            srcset: null,
          },
        },
        items: [] /** listado de mensajes */,
        users: [] /** listado de usuarios que estan en la conversacion */,
        last_message: {
          uuid: 1,
          message: null,
          datetime: dayjs(),
        } /** ultimo chat here */,
      })
    }

    state.messenger.total_groups = state.messenger.groups.length
  },
  SYNC_GROUP: (state, data) => {
    data.groups.forEach((group, i) => {
      if (state.messenger.groups.some((t) => t.uuid === group.uuid)) {
        const item = state.messenger.groups.find((t) => t.uuid === group.uuid)

        if (Reflect.has(group, 'title')) item.title = group.title
        if (Reflect.has(group, 'unread')) item.unread = group.unread

        item.header.avatar.src = group.picture.thumbnail
          ? group.picture.thumbnail
          : DEFAULT_AVATAR_CHAT_USER
        item.last_message = {
          uuid: group.last_message.uuid,
          message: group.last_message.message,
          datetime: dayjs(group.last_message.datetime),
        }
      } else {
        state.messenger.groups.push({
          uuid: group.uuid /** identificador del grupo */,
          loading: false /** cargando mensajes, haciendo traducciones, etc */,
          visible: false /** define si el grupo esta visible */,
          scroll: 0 /** actualizando scroll del grupo de mensajes para que salga visible siempre el ultimo mensaje */,
          page: 0 /** paginado de items */,
          unread: group.unread /** qty de elementos que estan sin leer */,
          ave_response:
            group.ave_response /** tiempo promedio de respuesta del proveedor */,
          is_public: !!group.is_public /** define si un grupo es publico/privado */,
          type: group.type,
          typing: {
            event: true /** se lanza evento de que alguien esta escribiendo */,
            value: null /** nombre del usuario que esta escribiendo */,
          },
          header: {
            title: group.title,
            user: {
              /** uuid del usuario principal de la conversacion */
              uuid: group.owner.uuid,
            },
            avatar: {
              /** imagen del usuario o grupo */
              src: group.picture.thumbnail
                ? group.picture.thumbnail
                : DEFAULT_AVATAR_CHAT_USER,
              srcset: null,
            },
          },
          items: [] /** listado de mensajes */,
          users: [] /** listado de usuarios que estan en la conversacion */,
          last_message: {
            uuid: group.last_message.uuid,
            message: group.last_message.message,
            datetime: dayjs(group.last_message.datetime),
          } /** ultimo chat here */,
        })
      }
    })

    state.messenger.groups.sort(
      (a, b) =>
        new Date(b.last_message.datetime) - new Date(a.last_message.datetime)
    )
    state.messenger.total_groups = state.messenger.groups.length
  },
  RESET_GROUPS: (state) => {
    state.messenger.groups = []
    state.messenger.total_groups = -1
  },
  UPDATE_GROUP_INFO: (state, data) => {
    /** diferenciando sincronizacion en 2do plano */
    if (!(Reflect.has(data, 'visible') && data.visible === -1)) {
      state.messenger.groups.map((t) => (t.visible = t.uuid === data.uuid))
    }

    const group = state.messenger.groups.find((t) => t.uuid === data.uuid)
    const privates = ['uuid', 'visible']

    /** @todo en la sync no esta encontrando el grupo */
    if (!group) return false

    /** para cuando se quiere regresar del chat al aside */
    if (Reflect.has(data, 'visible') && typeof data.visible === 'boolean') {
      group.visible = data.visible
    }

    /** reset del paginado de mensajes */
    if (!group.visible) {
      group.page = 0
    }

    /** actualizando propiedades del group */
    Object.keys(data)
      .filter((t) => !privates.includes(t))
      .forEach((t) => {
        if (Reflect.has(group, t)) {
          switch (typeof group[t]) {
            case 'object':
              /** cuando llega un nuevo ultimo mensaje se suma a la cola de los no leidos cuando no esta visible el grupo */
              if (t === 'last_message') {
                data[t].datetime = dayjs(data[t].datetime)

                if (!group.visible && group[t].uuid !== data[t].uuid) {
                  group.unread++
                }
              }
              group[t] = Object.assign(group[t], data[t])
              break
            default:
              group[t] = data[t]
              break
          }
        }

        /** adicionando avatar por default */
        if (t === 'header' && !group.header.avatar.src) {
          group.header.avatar.src = DEFAULT_AVATAR_CHAT_USER
        }
      })
  },
  SYNC_GROUP_AVE_RESPONSE: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)

    group.ave_response = data.ave_response
  },
  SYNC_GROUP_USERS: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)

    data.users.forEach((user) => {
      if (group.users.some((t) => t.uuid === user.uuid)) {
        /** actualizando posible datos de usuario */
        const item = group.users.find((t) => t.uuid === user.uuid)

        item.profile = {
          firstname: user.profile.firstname,
          lastname: user.profile.lastname,
        }
        item.role = user.role ? user.role : 'traveler'
      } else {
        /** adicionando nuevo usuario al grupo */
        group.users.push({
          uuid: user.uuid,
          profile: {
            firstname: user.profile.firstname,
            lastname: user.profile.lastname,
          },
          role: user.role ? user.role : 'traveler',
        })
      }
    })

    /** eliminando usuarios del grupo */
    if (Reflect.has(data, 'remove') && data.remove.length > 0) {
      group.users = group.users.filter((t) => !data.remove.includes(t.uuid))
    }
  },
  SYNC_GROUP_ITEMS: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)
    let unread = 0

    /** @todo en la sync no esta encontrando el grupo */
    if (!group) return false

    data.items.forEach((chat) => {
      if (group.items.some((t) => t.uuid === chat.uuid)) {
        /** actualizando posible datos del mensaje */
        const item = group.items.find((t) => t.uuid === chat.uuid)

        /** no cambia de `read` a `unread` */
        if (item.status !== chat.status && chat.status === 'read') {
          unread--
        }

        if (Reflect.has(chat, 'message')) item.message = chat.message
        if (Reflect.has(chat, 'status')) item.status = chat.status
        if (Reflect.has(chat, 'read_by')) item.read_by = chat.read_by
      } else {
        /** adicionando nuevo usuario al grupo */
        if (chat.status === 'unread') {
          unread++
        }
        group.items.push({
          uuid: chat.uuid,
          message: chat.message,
          type: chat.type,
          status: chat.status,
          created_at: chat.created_at,
          owner: chat.owner,
          read_by: chat.read_by ? chat.read_by : [],
          _user: chat._user,
        })
      }
    })

    /** eliminando conversaciones del grupo */
    if (Reflect.has(data, 'remove') && data.remove.length > 0) {
      group.items = group.items.filter((t) => !data.remove.includes(t.uuid))
    }

    group.items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

    /** actualizando mensajes sin leer */
    if (group.visible) {
      group.unread += unread
    }
  },
  SET_GROUP_LOADING: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)

    if (typeof group === 'object') group.loading = data.loading
  },
  SET_READ_GROUP: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)
    group.items.map((t) => (t.status = 'read'))

    /** mantengo esta parte para compatibilidad con notificaciones */
    const notification = state.notifications.find(
      (t) => t.data.group.uuid === data.group.uuid
    )
    if (notification) {
      notification.data.message.status = 'read'

      state.unread = state.unread.filter((t) => t !== data.group.uuid)
    }

    /** actualizando mensajes sin leer */
    group.unread = 0
  },
  SET_GROUP_SCROLL: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)

    if (typeof group === 'object') group.scroll++
  },
  INCREMENT_GROUP_PAGE: (state, data) => {
    const group = state.messenger.groups.find((t) => t.uuid === data.group.uuid)

    if (typeof group === 'object') group.page++
  },

  ADD_NOTIFICATION_FROM_GROUP: (state, data) => {
    state.notifications = state.notifications.filter(
      (t) => t.data.group.uuid !== data.group.uuid
    )

    const message = data.items[data.items.length - 1]
    const user = Reflect.has(data, 'user') ? data.user : data.items[0]._user

    state.notifications.unshift({
      type: 'chat',
      data: {
        message: {
          uuid: message.uuid,
          message: message.message,
          type: message.type,
          status: message.status,
          created_at: message.created_at,
          user: {
            uuid: user.uuid,
            firstname: user.profile.firstname,
            lastname: user.profile.lastname,
          },
        },
        group: {
          uuid: data.group.uuid,
        },
        chateable: {
          uuid: data.chateable.uuid,
        },
      },
    })
  },
  ADD_MULTIPLES_NOTIFICATIONS: (state, items) => {
    state.notifications = state.notifications.concat(items)
  },
  SET_NOTIFICATIONS: (state, data) => {
    state.notifications = data
  },
  UPDATE_UNREAD_ITEMS: (state, data = []) => {
    // state.unread = [...new Set([].concat(state.unread, data))]
    state.unread = { ...state.unread, ...data }
  },

  SHOW_MESSENGER_ASIDE: (state, data) => {
    state.messenger.show_aside = !!data
    Cookies.set('lad-messenger-show-aside', data)
  },
  SHOW_MESSENGER_MODAL_AUTH: (state, data) => {
    state.messenger.show_modal_auth = !!data

    if (state.messenger.show_modal_auth) {
      Cookies.set('lad-messenger-auth-login', data)
    } else {
      Cookies.remove('lad-messenger-auth-login')
    }
  },
  IS_NEW_VISIT: (state, data) => {
    state.messenger.is_new_visit = !!data
    Cookies.set('lad-messenger-new-visit', data)
  },

  CHANGE_CURRENT_TAB: (state, data) => {
    state.messenger.tabs.current = data
  },

  SWITCH_FLOATING_OPEN: (state, data) => {
    state.messenger.floating.open =
      typeof data === 'undefined' ? !state.messenger.floating.open : data

    if (!state.messenger.floating.open) {
      state.messenger.groups.map((t) => {
        t.visible = false
        t.page = 0
      })
    }
  },
  UPDATE_FLOATING_PARENT: (state, data) => {
    state.messenger.floating.parent = data
  },
}

export const getters = {}

export const actions = {
  /** llega un nuevo mensaje del server */
  NewMessageReceived({ commit }, data) {
    commit('SYNC_GROUP_ITEMS', data)
    commit('ADD_NOTIFICATION_FROM_GROUP', data)

    /** llego un nuevo mensaje y lo considero como nuevo */
    commit('UPDATE_UNREAD_ITEMS', [data.group.uuid])
  },
  /** llega un nuevo mensaje del server */
  UpdateGroupStatus({ commit }, data) {
    /** llego un nuevo mensaje y lo considero como nuevo */
    commit('SYNC_GROUP_ITEMS', data)

    /** sincroniza la informacion del grupo */
    commit('UPDATE_GROUP_INFO', { ...data.group, visible: -1 })
  },
  /** actualiza el scroll de un grupo de mensajes */
  UpdateGroupScroll({ commit }, data) {
    commit('INIT_GROUP', data)

    commit('SET_GROUP_SCROLL', data)
  },
  /** actualiza si se muestra o no el aside del chatroom */
  ShowAside({ commit }, data) {
    commit('SHOW_MESSENGER_ASIDE', data)
  },
  /** actualiza si es no se ha notificado la usuario del componente messenger */
  IsNewVisit({ commit }, data) {
    commit('IS_NEW_VISIT', data)
  },
  /** levanta el modal de auth desde el componente */
  LoginFromMessenger({ commit }, data) {
    commit('SHOW_MESSENGER_MODAL_AUTH', data)
  },
  /** actualiza el tab actual del aside */
  ChangeCurrentMessengerTab({ commit }, data) {
    commit('CHANGE_CURRENT_TAB', data)
  },
  /** abre/cierra el modal flotante de messenger */
  SwitchFloatingOpen({ commit }, data) {
    commit('SWITCH_FLOATING_OPEN', data)

    if (!data) {
      commit('RESET_GROUPS')
    }
  },
  /** actualiza los datos del floating parent */
  UpdateFloatingParent({ commit }, data) {
    commit('UPDATE_FLOATING_PARENT', data)
  },
  /** eliminando todos los grupos */
  ResetGroups({ commit }) {
    commit('RESET_GROUPS')
  },
  /** se va a enviar un nuevo mensaje al servidor */
  async SendNewMessage({ commit }, form) {
    let response = null

    try {
      const { data, status } = await this.$axios.post(
        `/api/chat/${form.group.uuid}`,
        form
      )

      if (status === 'success') {
        commit('SYNC_GROUP_ITEMS', data)
        commit('ADD_NOTIFICATION_FROM_GROUP', data)

        /** seria en el primer mensaje que se envia en un grupo publico y acepta participar */
        if (Reflect.has(data, '_users')) {
          commit('SYNC_GROUP_USERS', {
            group: { uuid: data.group.uuid },
            users: data._users,
          })
        }
      } else {
        response = data.message
      }
    } catch (error) {
      throw new Error(getMessageFromResponse(error))
    }

    return response
  },
  /** enviando pregunta para crear grupo de conversacion */
  async SendNewQuestion({ commit }, send) {
    let response = null
    const form = {
      to: {
        uuid: this.state.messenger.messenger.floating.parent.uuid,
      },
      message: send.message,
    }

    try {
      const { data, status } = await this.$axios.post(
        `/api/chat/add/question`,
        form
      )

      if (status === 'success') {
        commit('SYNC_GROUP', { groups: data })

        const group = this.state.messenger.messenger.groups[0]
        commit('UPDATE_GROUP_INFO', {
          uuid: group.uuid,
          page: 0,
        })

        commit('SHOW_MESSENGER_ASIDE', 1)
      } else {
        response = data.message
      }
    } catch (error) {
      response = getMessageFromResponse(error)
    }

    return response
  },
  /** actualiza la informacion del grupo de chat actual */
  UpdateGroupInfo({ commit }, data) {
    commit('INIT_GROUP', { group: { uuid: data.uuid } })

    commit('UPDATE_GROUP_INFO', data)
  },
  /** traduciendo una conversacion */
  async TranslateConversations({ commit }, values) {
    const group = { group: { uuid: values.uuid } }

    commit('SET_GROUP_LOADING', { ...group, loading: true })

    const page = this.state.messenger.messenger.groups.find(
      (t) => t.uuid === values.uuid
    ).page

    try {
      const { data, status } = await this.$axios.get(
        `/api/chat/${values.uuid}/${page}/translate/${values.translate}`
      )

      if (status === 'success') {
        commit('SYNC_GROUP_ITEMS', { ...group, items: data.items })
      }
    } catch (error) {}

    commit('SET_GROUP_LOADING', { ...group, loading: false })
  },
  /** se va a enviar un nuevo mensaje al servidor */
  async getMessagesFromGroup({ commit }, form) {
    const group = { group: { uuid: form.uuid } }

    commit('INIT_GROUP', group)
    commit('SET_GROUP_LOADING', { ...group, loading: form.loading })

    const current = this.state.messenger.messenger.groups.find(
      (t) => t.uuid === form.uuid
    )
    let total = 0

    commit('INCREMENT_GROUP_PAGE', group)

    try {
      const { data, status } = await this.$axios.get(
        `/api/chat/${form.uuid}/${current.page}/messages`
      )

      if (status === 'success') {
        commit('SYNC_GROUP_USERS', { ...group, users: data.users })
        commit('SYNC_GROUP_ITEMS', { ...group, items: data.items })

        commit('SYNC_GROUP_AVE_RESPONSE', {
          ...group,
          ave_response: data.ave_response,
        })

        total = data.items.length
      }
    } catch (error) {}

    commit('SET_GROUP_LOADING', { ...group, loading: false })

    return total
  },
  async getAsideList({ commit }) {
    try {
      const { data, status } = await this.$axios.get(`/api/chat/aside/list`)

      if (status === 'success') {
        commit('SYNC_GROUP', { groups: data })
      }
    } catch (error) {}
  },
  /** marcar mensajes como leidos de una conversacion */
  async markMessagesAsRead({ commit }, uuid) {
    try {
      const { status } = await this.$axios.get(`/api/chat/${uuid}/read`)

      if (status === 'success') {
        commit('SET_READ_GROUP', { group: { uuid } })
      }
    } catch (error) {}
  },
  /** load more notifications */
  async loadNotifications({ commit }, page) {
    let response = false

    try {
      const { data, status } = await this.$axios.get(
        `/api/user/${page}/notifications`
      )

      if (status === 'success') {
        commit('ADD_MULTIPLES_NOTIFICATIONS', data.items)
        commit('UPDATE_UNREAD_ITEMS', data.unread)

        response = data.items.length === 0
      }
    } catch (error) {}

    return response
  },
}
