export const state = () => ({
  items: [],
  paginate: {
    limit: 30,
    page: 1,
    total: 0,
  },
  search: {
    flyFrom: 'MEX',
    flyTo: 'CAI',
    dateFrom: null,
    dateTo: null,
    returnFrom: null,
    returnTo: null,
    nightsInDstFrom: null,
    nightsInDstTo: null,
    flightType: 'round',
    adults: null,
    children: null,
    infants: null,
    selectedCabins: 'M',
    mixWithCabins: null,
    adultHoldBag: null,
    adultHandBag: null,
    childHoldBag: null,
    childHandBag: null,
    currency: null,
    priceFrom: null,
    priceto: null,
    stopoverFrom: null,
    stopoverTo: null,
    maxStopovers: null,
    retFromDiffAirport: null,
    retToDiffAirport: null,
  },
})

export const mutations = {
  SET_DEFAULT: (state) => {
    state.items = []
    state.airlines = []
    state.search = {
      flyFrom: null,
      flyTo: null,
      dateFrom: null,
      dateTo: null,
      returnFrom: null,
      returnTo: null,
      nightsInDstFrom: null,
      nightsInDstTo: null,
      flightType: null,
      adults: null,
      children: null,
      infants: null,
      selectedCabins: null,
      mixWithCabins: null,
      adultHoldBag: null,
      adultHandBag: null,
      childHoldBag: null,
      childHandBag: null,
      currency: null,
      priceFrom: null,
      priceto: null,
      stopoverFrom: null,
      stopoverTo: null,
      maxStopovers: null,
      retFromDiffAirport: null,
      retToDiffAirport: null,
    }
    state.paginate = {
      limit: 30,
      page: 1,
      total: 0,
    }
  },

  SET_SEARCH: (state, search) => {
    for (const [key, value] of Object.entries(search)) {
      state.search[key] = value
    }
  },

  SET_ITEMS: (state, data) => {
    state.items = data
  },
}

export const getters = {}

export const actions = {
  setItems({ commit }, data) {
    commit('SET_ITEMS', data)
  },
  setSearch({ commit }, data) {
    commit('SET_SEARCH', data)
  },

  async getFlights({ commit }, search) {
    try {
      commit('SET_SEARCH', search)
      const { data, status } = await this.$axios.get(`/api/v1.5/kiwi/search/`, {
        params: search,
      })
      if (status === 'success') {
        const list = []
        data.data.forEach((element) => {
          list.push(element)
        })
        commit('SET_ITEMS', list)
      }
    } catch (error) {}
  },
}
