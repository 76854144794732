const dayjs = require('dayjs')

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  /** en `utils/sitemap.js` hay info relacionada */
  influencer: {
    RenGMZ: {
      alias: 'Ren',
      pages: {
        'salkantay-trek': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/RENGMZ1-landing-cover-2.jpg',
          es_name: 'Ren en Salkantay Perú - La Ruta de los Dioses',
          en_name: 'Ren in Salkantay Peru - The Route of the Gods',
          code: 'RENGMZ1',
          whatsapp_es_name: 'Salkantay, trek a Machu Picchu',
          whatsapp_en_name: 'Salkantay, trek to Machu Picchu',
          utms: {
            source: 'rengmz',
            campaign: 'ren_y_mon_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'salkantay',
          },
        },
      },
    },
    MonGomez: {
      alias: 'Mon',
      pages: {
        'salkantay-trek': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/MONGMZ1-landing-cover-2.jpg',
          es_name: 'Mon en Salkantay Perú - La Ruta de los Dioses',
          en_name: 'Mon in Salkantay Peru - The Route of the Gods',
          code: 'MONGMZ1',
          whatsapp_es_name: 'Salkantay, trek a Machu Picchu',
          whatsapp_en_name: 'Salkantay, trek to Machu Picchu',
          utms: {
            source: 'mongomez',
            campaign: 'ren_y_mon_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'salkantay',
          },
        },
        'noches-auroras-boreales-con-vuelo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/MONGMZ2-landing-cover.png',
          es_name:
            'Mon en noches de auroras boreales con vuelos desde Ciudad de México',
          en_name:
            'Mon on Northern Lights nights with flights from Mexico City',
          code: 'MONGMZ2',
          whatsapp_es_name:
            'Noches de Auroras Boreales con vuelos desde Ciudad de México',
          whatsapp_en_name:
            'Northern Lights nights with flights from Mexico City',
          utms: {
            source: 'mongomez',
            campaign: 'mon_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'noches_auroras_vuelos',
          },
        },
      },
    },
    danieeibanez: {
      alias: 'Daniela',
      pages: {
        'aventura-rio-nilo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/DANBNZ1-landing-cover.jpg',
          es_name:
            'Dani en Aventura Por El Río Nilo Con Vuelo Desde Ciudad De México',
          en_name: 'Dani in Adventure Across The Nile River From Mexico City',
          code: 'DANBNZ1',
          whatsapp_es_name:
            'Aventura Por El Río Nilo Con Vuelo Desde Ciudad De México',
          whatsapp_en_name: 'Adventure Across The Nile River From Mexico City',
          utms: {
            source: 'danibanez',
            campaign: 'dani_y_pablo_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'rio_nilo',
          },
        },
      },
    },
    pablocampos98: {
      alias: 'Pablo',
      pages: {
        'aventura-rio-nilo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/PABLOC1-landing-cover.jpg',
          es_name:
            'Pablo en Aventura Por El Río Nilo Con Vuelo Desde Ciudad De México',
          en_name: 'Pablo in Adventure Across The Nile River From Mexico City',
          code: 'PABLOC1',
          whatsapp_es_name:
            'Aventura Por El Río Nilo Con Vuelo Desde Ciudad De México',
          whatsapp_en_name: 'Adventure Across The Nile River From Mexico City',
          utms: {
            source: 'pablocampos',
            campaign: 'dani_y_pablo_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'rio_nilo',
          },
        },
      },
    },
    rebecaschurenkamper: {
      alias: 'Rebeca',
      pages: {
        'lo-mejor-peru': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/REBEC1-landing-cover.jpg',
          es_name: 'Rebeca en lo mejor de Perú',
          en_name: 'Rebeca in the best of Peru',
          code: 'REBEC1',
          whatsapp_es_name: 'Lo Mejor De Perú Con Vuelo Desde Ciudad De México',
          whatsapp_en_name: 'The Best Of Peru With Flight From Mexico City',
          utms: {
            source: 'rebeca',
            campaign: 'rebeca_y_urboi_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'lo_mejor_peru_vuelos',
          },
        },
      },
    },
    urboiurboi: {
      alias: 'Urboi',
      pages: {
        'lo-mejor-peru': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/URBOI1-landing-cover-2.jpg',
          es_name: 'Urboi en lo mejor de Perú',
          en_name: 'Urboi in the best of Peru',
          code: 'URBOI1',
          whatsapp_es_name: 'Lo Mejor De Perú Con Vuelo Desde Ciudad De México',
          whatsapp_en_name: 'The Best Of Peru With Flight From Mexico City',
          utms: {
            source: 'urboi',
            campaign: 'rebeca_y_urboi_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'lo_mejor_peru_vuelos',
          },
        },
      },
    },
    yagomo: {
      alias: 'Yago',
      pages: {
        'noches-auroras-boreales-con-vuelo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/YAGOMU1-landing-cover.png',
          es_name:
            'Yago en noches de auroras boreales con vuelo desde la ciudad de México',
          en_name:
            'Yago on northern lights nights with flight from Mexico City',
          code: 'YAGOMU1',
          whatsapp_es_name:
            'Noches de auroras boreales con vuelo desde la ciudad de México',
          whatsapp_en_name:
            'Northern Lights nights with flight from Mexico City',
          utms: {
            source: 'yago',
            campaign: 'yago_y_natasha_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'noches_auroras_boreales_vuelos',
          },
        },
      },
    },
    natdupeyron: {
      alias: 'Natasha',
      pages: {
        'noches-auroras-boreales-con-vuelo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/NATASH1-landing-cover-2.png',
          es_name:
            'Natasha en noches de auroras boreales con vuelo desde la ciudad de México',
          en_name:
            'Natasha on northern lights nights with flight from Mexico City',
          code: 'NATASH1',
          whatsapp_es_name:
            'Noches de auroras boreales con vuelo desde la ciudad de México',
          whatsapp_en_name:
            'Northern Lights nights with flight from Mexico City',
          utms: {
            source: 'natasha',
            campaign: 'yago_y_natasha_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'noches_auroras_boreales_vuelos',
          },
        },
      },
    },
    orsonpadilla: {
      alias: 'Orson',
      pages: {
        'santorini-naxos-y-atenas-en-una-aventura-por-grecia': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/ORSONP1-landing-cover.png',
          es_name:
            'Orson en Santorini, Naxos y Atenas en una aventura por Grecia',
          en_name:
            'Orson in Santorini, Naxos and Athens on an adventure through Greece',
          code: 'ORSONP1',
          whatsapp_es_name:
            'Santorini, Naxos y Atenas en una aventura por Grecia',
          whatsapp_en_name:
            'Santorini, Naxos and Athens on an adventure through Greece',
          utms: {
            source: 'orson',
            campaign: 'orson_y_vale_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'santorini',
          },
        },
      },
    },
    valeaguima: {
      alias: 'Valeria',
      pages: {
        'santorini-naxos-y-atenas-en-una-aventura-por-grecia': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/VALEAG1-landing-cover.png',
          es_name:
            'Valeria en Santorini, Naxos y Atenas en una aventura por Grecia',
          en_name:
            'Valeria in Santorini, Naxos and Athens on an adventure through Greece',
          code: 'VALEAG1',
          whatsapp_es_name:
            'Santorini, Naxos y Atenas en una aventura por Grecia',
          whatsapp_en_name:
            'Santorini, Naxos and Athens on an adventure through Greece',
          utms: {
            source: 'valeria',
            campaign: 'orson_y_vale_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'santorini',
          },
        },
      },
    },
    patoremo: {
      alias: 'Patrick',
      pages: {
        'explorando-turquia-con-vuelo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/PATRIC1-landing-cover.png',
          es_name:
            'Patrick en explorando Turquía con vuelo desde ciudad de México',
          en_name: 'Patrick in exploring Turkey with flight from Mexico City',
          code: 'PATRIC1',
          whatsapp_es_name:
            'Explorando Turquía con vuelo desde ciudad de México',
          whatsapp_en_name: 'Exploring Turkey with flight from Mexico City',
          utms: {
            source: 'patrick',
            campaign: 'patrick_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'explorando_turquia_vuelos',
          },
        },
        'noches-auroras-boreales-con-vuelo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/PATRIC2-landing-cover.png',
          es_name:
            'PATRICK EN NOCHES DE AURORAS BOREALES CON VUELO DESDE CIUDAD DE MÉXICO',
          en_name:
            'PATRICK IN NIGHTS OF NORTHERN LIGHTS WITH FLIGHT FROM MEXICO CITY',
          code: 'PATRIC2',
          whatsapp_es_name:
            'Noches de Auroras Boreales con vuelos de la oferta que vi de Patrick (cupón PATRIC2)',
          whatsapp_en_name:
            'Northern Lights Nights trip with flights from the offer I saw from Patrick (coupon PATRIC2)',
          utms: {
            source: 'patrick',
            medium: 'influencer',
            campaign: 'patrick_{MMM}_{YYYY}',
            content: '_',
            term: 'noches_auroras_boreales_vuelos',
          },
        },
      },
    },
    meldehaene: {
      alias: 'Mel',
      pages: {
        'noches-auroras-boreales-con-vuelo': {
          cover:
            'https://cdn.localadventures.io/web-client/covers/influencers/MELDEH1-landing-cover.png',
          es_name:
            'Mel en noches de auroras boreales con vuelos desde Ciudad de México',
          en_name:
            'Mel on Northern Lights nights with flights from Mexico City',
          code: 'MELDEH1',
          whatsapp_es_name:
            'Noches de Auroras Boreales con vuelos desde Ciudad de México',
          whatsapp_en_name:
            'Northern Lights nights with flights from Mexico City',
          utms: {
            source: 'meldehaene',
            campaign: 'mel_{MMM}_{YYYY}',
            medium: 'influencer',
            term: 'noches_auroras_vuelos',
          },
        },
      },
    },
  },
  pages: {
    'salkantay-trek': {
      experience: {
        uuid: '37ce7623-119f-42db-842f-cdbbfa1d23e6',
      },
    },
    'aventura-rio-nilo': {
      experience: {
        uuid: '9cb5d527-ae6a-476c-814b-85c95d56ae74',
      },
    },
    'lo-mejor-peru': {
      experience: {
        uuid: '011b4618-25ef-47db-82d8-7f6d9d2c0e4f',
      },
    },
    'noches-auroras-boreales-con-vuelo': {
      experience: {
        uuid: '7d9a0d66-24ec-4795-a9e6-71b437c9c37a',
      },
    },
    'santorini-naxos-y-atenas-en-una-aventura-por-grecia': {
      experience: {
        uuid: 'e1b71788-42c9-4e68-bab3-524210964b15',
      },
    },
    'explorando-turquia-con-vuelo': {
      experience: {
        uuid: '4b6a6e9e-0b6b-4e40-9508-598234349d54',
      },
    },
  },
  static: {
    uuid: null,
    name: null,
    description: null,
    cover: { url: null },
    lead: {
      preventDoubleSubmit: false,
      sent: false,
    },
  },
})

export const mutations = {
  SET_STATIC: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.static, key)) {
        state.static[key] = value
      }
    }
  },
  SET_LEAD: (state, data) => {
    state.lead = data
  },
}

export const getters = {}

export const actions = {
  getByCode({ state }, { code, page }) {
    const item = { isValid: false, page: {}, alias: null }
    const codes = Object.keys(state.influencer).map((t) => t.toLowerCase())

    if (codes.includes((code || '').toLowerCase())) {
      const pageName = page.replace('influencer-', '').toLowerCase()
      const realkey = Object.keys(state.influencer).find(
        (t) => t.toLowerCase() === (code || '').toLowerCase()
      )
      const influencer = state.influencer[realkey]
      const pages = Object.keys(influencer.pages).map((t) => t.toLowerCase())

      if (pages.includes(pageName)) {
        const today = dayjs()
        const month = today.format('MMM').replace('.', '').toLowerCase()
        const year = today.format('YYYY')

        item.isValid = true
        item.alias = influencer.alias
        item.page = JSON.parse(JSON.stringify(influencer.pages[pageName]))

        item.page.utms.campaign = item.page.utms.campaign.replace(
          '{MMM}',
          month
        )
        item.page.utms.campaign = item.page.utms.campaign.replace(
          '{YYYY}',
          year
        )
      }
    }

    return item
  },
  getPageByName({ state }, { page }) {
    const item = { isValid: false, page: {} }
    const pageName = page.replace('influencer-', '').toLowerCase()
    const pages = Object.keys(state.pages).map((t) => t.toLowerCase())

    if (pages.includes(pageName)) {
      item.isValid = true

      item.page = JSON.parse(JSON.stringify(state.pages[pageName]))
    }

    return item
  },
  async getByPermalink({ commit }, permalink) {
    try {
      const { data, status } = await this.$axios.get(
        `/api/v1.5/influencers/show/${permalink}`
      )

      if (status === 'success') {
        commit('SET_STATIC', data)
      }
    } catch (error) {}
  },
}
