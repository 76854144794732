/** invierte la lista de srcset */
export function revertImagesList(srcset) {
  const src = srcset ? srcset.split(', ').reverse().join(', ') : []

  return src
}

/** extrae el thumbnail del srcset */
export function estractThumbnail(srcset) {
  const list = srcset
    ? srcset.replace(/\s+[0-9]+(\.[0-9]+)?[wx]/g, '$1').split(', ')
    : []

  return list.length ? list.pop() : srcset
}

/** obtiene listado de sizes a partir del srcset */
export function extractSizesImage(srcset) {
  let list = srcset ? srcset.match(/\s+[0-9]+(\.[0-9]+)?[wx]/g) : []
  if (!list) list = []
  list.map((t) => t.replace(' ', '').replace('w', 'px')).reverse()

  const sizes = []

  list.forEach((size) => sizes.push(`(max-width: ${size}) ${size}`))

  return sizes.join(', ')
}

/** extrae listado de imagenes con dimensiones menores al limit */
export function filterMediaFromSrcset(srcset, url, limit) {
  const data = {
    srcset: [],
    url: null,
  }
  const list = srcset ? srcset.split(', ') : []

  list.forEach((t) => {
    const items = t.split(' ')
    if (items.length === 2 && parseFloat(items[1]) < limit) {
      data.srcset.push(t)
    }
  })

  /** para cuando encuentra solo el thumbnails y quedan otras dimensiones */
  if (data.srcset.length === 1) {
    list.pop()
    const first = list.reverse()[0]

    if (first) {
      data.srcset.push(first)
      data.srcset.reverse()
    }
  }

  data.url = data.srcset.length > 0 ? data.srcset[0].split(' ')[0] : url
  data.srcset = data.srcset.join(', ')

  return data
}
