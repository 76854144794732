<template>
  <div
    class="wrapper--price-with-promotions"
    :class="{ 'has-discount': hasDiscount }"
  >
    <slot v-if="showHeader" name="header">
      <div class="wrapper--price-top">
        <template v-if="hasDiscount">
          <div class="container-tags">
            <span
              v-if="promoType === 'promo_dates'"
              class="discount-value discount-promo"
              ><span
                v-html="
                  $t(`general.promo_type.${promoType}`, {
                    discount: promoDiscount,
                  })
                "
              ></span
            ></span>
            <span v-else class="discount-value discount-promo"
              ><span>{{ promoDiscount }}</span></span
            >

            <LadPriceMSI :product="product" />
          </div>

          <div class="container-price-before">
            <span class="price-from">{{ $t(`${base}.from`) }}</span>
            <span class="price--old">{{ withoutDiscount }}</span>
          </div>

          <div class="you-save"
            >{{
              $t(`${base}.you_save`, {
                discount: discountWithFormat,
              })
            }}
          </div>
        </template>

        <template v-else>
          <LadPriceMSI :product="product" />

          <div class="container-price-before">
            <span class="price-from">{{ $t(`${base}.from`) }}</span>
          </div>
        </template>
      </div>
    </slot>

    <div class="wrapper-sidebar--price-middle d--grid">
      <LadPriceAmount
        :price="price"
        :discount-amount="discountAmount"
        :currency="currency"
      />
    </div>

    <slot v-if="showFooter" name="footer">
      <div
        class="wrapper--price-bottom"
        :class="{ 'per-person': showPerPerson || hasDateRestriction }"
      >
        <span v-if="showPerPerson || hasDateRestriction" class="price--unit">{{
          $t('general.promo_type.per_person')
        }}</span>
        <div v-else class="discount-value"
          ><span
            v-html="
              $t(`general.promo_type.${promoType}`, { discount: promoDiscount })
            "
          ></span
        ></div>
      </div>
    </slot>
  </div>
</template>

<script>
import LadPriceAmount from '../amount'
import LadPriceMSI from '../msi'

export default {
  name: 'LadAbstractPriceWithPromo',
  components: { LadPriceAmount, LadPriceMSI },
  props: {
    product: { type: Object, default: () => ({ is_lad: false }) },
  },
  data() {
    return {
      base: 'experience.sidebars.main',
    }
  },
  computed: {
    showPerPerson() {
      return !(
        this.promoType.startsWith('promo_2x1') ||
        this.promoType.startsWith('promo_3x1')
      )
    },
    hasDateRestriction() {
      return this.promoType.endsWith('_dates') && !this.showDiscountOrPromo
    },
  },
}
</script>

<style lang="scss">
.wrapper--price-with-promotions {
  .wrapper--price-top,
  .wrapper--price-bottom {
    padding: 0.5em 0;
    font-size: 0.8em;
  }

  .container-price-before {
    margin-top: 5px;
  }

  .price-from {
    text-transform: capitalize;
  }

  .price--old {
    text-decoration: line-through;
    font-style: italic;
    font-size: 1em;
    text-transform: uppercase;

    & + .discount-value {
      margin-left: 5px;
    }
  }

  .discount-value {
    display: inline-block;
    font-weight: 600;
    background-color: #c91800;
    color: white;
    border-radius: 10px;
    padding: 3px 6px;

    span {
      display: inline-block;
    }

    small {
      font-size: 100%;
    }
  }

  .you-save {
    font-style: italic;
    font-size: 1em;
  }

  &.has-discount {
    & > p {
      strong {
        color: #c91800;
      }
    }
    .price-content {
      color: #c91800;
    }
  }

  .container-tags {
    display: inline-block;
  }
}
</style>
