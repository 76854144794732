const axios = require('axios')

export default async (req, res, next) => {
  if (req.url.startsWith('/_')) return next()

  const parseCookies = (request) => {
    const list = {}
    const rc = request.headers.cookie

    rc &&
      rc.split(';').forEach((cookie) => {
        const parts = cookie.split('=')
        list[parts.shift().trim()] = decodeURI(parts.join('='))
      })

    return list
  }
  const cookies = parseCookies(req)
  const strategy = cookies['auth.strategy'] || 'custom-login'

  let url = req.headers.host ? `https://${req.headers.host}${req.url}` : req.url

  const reservation = {
    user: '/user/reservation/',
    public: '/public/reservation/',
  }

  const isPrivateSection = /\/(user|public)\/reservation\/(.*)\/(travelers|insurances|provider|flights)/.test(
    url
  )
  const separator = url.includes('?') ? '&' : '?'
  const key = 'redirect_from'

  /** eliminando `key` de redirect anterior */
  if (url.includes(key)) {
    const parts = url.split('?')
    const first = parts.shift()
    const params = []

    parts.forEach((part) => {
      const items = part.split('&')

      params.push(items.filter((item) => !item.startsWith(key)).join('&'))
    })

    url = `${first}?${params.join('&')}`
  }

  if (cookies) {
    if (
      typeof cookies[`auth._token.${strategy}`] !== 'undefined' &&
      !['false', '', 'undefined'].includes(cookies[`auth._token.${strategy}`])
    ) {
      // Initiate axios
      const instance = axios.create({
        baseURL: process.env.LAD_PRIVATE_API_BASE_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-localization': process.env.APP_LOCALE,
          'X-currency': process.env.APP_CURRENCY,
          Authorization: cookies[`auth._token.${strategy}`],
        },
      })

      let isValidToken = false
      try {
        const {
          data: { data, status },
        } = await instance.get('/api/ping')
        isValidToken = status === 'success' && data === 'pong'
      } catch (error) {}

      if (isValidToken && req.url.startsWith(reservation.public)) {
        /** auth and public, redirect to private page */
        let redirect = url.replace(reservation.public, reservation.user)
        redirect += `${separator}${key}_ssr=public`

        res.writeHead(307, { Location: redirect })
        return res.end()
      } else if (!isValidToken && req.url.startsWith(reservation.user)) {
        /** auth and public, redirect to private page */
        let redirect = url.replace(reservation.user, reservation.public)
        redirect += `${separator}${key}_ssr=private_1`

        res.writeHead(307, { Location: redirect })
        return res.end()
      } else {
        /** auth and private */
        return next()
      }
      //
    } else if (req.url.startsWith(reservation.user)) {
      /** no auth and private, redirect to public page */
      let redirect = url.replace(reservation.user, reservation.public)
      redirect += `${separator}${key}_ssr=private_2`

      if (isPrivateSection) {
        redirect = redirect.replace(/\/travelers|\/insurances|\/provider/g, '/')
      }
      res.writeHead(307, { Location: redirect })
      return res.end()
    } else if (isPrivateSection) {
      /** no auth and private sections, delete private section */
      let redirect = url.replace(/\/travelers|\/insurances|\/provider/g, '/')
      redirect += `${separator}${key}_ssr=private_section_3`

      res.writeHead(307, { Location: redirect })
      return res.end()
    } else {
      return next()
    }
    //
  } else if (req.url.startsWith(reservation.user)) {
    /** no auth and private, redirect to public page */
    let redirect = url.replace(reservation.user, reservation.public)
    redirect += `${separator}${key}_ssr=private_4`

    if (isPrivateSection) {
      redirect = redirect.replace(/\/travelers|\/insurances|\/provider/g, '/')
    }
    res.writeHead(307, { Location: redirect })
    return res.end()
  } else if (isPrivateSection) {
    /** no auth and private sections, delete private section */
    let redirect = url.replace(/\/travelers|\/insurances|\/provider/g, '/')
    redirect += `${separator}${key}_ssr=private_section_5`

    res.writeHead(307, { Location: redirect })
    return res.end()
  } else {
    return next()
  }
}
