<script>
import LadPricePromoMixin from '@/components/frontends/Price/components/mixin/promo'
import LadAbstractPriceWithPromo from '@/components/frontends/Price/components/abstract/with-promo'

export default {
  name: 'LadPriceWithPromo',
  extends: LadAbstractPriceWithPromo,
  mixins: [LadPricePromoMixin],
  props: {
    price: { type: Number, required: true },
    currency: { type: String, required: true },
    promotions: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/no-boolean-default
    showHeader: { type: Boolean, default: true },
    // eslint-disable-next-line vue/no-boolean-default
    showFooter: { type: Boolean, default: true },
  },
}
</script>
