/**
 * @class Fb
 */
class Fb {
  constructor (fbq, options, data) {
    this.fbq = fbq
    this.options = options
    this.data = data
  }

  /**
   * @method enable
   */
  enable () {
    this.init()
    this.track()
  }

  setData(data) {
    if (data !== null && typeof data === 'object') {
      this.data = data
    }

    this.init()
  }

  /**
   * @method init
   */
  init () {
    this.query('init', this.options.pixelId, this.data);

    (this.options.pixels || []).forEach(pixel => {
      this.query('init', pixel, this.data)
    });
  }

  /**
   * @method track
   */
  track (event = null, parameters = null) {
    if (!event) {
      event = this.options.track
    }

    this.query('track', event, parameters)
  }

  /**
   * @method trackCustom
   */
  trackCustom (event = null, parameters = null) {
    if (!!event) {
      this.query('trackCustom', event, parameters)
    }
  }

  /**
   * @method query
   * @param {string} cmd
   * @param {object} option
   * @param {object} parameters
   */
  query (cmd, option, parameters = null) {
    if (typeof this.fbq === 'function') {
      if (!parameters) {
        this.fbq(cmd, option)
      } else {
        this.fbq(cmd, option, parameters)
      }
    }
  }
}

export default (ctx, inject) => {
  let _fbq
  let fbqData = {}

  /** adicionando usuario autenticado al GA */
  const currentUserAuth = ctx.store.getters.currentUserAuth
  if (!!currentUserAuth) {
    fbqData = {
      em: currentUserAuth.email,         // Values will be hashed
      fn: currentUserAuth.profile.firstname,    // automatically by the pixel
      ln: currentUserAuth.profile.lastname,      // using SHA-256
      ph: `${currentUserAuth.profile.phone.dial}${currentUserAuth.profile.phone.number}`,
      external_id: currentUserAuth.uuid
    }
  }

  /* eslint-disable */
  let options = {"pixelId":"632094670262510","track":"PageView","autoPageView":true,"version":"2.0","pixels":["418782648568121"],"manualMode":false,"disabled":false,"debug":false};

  if (typeof window !== 'undefined') {
    ((f, b, e, v, n, t, s) => {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = options.version;
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName('body')[0];
      if (!!s) {
        s.parentNode.appendChild(t, s);
      }

      _fbq = fbq;

      if (!options.disabled) {
        fbq('init', options.pixelId, fbqData);
        fbq('track', options.track);
      }

      (options.pixels || []).forEach(pixel => {
        fbq('init', pixel, fbqData);
        fbq('track', options.track);
      });
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  }

  const instance = new Fb(_fbq, options, fbqData);
  /* eslint-enable */

  ctx.$fb = instance
  inject('fb', instance)
}
