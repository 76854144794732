export default [
  'pagination',
  'dialog',
  'dropdown',
  'dropdown-menu',
  'dropdown-item',
  // 'menu',
  'submenu',
  // 'menu-item',
  'menu-item-group',
  'input',
  'input-number',
  'radio',
  'radio-group',
  'radio-button',
  'checkbox',
  'checkbox-group',
  'switch',
  'select',
  'option',
  'button',
  'button-group',
  'table',
  'table-column',
  'date-picker',
  'popover',
  'tooltip',
  // 'breadcrumb',
  // 'breadcrumb-item',
  'form',
  'form-item',
  'tabs',
  // 'tab-pane',
  'tag',
  'alert',
  // 'notification',
  'slider',
  // 'row',
  // 'col',
  'upload',
  'progress',
  // 'message',
  // 'card',
  'rate',
  'steps',
  'step',
  'carousel',
  'carousel-item',
  'collapse',
  'collapse-item',
  // 'container',
  // 'header',
  // 'aside',
  // 'main',
  'footer',
  'timeline',
  'timeline-item',
  'link',
  'divider',
  'page-header',
  'avatar',
  'drawer',
  'popconfirm',
]
