import Vue from 'vue'
import locale from 'element-ui/lib/locale'

/**
 * https://ichi.pro/es/como-cargar-element-ui-dinamicamente-con-nuxt-js-156340569149155
 * https://developpaper.com/question/how-to-use-element-ui-in-nuxt-js/
 * https://medium.com/js-dojo/how-to-load-element-ui-dynamically-with-nuxt-js-8e30e0168ee3
 */

import {
  Message,
  Notification,
  MessageBox,
  Loading,
  InfiniteScroll,
} from 'element-ui'

import CommonComponents from '@/utils/element-ui/common-components'
import DYNAMICS_COMPONENTS from '@/utils/element-ui/dynamics-components'

/* Element UI languages */
import enLocale from 'element-ui/lib/locale/lang/en'
import esLocale from 'element-ui/lib/locale/lang/es'

/** personalizaciones de prototype */
import '@/components/frontends/prototype'

// Registro de los componentes comunes globalmente
Object.values(CommonComponents).forEach((component) => {
  Vue.use(component)
})

const capitalize = (text) =>
  `${text.toString().charAt(0).toUpperCase()}${text.substr(1)}`

export default ({ store: { state } }) => {
  // Configurar idioma
  locale.use(state.settings.locale.startsWith('es_') ? esLocale : enLocale)

  Vue.prototype.$ELEMENT = { size: 'medium' }

  // Cargar componentes dinámicamente bajo demanda
  DYNAMICS_COMPONENTS.forEach((component) => {
    const name = component
      .split('-')
      .map((t) => capitalize(t))
      .join('')

    Vue.component(`El${name}`, () =>
      import(
        /* webpackChunkName: 'element-ui/[request]' */ `element-ui/lib/${component}.js`
      )
    )
  })

  Vue.use(Loading.directive)
  Vue.use(InfiniteScroll)

  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$alert = MessageBox.alert
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$prompt = MessageBox.prompt
  Vue.prototype.$notify = Notification
  Vue.prototype.$message = Message
}
