import { hasOwnProperties, sleep } from '@/utils'
import { getToken } from '@/utils/auth'

export default function ({
  $axios,
  app,
  store,
  redirect,
  $config,
  $gtag,
  $bugsnag,
  $fb,
}) {
  /** https://nuxtjs.org/guide/async-data/ */

  /** variable de control para los request de tipo auth */
  let enabled = false

  $axios.onRequest(async (config) => {
    const url = config.url.replace('/api/v1.5', '/api')
    const isAuthUrl = /\/_auth\//.test(url)

    const strategy = store.state.auth.strategy || 'custom-login'

    /** si hay request previos relacionados a auth, deben los demas esperar a que se termine de hacer el request de auth */
    let max = 10
    // eslint-disable-next-line no-unmodified-loop-condition
    while (enabled && max > 0) {
      await sleep(1000)

      /** los request en la queue se les setea en auth token actualizado */
      const newToken = getToken(`auth._token.${strategy}`)
      $axios.setToken(newToken)
      config.headers.Authorization = newToken

      /** hay casos en que se envian dos request de tipo `refresh_token` ...el 2do se envia con el nuevo token para que no de error y desloguee al usuario */
      if (
        isAuthUrl &&
        config.method === 'post' &&
        config.data.grant_type === 'refresh_token'
      ) {
        const newRefeshToken = getToken(`auth._refresh_token.${strategy}`)
        config.data.refresh_token = newRefeshToken
      }

      max--
    }

    if (isAuthUrl) {
      enabled = true
    }

    /** obteniendo headers a sobreescribir */
    try {
      if (config.url.includes('?headers=')) {
        const changes = config.url.split('?headers=')
        const settings = JSON.parse(changes[1])
        for (const [key, value] of Object.entries(settings)) {
          config.headers[key] = value
        }

        config.url = changes[0]
      }
    } catch (error) {}

    /** adicionando cabeceras personalizadas solo para api de localadventures */
    if (config.url.startsWith('/api/')) {
      config.headers['Access-Control-Allow-Origin'] = '*'

      /** adicionando header localization en caso de no existir */
      if (!Reflect.has(config.headers, 'X-localization')) {
        config.headers['X-localization'] = store.state.settings.locale
      }

      /** adicionando header currency en caso de no existir */
      if (!Reflect.has(config.headers, 'X-currency')) {
        config.headers['X-currency'] = store.state.settings.currency
      }

      config.headers['X-App-Version'] = $config.appVersion
    } else if (config.url.startsWith('/_auth/')) {
      config.headers['X-localization'] = store.state.settings.locale
    }

    return config
  })

  $axios.onResponse((response) => {
    const url = response.config.url.replace('/api/v1.5', '/api')
    const isMeUrl = /\/api\/me/.test(url)
    const isAuthUrl = /\/_auth\//.test(url)

    if (isMeUrl) {
      /** Sending event to Google Analytics */
      if (typeof $gtag !== 'undefined') {
        $gtag('set', { user_id: response.data.uuid })

        /** cuando tengamos habilitado varios pasarelas de auth, se toma este valor desde @nuxtjs/auth */
        $gtag('event', 'login', { method: 'local' })
      }

      if (typeof $fb !== 'undefined') {
        $fb.trackCustom('Login', { method: 'local' })
      }
    }

    const data =
      !isMeUrl && Reflect.has(response, 'data') ? response.data : response

    if (data.access_token && data.refresh_token) {
      setTimeout(() => {
        if (isAuthUrl) {
          enabled = false
        }
        store.$auth
          .setUserToken(data.access_token, data.refresh_token)
          .finally(() => {})
      }, 300)
    } else {
      enabled = false
    }

    return data
  })

  $axios.onError((error) => {
    const url = error.config.url.replace('/api/v1.5', '/api')
    enabled = false

    const isLoginRoute = /\/_auth\/|\/api\/me/.test(url)
    const isRequestWithCard = /\/api\/payments|api\/orders\/create/.test(url)

    // if (isLoginRoute) {
    //   await app.$auth
    //     .logout('custom-login') /* .then(() => this.$router.push('/')) */
    //     .finally(() => {})
    // }

    const request =
      hasOwnProperties(error, 'config.data') &&
      typeof error.config.data === 'string'
        ? JSON.parse(error.config.data)
        : error.config.data || {}

    if (isRequestWithCard && hasOwnProperties(request, 'card')) {
      const keys = ['cvc', 'expiration_month', 'expiration_year']
      keys.forEach(
        (key) => (request.card[key] = '*'.repeat(request.card[key].length))
      )
    }

    if (
      hasOwnProperties(request, 'password') &&
      hasOwnProperties(request, 'password_confirmation')
    ) {
      request.is_same_password =
        request.password === request.password_confirmation
    }

    if (hasOwnProperties(request, 'password')) {
      request._password_ = '*'.repeat(request.password.length)
      delete request.password
    }

    if (hasOwnProperties(request, 'password_confirmation')) {
      request._password_confirmation_ = '*'.repeat(
        request.password_confirmation.length
      )
      delete request.password_confirmation
    }

    const data = {
      request: {
        url: hasOwnProperties(error, 'config.url') ? error.config.url : null,
        headers: hasOwnProperties(error, 'config.headers')
          ? error.config.headers
          : null,
        data: request,
      },
      response: {
        status: hasOwnProperties(error, 'response.status')
          ? error.response.status
          : null,
        headers: hasOwnProperties(error, 'response.headers')
          ? error.response.headers
          : null,
        data: hasOwnProperties(error, 'response.data')
          ? error.response.data
          : null,
      },
    }

    delete data.request.headers.cookie
    if (typeof $bugsnag === 'object' && typeof $bugsnag.notify === 'function') {
      $bugsnag.notify(new Error('middleware axios error'), function (event) {
        event.severity = 'info'
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === 'object' && data[key] !== null) {
            event.addMetadata(`custom_${key}`, data[key])
          }
        })
      })
    }

    if (
      !isLoginRoute &&
      hasOwnProperties(error, 'response.status', 401) &&
      hasOwnProperties(error, 'response.data.message', 'Unauthenticated.')
    ) {
      redirect('/401')
    }
  })

  $axios.interceptors.request.use(
    (config) => {
      if (config.url.endsWith('/orders/create')) {
        config.data.experiences.map((experience) => {
          experience.variations = [
            {
              sale_price: experience.price,
              layaway_price: null,

              qty: experience.qty,
              unit: experience.unit,

              departure_date: {
                start: experience.date,
                code: experience.departure_date_code,
                prev_code: experience.departure_date_code,
                age_range: 'na',
                room: { uuid: null },
              },
            },
          ]

          delete experience.price
          delete experience.qty
          delete experience.unit
          delete experience.date
          delete experience.departure_date_code
        })

        config.data.packages.map((product) => {
          product.variations = [
            {
              sale_price: product.price,
              layaway_price: null,

              qty: product.qty,
              unit: product.unit,

              departure_date: {
                start: product.date,
                code: product.departure_date_code,
                prev_code: product.departure_date_code,
                age_range: 'na',
                room: { uuid: null },
              },
            },
          ]

          delete product.price
          delete product.qty
          delete product.unit
          delete product.date
          delete product.departure_date_code
        })
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  $axios.interceptors.response.use(
    (response) => {
      if (hasOwnProperties(response, 'data.reservation.variations')) {
        const variation = response.data.reservation.variations[0]
        const departureDate = response.data.reservation.departure_date

        response.data.reservation.departure_date = {
          open: departureDate.open,
          code: variation.departure_date.code,
          min_travelers: variation.min_travelers,
          max_travelers: variation.max_travelers,
          season: response.data.reservation.season,
          language: response.data.reservation.language,
          flight_confirmation_code: variation.flight_confirmation_code,
          start: departureDate.start,
          end: departureDate.end,
        }

        response.data.reservation.experience_amount = variation.sale_price
        response.data.reservation.experience_layaway_price =
          variation.layaway_price
        response.data.reservation.experience_public_price =
          variation.public_price

        response.data.reservation.tickets = variation.qty
        response.data.reservation.unit = variation.unit

        response.data.order.products.experiences.map((experience) => {
          const v = experience._shopping_cart.variations[0]
          const d = experience._shopping_cart.departure_date

          experience.departure_date = {
            open: d.open,
            code: v.departure_date.code,
            min_travelers: v.min_travelers,
            max_travelers: v.max_travelers,
            season: response.data.reservation.season,
            language: response.data.reservation.language,
            flight_confirmation_code: v.flight_confirmation_code,
            start: d.start,
            end: d.end,
          }

          experience.price = v.price
          experience.layaway = v.layaway

          experience.qty = v.qty
          experience.unit = v.unit
        })
      }

      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}
