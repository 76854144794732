export const state = () => ({
  item: {
    id: null,
    slug: null,
    title: null,
    content: null,
    groups: [],
    yoast: {},
  },
  experiences: [],
})

export const mutations = {
  SET_DEFAULT: (state, { id = null, slug = null }) => {
    state.item = {
      id,
      slug,
      title: null,
      content: null,
      groups: [],
      yoast: {},
    }
  },
  SET_ITEM: (state, data) => {
    state.item.title = data.title

    const decodeHtmlCharCodes = (str) =>
      str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
        String.fromCharCode(charCode)
      )

    const groups = []
    const matches = data.content.match(/\[lad_experiences uuids=[^\]]*\]/g)
    matches.forEach((match, i) => {
      /** https://regexr.com/3an7c */
      const uuids = decodeHtmlCharCodes(match)
        .match(/\b(uuid:){0,1}\s*([a-f0-9\\-]*){1}\s*/g)
        .map((t) => t.trim())
        .filter((t) => !!t)

      groups.push({ key: i, uuids })

      data.content = data.content.replace(
        match,
        `<div class="wrapper-experiences-list" data-group="${i}"></div>`
      )
    })

    state.item.content = data.content
    state.item.groups = groups
    state.item.yoast = data.yoast
  },
  SET_EXPERIENCES: (state, data) => {
    state.experiences = data
  },
}

export const getters = {}

export const actions = {
  async getData({ commit, rootState }, { id }) {
    commit('SET_DEFAULT', { id, slug: null })

    const cms = process.env.APP_URL_CMS
    let response = await this.$axios.get(
      `${cms}/wp-json/wp/v2/landing-products/${id}?status=publish&_embed`
    )

    /** se hizo cambio de idioma y no coincide el slug */
    if (response.wpml_current_locale !== rootState.settings.locale) {
      const post = response.wpml_translations.find(
        (translation) => translation.locale === rootState.settings.locale
      )
      response = await this.$axios.get(
        `${cms}/wp-json/wp/v2/landing-products/${post.id}?status=publish&_embed`
      )
    }

    const data = {
      slug: response.slug,
      title: response.title.rendered,
      content: response.content.rendered,
      yoast: response.yoast_head_json,
    }

    commit('SET_ITEM', data)
  },
  async getExperiences({ commit }, groups) {
    try {
      const { data, status } = await this.$axios.post(
        `/api/v1.5/experiences/list/groups`,
        {
          groups,
        }
      )

      if (status === 'success') {
        commit('SET_EXPERIENCES', data)
      }
    } catch (error) {}
  },
}
