export default ({ store }) => {
  /* Add to Homescreen v4.0.0 ~ (c) 2019 Chris Love ~ @license: https://love2dev.com/pwa/add-to-homescreen/ */
  /*
    Check out these PWA Resources:
    https://love2dev.com/pwa/pwa-starter/
    https://pwastarter.love2dev.com/
    https://love2dev.com/blog/beforeinstallprompt/
    */

  /* eslint-disable */
  ;(function (window, document) {
    'use strict'

    let _instance

    const platform = {
      isCompatible: false,
      nativePrompt: 'onbeforeinstallprompt' in window,
    }

    if ('onbeforeinstallprompt' in window) {
      window.addEventListener('beforeinstallprompt', beforeInstallPrompt)
    }

    function afterSWCheck(sw) {
      if (!sw) {
        platform.isCompatible = false

        // return, no need to go further
        return
      }

      checkPlatform()

      platform.isCompatible = true
    }

    function checkPlatform() {
      // browser info and capability
      const _ua = window.navigator.userAgent

      platform.isIDevice = /iphone|ipod|ipad/i.test(navigator.platform)
      platform.isSamsung = /Samsung/i.test(_ua)
      platform.isFireFox = /Firefox/i.test(_ua)
      platform.isOpera = /opr/i.test(_ua)
      platform.isEdge = /edg/i.test(_ua)

      // Opera & FireFox only Trigger on Android
      if (platform.isFireFox) {
        platform.isFireFox = /android/i.test(_ua)
      }

      if (platform.isOpera) {
        platform.isOpera = /android/i.test(_ua)
      }

      platform.isChromium = 'onbeforeinstallprompt' in window
      platform.isInWebAppiOS = window.navigator.standalone === true
      platform.isInWebAppChrome =
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches
      platform.isMobileSafari =
        platform.isIDevice && _ua.includes('Safari') && !_ua.includes('CriOS')
      platform.isStandalone =
        platform.isInWebAppiOS || platform.isInWebAppChrome
      platform.isiPad = platform.isMobileSafari && _ua.includes('iPad')
      platform.isiPhone = platform.isMobileSafari && !_ua.includes('iPad')
      platform.isCompatible =
        platform.isChromium ||
        platform.isMobileSafari ||
        platform.isSamsung ||
        platform.isFireFox ||
        platform.isOpera ||
        platform.isIDevice
    }

    function beforeInstallPrompt(evt) {
      evt.preventDefault()

      platform.beforeInstallPrompt = evt

      store.dispatch('app/setDeferredPrompt', platform.beforeInstallPrompt)
    }

    ;(function () {
      if ('serviceWorker' in navigator) {
        const manifestEle = document.querySelector("[rel='manifest']")

        // if no manifest file then no add to homescreen
        if (!!manifestEle) {
          navigator.serviceWorker.getRegistration().then(afterSWCheck)
        }
      }
    })()
  })(window, document)
  /* eslint-enable */
}
