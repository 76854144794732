<template>
  <div class="price-content d--grid">
    <div class="price-content--left">
      <span class="price--symbol">{{ symbol }}</span>
      <span>&nbsp;</span>
    </div>
    <div class="price-content--center">
      <span class="price--price">{{ amount }}</span>
    </div>
    <div class="price-content--right">
      <span class="price--cent">{{ `${decimal}${cents}` }}</span>
      <span class="price--currency">{{ currency }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LadPriceAmount',
  props: {
    symbol: { type: String, default: '$' },
    price: { type: Number, required: true },
    discountAmount: { type: Number, default: 0 },
    currency: { type: String, required: true },
    // eslint-disable-next-line vue/no-boolean-default
    showZeroPrice: { type: Boolean, default: true },
  },
  computed: {
    amountCurrency() {
      return this.$options.filters
        .ladFnCurrency(this.price, this.currency, false)
        .subtract(this.discountAmount)
    },
    amountWithoutFormat() {
      return this.amountCurrency.format(false).replace(this.symbol, '')
    },
    amount() {
      return this.showZeroPrice && this.amountCurrency.dollars() === 0
        ? '-'
        : this.amountWithoutFormat.slice(0, -3)
    },
    cents() {
      const length = this.amountWithoutFormat.length

      return this.amount === '-' && this.amountCurrency.cents() === 0
        ? '--'
        : this.amountWithoutFormat.slice(-2, length)
    },
    decimal() {
      return this.amountCurrency.s.decimal
    },
  },
}
</script>

<style lang="scss">
.price-content {
  grid-template-columns: auto 1fr auto;
  grid-gap: 5px;
  align-items: center;
  width: auto !important;
  margin-left: auto;

  &--right {
    font-size: 0.9em;
  }

  &--left,
  &--right {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  span {
    display: block;
  }

  .price--price {
    font-size: 3.5em;
    font-weight: 700;
    text-align: right;
  }
  .price--currency {
    margin-top: auto;
    font-size: 0.8em;
    text-transform: uppercase;
  }
}
</style>
