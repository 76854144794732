import { uuidv4, toText } from '@/utils'

export const state = () => ({
  announcementList: [],
  announcement: {
    id: null,
    type: 'announcement',
    title: null,
    description: null,
    additional_description: null,
    date: '',
    media: {},
  },
})

export const mutations = {
  INIT_ANNOUNCEMENT_LIST: (state) => {
    state.announcementList = []
  },
  SET_ANNOUNCEMENT_LIST: (state, data) => {
    state.announcementList.push(data)
  },
  SET_ID: (state, data) => {
    state.announcement.id = data
  },
  SET_TITLE: (state, data) => {
    state.announcement.title = data
  },
  SET_DESCRIPTION: (state, data) => {
    state.announcement.description = data
  },
  SET_ADDITIONAL_DESCRIPTION: (state, data) => {
    state.announcement.additional_description = data
  },
  SET_DATE: (state, data) => {
    state.announcement.date = data
  },
  SET_SRC: (state, data) => {
    state.announcement.media = data
  },
}

export const getters = {}

export const actions = {
  async getList({ commit, rootState }) {
    commit('INIT_ANNOUNCEMENT_LIST')

    try {
      const cms = process.env.APP_URL_CMS
      const url = `${cms}/wp-json/wp/v2/announcements?filter[orderby]=date&order=desc&status=publish`

      let lang = ''
      switch (rootState.settings.locale) {
        case 'es_CO':
          lang = '&lang=es-co'
          break
        case 'en_US':
          lang = '&lang=en'
          break
        default:
          break
      }

      const response = await this.$axios.get(`${url}${lang}`)
      response.forEach((announcement) => {
        const description =
          toText(announcement.excerpt.rendered)
            .split(' ')
            .slice(0, 20)
            .join(' ') + '...'

        const hasMedia =
          !!announcement._embedded &&
          announcement._embedded['wp:featuredmedia'] &&
          announcement._embedded['wp:featuredmedia'].length > 0

        commit('SET_ANNOUNCEMENT_LIST', {
          id: announcement.id,
          type: 'post',
          title: announcement.title.rendered,
          description,
          date: this.$dayjs(announcement.date).format('YYYY-MM-DD'),
          permalink: {
            path: `/announcement/${announcement.slug}-${announcement.id}`,
          },
          media: {
            type: 'image',
            data: {
              url: hasMedia
                ? announcement._embedded['wp:featuredmedia'][0].media_details
                    .sizes.medium_large.source_url
                : null,
              uuid: uuidv4(),
            },
          },
        })
      })
    } catch (e) {}
  },
  async getItem({ commit, rootGetters, rootState }, { id }) {
    const cms = process.env.APP_URL_CMS
    let response = await this.$axios.get(
      `${cms}/wp-json/wp/v2/announcements/${id}?_embed&status=publish`
    )

    /** se hizo cambio de idioma y no coincide el slug */
    if (response.wpml_current_locale !== rootState.settings.locale) {
      const post = response.wpml_translations.find(
        (translation) => translation.locale === rootState.settings.locale
      )
      response = await this.$axios.get(
        `${cms}/wp-json/wp/v2/announcements/${post.id}?_embed&status=publish`
      )
    }

    commit('SET_ID', response.id)
    commit('SET_TITLE', response.title.rendered)
    commit('SET_DESCRIPTION', response.content.rendered)
    commit('SET_ADDITIONAL_DESCRIPTION', response.aditional_description)
    commit('SET_DATE', this.$dayjs(response.date).format('MMMM D, YYYY'))
    commit('SET_SRC', {
      src:
        response._embedded['wp:featuredmedia'][0].media_details.sizes.large
          .source_url,
      imgMinHeight: 600,
      imgMaxWidth: rootGetters['device/isMobile'] ? 900 : 1920,
    })
  },
}
