// eslint-disable-next-line import/no-relative-parent-imports
import { LANDING_PERMALINK_ROUTER } from '../../utils/static-router-alias'

const axios = require('axios')

/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default async (req, res, next) => {
  const groups = await req.url.split('?')
  const url = groups[0].endsWith('/') ? groups[0].slice(0, -1) : groups[0]
  const sections = url.split('/')
  sections.shift()

  let permalink = sections[1]
  let newLocation = null

  if (sections[0] === 'landing' && !/.+\.(png|jpg|jpeg|svg)/.test(url)) {
    // Initiate axios
    const instance = axios.create({
      baseURL: process.env.LAD_PRIVATE_API_BASE_URL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-localization': process.env.APP_LOCALE,
        'X-currency': process.env.APP_CURRENCY,
      },
    })

    let redirect2Travel = false

    try {
      const {
        data: { data, status },
      } = await instance.post('/api/settings/validate-slug', {
        type: 'experience',
        slug: permalink || '',
        url,
      })

      if (status === 'success') {
        if (
          process.env.APP_URL === process.env.APP_URL_EN_US &&
          data.legacy.es === permalink
        ) {
          permalink = data.current.es

          redirect2Travel = true
        } else {
          permalink = data.current.default
        }
      }
    } catch (error) {}

    newLocation = `/landing/${permalink}`

    /** aceptando AB Testing en las rutas */
    if (
      sections.length > 2 &&
      LANDING_PERMALINK_ROUTER.abtesting.includes(sections[2])
    ) {
      newLocation += `/${sections[2]}`
    }

    if (newLocation !== url) {
      if (groups.length === 2) {
        newLocation += '?' + groups[1]
      }

      if (redirect2Travel) {
        newLocation = `${process.env.APP_URL_ES_MX}${newLocation}`
      }

      res.writeHead(301, { Location: newLocation })
      return res.end()
    } else {
      return next()
    }
  } else {
    return next()
  }
}
