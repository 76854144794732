export default {
  customer_service: {
    whatsapp: ['+1 936 246 0209'],
    phone: '+1 415-801-6487',
    email: 'customers@localadventures.travel',
    schedules: ['Mon - Sun 10am - 7pm'],
  },
  sales: {
    whatsapp: ['+1 936 246 0209'],
    phone: '+1 415-801-6487',
    email: 'info@localadventures.travel',
    schedules: ['Mon - Sun 9am - 8pm'],
  },
  address: {
    company: 'EMX EMPREMEXICO SAPI DE CV',
    address: `AV. Ignacio L. Vallarta 3155,<br />Vallarta Poniente,<br />Guadalajara,<br />Jalisco,<br />CP:44110`,
  },
  country: {
    alpha_3_code: 'usa',
  },
  toky: {
    emergency:
      'https://app.toky.co/app/09c9f44f-76c0-11eb-865c-065a460efe03/41571',
  },
}
