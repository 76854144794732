<template>
  <picture
    class="lad-image"
    :data-width="width"
    :data-height="height"
    @click="handleClick"
  >
    <img
      v-if="!isCdnValid"
      :alt="alt || title"
      :title="title || alt"
      :src="image"
      :width="width"
      :height="height"
      :loading="lazyLoading ? 'lazy' : 'eager'"
    />
    <template v-else>
      <source
        v-for="(item, i) in medias"
        :key="i"
        :srcset="
          image
            | convertMediaSrc(
              item.width,
              item.quality || quality,
              minHeight,
              maxWidth
            )
        "
        :media="item.media"
      />
      <img
        :src="
          hasThumb
            ? thumbnail
            : image
              | convertMediaSrc(
                defaultValues.width, // w
                defaultValues.quality, // q
                minHeight, // minH
                maxWidth // maxW
              )
        "
        :alt="alt || title"
        :title="title || alt"
        :width="width"
        :height="height"
        :loading="lazyLoading ? 'lazy' : 'eager'"
      />
    </template>
  </picture>
</template>

<script>
import { estractThumbnail } from '@/components/frontends/Image/utils'
import LadComponentNameMixin from '@/utils/mixin/custom-component-name.js'

export default {
  name: 'LadImage',
  filters: {
    convertMediaSrc(src, w, q, minH, maxW) {
      if (!isNaN(minH) && minH > w) {
        w = 'auto'
      } else {
        minH = 'auto'
      }

      if (maxW > 0 && w === 'auto') {
        w = maxW
      }

      let transform = `cdn-cgi/image/w=${w},h=${minH},q=${q || 84},f=auto`

      if (maxW > 0) {
        transform += ',fit=cover'
      }

      /** https://developers.cloudflare.com/images/resizing-with-workers */
      const data = (src || '').replace(
        process.env.DO_SPACES_CDN_ENDPOINT,
        `${process.env.DO_SPACES_CDN_ENDPOINT}/${transform}`
      )

      return data
    },
  },
  mixins: [LadComponentNameMixin],
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    src: { type: String | null, default: '' },
    alt: { type: String, default: '' },
    title: { type: String, default: '' },
    srcset: { type: String, default: '' },
    maxWidth: { type: Number, default: 0 },
    // eslint-disable-next-line vue/require-prop-type-constructor
    defaultHeight: { type: String | Number, default: '100' },
    // eslint-disable-next-line vue/require-prop-type-constructor
    minHeight: { type: String | Number, default: 'auto' },
    quality: { type: Number, default: 84 },
    includeAllSources: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    // eslint-disable-next-line vue/no-boolean-default
    lazyLoading: { type: Boolean, default: true },
    preloading: { type: Boolean, default: false },
  },
  data() {
    return {
      width: null,
      height: null,
      forceUpdate: false,
      minWidthSupport: 320,
      defaultValues: {
        width: 460,
        quality: 1,
      },
    }
  },
  computed: {
    image() {
      return this.src === null
        ? require('@/assets/default-image.jpg')
        : this.src
    },
    isCdnValid() {
      return (
        (this.image || '').startsWith(process.env.DO_SPACES_CDN_ENDPOINT) &&
        !(this.image || '').endsWith('.svg')
      )
    },
    medias() {
      /** https://ricostacruz.com/til/css-media-query-breakpoints */
      const ranges = [
        { max: 567, quality: this.quality },
        { min: 568, max: 767, quality: this.quality },
        { min: 768, max: 1023, quality: this.quality },
        {
          min: 1024,
          max: 1365,
          quality: this.quality === 84 ? 90 : this.quality,
        },
        {
          min: 1366,
          max: 1679,
          quality: this.quality === 84 ? 90 : this.quality,
        },
        {
          min: 1680,
          max: 1920,
          quality: this.quality === 84 ? 90 : this.quality,
        }, // max - ((max - min) * 0.2) = 1872
      ]
      const data = []
      let lastMin = null

      ranges.forEach((range) => {
        let width = parseInt(
          range.max - (range.max - range.min || this.minWidthSupport) * 0.2
        )
        if (this.maxWidth > 0 && this.maxWidth < width) {
          width = this.maxWidth
        }

        if (
          this.width >= width ||
          this.width === null ||
          this.includeAllSources
        ) {
          if (typeof range.min === 'undefined') {
            data.push({
              media: `(max-width: ${range.max}px)`,
              width,
              quality: range.quality,
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            data.push({
              media: `(min-width: ${range.min}px) and (max-width: ${range.max}px)`,
              width,
              quality: range.quality,
            })

            lastMin = range.min
          }
        }
      })

      /** el ultimo rango de medias no puede tener un max-width ...solo cuando hay mas de un rango de source */
      if (data.length > 1) {
        const last = data[data.length - 1]
        last.media = `(min-width: ${lastMin}px)`
      } else if (data.length === 0) {
        /** en caso que el ancho sea menor que el primer rango, siempre adicionar uno por default */
        let width = parseInt(
          ranges[0].max - (ranges[0].max - this.minWidthSupport) * 0.2
        )
        if (this.maxWidth > 0 && this.maxWidth < width) {
          width = this.maxWidth
        }
        data.push({
          media: `(min-width: ${this.minWidthSupport}px)`,
          width,
        })
      } else if (data.length === 1) {
        const last = data[data.length - 1]
        last.media = `(min-width: ${this.minWidthSupport}px)`
      }

      return data
    },
    thumbnail() {
      return estractThumbnail(this.srcset)
    },
    hasThumb() {
      return !!this.thumbnail
    },
  },
  watch: {
    height: {
      immediate: true,
      handler(v, x) {
        if (process.server) return

        /** en algunas ocasiones es necesario forzar la actualizacion de las dimensiones (solo una vez) */
        if (!v && !x && !this.forceUpdate) {
          this.forceUpdate = true
          this.updateDimenssions()
        }
      },
    },
  },
  created() {
    if (this.preloading) {
      const sources = []
      this.medias.forEach((item) => {
        sources.push(
          this.$options.filters.convertMediaSrc(
            this.src,
            item.width,
            item.quality || this.quality,
            this.minHeight,
            this.maxWidth
          )
        )
      })
      this.$nuxt.$emit('set-preload', {
        medias: this.medias,
        sources,
        src: this.src,
        srcset: this.srcset,
      })
    }
  },
  beforeMount() {
    this.updateDimenssions()
  },
  methods: {
    updateDimenssions() {
      /** debe ponerse aqui dentro para evitar que no se encuentre el parentNode */
      this.LadNextTick(() => {
        try {
          const data = this.$el.parentNode.getBoundingClientRect()

          if (typeof data !== 'undefined' && data.width > 50) {
            const ratio = data.width / data.height === 0 ? 1 : data.height
            this.width = data.width > 0 ? data.width : '100'
            this.height =
              ratio > 1.2 && ratio < 5 ? data.height : this.defaultHeight
          }
        } catch (error) {}
      }, `LadImage - ${this.getComponentName()}`)
    },
    handleClick() {
      this.$emit('clicked', this.index)
    },
  },
}
</script>

<style lang="scss">
.lad-image {
  // display: inline-block;

  &.image--cover {
    source,
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  img {
    --aspectRatio: calc(3 / 4 * 100%);

    // eslint-disable-next-line
    min-height: var(--aspectRatio);

    &[height='100'] {
      height: initial;
    }

    &[width='100'] {
      width: 100%;
    }
  }
}
</style>
