var render = function render(){var _vm=this,_c=_vm._self._c;return _c('picture',{staticClass:"lad-image",attrs:{"data-width":_vm.width,"data-height":_vm.height},on:{"click":_vm.handleClick}},[(!_vm.isCdnValid)?_c('img',{attrs:{"alt":_vm.alt || _vm.title,"title":_vm.title || _vm.alt,"src":_vm.image,"width":_vm.width,"height":_vm.height,"loading":_vm.lazyLoading ? 'lazy' : 'eager'}}):[_vm._l((_vm.medias),function(item,i){return _c('source',{key:i,attrs:{"srcset":_vm._f("convertMediaSrc")(_vm.image,
            item.width,
            item.quality || _vm.quality,
            _vm.minHeight,
            _vm.maxWidth
          ),"media":item.media}})}),_vm._v(" "),_c('img',{attrs:{"src":_vm._f("convertMediaSrc")(_vm.hasThumb
          ? _vm.thumbnail
          : _vm.image,
              _vm.defaultValues.width, // w
              _vm.defaultValues.quality, // q
              _vm.minHeight, // minH
              _vm.maxWidth // maxW
            ),"alt":_vm.alt || _vm.title,"title":_vm.title || _vm.alt,"width":_vm.width,"height":_vm.height,"loading":_vm.lazyLoading ? 'lazy' : 'eager'}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }