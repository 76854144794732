export default {
  methods: {
    getComponentName() {
      let _this = this
      let parentName = null
      let parentKey = null
      let limit = 10

      try {
        do {
          parentName = _this.$parent.$vnode.componentOptions.tag || ''
          parentKey = _this.$parent.$vnode.key || ''

          if (!parentName.startsWith('Lad')) {
            parentName = null
            _this = _this.$parent
          }

          limit--
        } while (parentName === null && limit > 0)
      } catch (error) {}

      return `${parentName || ''} > ${this.$vnode.key || parentKey}`
    },
  },
}
