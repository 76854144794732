export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  reviews: [],
  search: '',
})

export const mutations = {
  SET_REVIEWS_ITEMS: (state, data) => {
    state.reviews = data.map((t) => {
      const ld = (t.likes_dislikes || '0 / 0').split(' / ')

      if (t.message === null) {
        t.message = ''
      }

      t.likes = parseInt(ld[0])
      t.dislikes = parseInt(ld[1])

      return t
    })
  },
  SET_REVIEWS_SEARCH: (state, data) => {
    state.search = data || ''
  },
}

export const getters = {}

export const actions = {
  async getProductReviews({ commit }, { product, firstReview }) {
    try {
      const { data, status } = await this.$axios.get(
        `/api/v1.5/products/${product.uuid}/reviews`
      )

      if (status === 'success') {
        if (firstReview.uuid) {
          const index = data.findIndex(
            (e) => e.review.uuid === firstReview.uuid
          )

          if (index !== -1) {
            const review = data.splice(index, 1)
            data.unshift(review[0])
          }
        }

        commit('SET_REVIEWS_ITEMS', data)
      }
    } catch (error) {}
  },
  setProductReviews({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_REVIEWS_ITEMS', data)
      return resolve(data)
    })
  },
  setProductReviewsSearch({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_REVIEWS_SEARCH', data)
      return resolve(data)
    })
  },
}
