export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  builder: {
    name: null,
    description: null,
    show_count_down: false,
    logo: {
      url: null,
    },
    styles: {
      banner: { color: null, background: null },
      cta: { color: null, background: null },
    },
    start: null,
    end: null,
  },
  orders: {
    styles: {
      banner: { color: 'white', background: '#DACAFF' },
      cta: { color: 'white', background: '#DACAFF' },
    },
    name: null,
    description: null,
    show_count_down: false,
    start: null,
    end: null,
  },
})

export const mutations = {
  UPDATE_BUILDER: (state, data) => {
    state.builder = data
  },
  SET_DEFAULT_ORDER: (state, data) => {
    state.orders = {
      styles: {
        banner: { color: 'white', background: '#DACAFF' },
        cta: { color: 'white', background: '#DACAFF' },
      },
      name: null,
      description: null,
      show_count_down: false,
      start: null,
      end: null,
    }
  },
  SET_ORDERS_SHOW: (state, data) => {
    state.orders.show_count_down = data
  },
  SET_ORDER_COUNTDOWN: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.orders, key)) {
        state.orders[key] = value
      }
    }
  },
}

export const getters = {}

export const actions = {
  async get({ commit }) {
    try {
      /** obteniendo datos de la experiencia */
      const { data, status } = await this.$axios.get(
        `/api/advertisements/enabled`
      )

      if (status === 'success' && data !== null) {
        commit('UPDATE_BUILDER', data)
      }
    } catch (error) {}
  },
  setDefaultOrder({ commit }) {
    commit('SET_DEFAULT_ORDER')
  },
  setOrderCountDown({ commit }, data) {
    commit('SET_DEFAULT_ORDER')
    commit('SET_ORDER_COUNTDOWN', data)
  },
}
