import load from '@/utils/load-promise'

export default (ctx, inject) => {
  const domain =
    process.env.NODE_ENV === 'production'
      ? 'atratopago.com'
      : 'sandbox.atratopago.com'

  const atrato = {
    loaded: false,
    init: () => {
      atrato.loaded = true
    },
    load: () => {
      if (!atrato.loaded) {
        let limit = 30
        const interval = setInterval(() => {
          if (typeof hs !== 'undefined') {
            clearInterval(interval)

            load
              .js(
                `https://${domain}/ecommerce_plugin/highslide-config.js`,
                'atrato-highslide-config-script-src',
                'defer'
              )
              .then(() => atrato.init())
              .catch(() => {})

            load
              .css(
                `https://${domain}/ecommerce_plugin/highslide.css`,
                'atrato-highslide-stylesheet-src'
              )
              .catch(() => {})
          } else {
            limit--
          }

          if (limit === 0) {
            clearInterval(interval)
          }
        }, 300)
      }
    },
  }

  inject('atrato', atrato)
}
