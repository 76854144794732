import { hasOwnProperties } from '@/utils'

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  resume: {
    is_same_liquidation_date: true,
  },
  sidebar: {
    pending_amount: 0,
    total_payments: 0,
    save: false,
    enable_btn_save: false,
  },
  data: {
    product: {
      uuid: null,
      name: '',
      permalink: null,
      type: null,
      address: null,
      is_lad: false,
      can_open_date: true,
      with_flight: false,
    },
    local: { name: null, email: null, phone: null },
    currency: { id: null, code: 'MXN' },
    reservation: {
      experience_amount: 0,
      tickets: 1,
      reservation_amount: 0,
      departure_date: { start: '', end: '', open: true, code: null },

      season: null,
      language: null,

      liquidated: false,
      // created_at: new Date(),
      confirmation_date: new Date(),
      liquidation_date: '',
      paid_to_supplier: false,
      pending_amount: 0,
      code: null,
      status: 'new',
      recurring: {
        active: false,
        next_payment_date: null,
        next_payment_amount: null,
      },
      created_at: null,
    },
    order: {
      amount: 0,
      pending_amount: 0,
      liquidated: false,
      products: [],
    },
    customer: {
      uuid: null,
      firstname: null,
      lastname: null,
      phone: {
        dial: null,
        number: null,
      },
      email: null,
    },
    emergency_contact: {
      user: {
        uuid: null,
        firstname: null,
        lastname: null,
        phone: {
          dial: null,
          number: null,
        },
        email: null,
      },
    },
    extras: [],
    rooms: [],
    insurances: [],
    flights: [],
    attachments: [],
    payments: [],
    travelers: [],
    assistcards: [],
    promotions: [],
    penalizations: [],
    discount_penalizations: [],
    story: { uuid: '', enable_payment_method: true },
    chat: { uuid: '' },
    client_view_link: '',
    local_points: {
      amount: 0,
      available: 0,
      spent: 0,
      referring_amount: {
        min: 0,
        max: 0,
        all: [],
      },
      referral_code: '',
    },
    provider_notes: null,
    traveler_notes: null,
    timer: null,
    check_up: {
      sections: [],
      general: {
        is_liquidated: false,
        has_emergency_contact: false,
        has_passports: false,
        has_passports_expiry_valid: false,
        has_travelers: false,
        has_insurances: false,
      },
      by_user: [],
    },
  },
})

export const mutations = {
  SET_DEFAULT: (state) => {
    state.resume = {
      is_same_liquidation_date: true,
    }

    state.sidebar = {
      pending_amount: 0,
      total_payments: 0,
      save: false,
      enable_btn_save: false,
    }

    state.data = {
      product: {
        uuid: null,
        name: '',
        permalink: null,
        type: null,
        address: null,
        is_lad: false,
        can_open_date: true,
        with_flight: false,
      },
      local: { name: null, email: null, phone: null },
      currency: { id: null, code: 'MXN' },
      reservation: {
        experience_amount: 0,
        tickets: 1,
        reservation_amount: 0,
        departure_date: { start: '', end: '', open: true, code: null },

        season: null,
        language: null,

        liquidated: false,
        // created_at: new Date(),
        confirmation_date: new Date(),
        liquidation_date: '',
        paid_to_supplier: false,
        pending_amount: 0,
        code: null,
        status: 'new',
        recurring: {
          active: false,
          next_payment_date: null,
          next_payment_amount: null,
        },
        created_at: null,
      },
      order: {
        amount: 0,
        pending_amount: 0,
        liquidated: false,
        products: [],
      },
      customer: {
        uuid: null,
        firstname: null,
        lastname: null,
        phone: {
          dial: null,
          number: null,
        },
        email: null,
      },
      emergency_contact: {
        user: {
          uuid: null,
          firstname: null,
          lastname: null,
          phone: {
            dial: null,
            number: null,
          },
          email: null,
        },
      },
      extras: [],
      rooms: [],
      insurances: [],
      flights: [],
      attachments: [],
      payments: [],
      travelers: [],
      assistcards: [],
      promotions: [],
      penalizations: [],
      discount_penalizations: [],
      story: { uuid: '', enable_payment_method: true },
      chat: { uuid: '' },
      client_view_link: '',
      local_points: {
        amount: 0,
        available: 0,
        spent: 0,
        referring_amount: {
          min: 0,
          max: 0,
          all: [],
        },
        referral_code: '',
      },
      provider_notes: null,
      traveler_notes: null,
      timer: null,
      check_up: {
        sections: [],
        general: {
          is_liquidated: false,
          has_emergency_contact: false,
          has_passports: false,
          has_passports_expiry_valid: false,
          has_travelers: false,
          has_insurances: false,
        },
        by_user: [],
      },
    }
  },
  SET_RESUME: (state, data) => {
    state.resume = {
      is_same_liquidation_date: true,
    }
  },
  SET_SIDEBAR: (state, data) => {
    state.sidebar = {
      pending_amount: data.pending_amount,
      total_payments: data.total_payments,
      save: data.save,
    }
  },
  SET_SAVE: (state) => {
    state.sidebar.save = !state.sidebar.save
  },
  SET_ENABLE_BTN_SAVE: (state, data) => {
    state.sidebar.enable_btn_save = data
  },
  HANDLE_IS_SAME_LIQUIDATION_DATE: (state, data) => {
    state.resume.is_same_liquidation_date = data
  },

  SET_RESERVATION_DATA: (state, data) => {
    state.data = data
  },
  SET_RESERVATION_ASSISTCARDS: (state, data) => {
    state.data.assistcards = data
  },
  SET_RESERVATION_TRAVELERS: (state, data) => {
    data.travelers.map((t) => {
      t.empty = false
      t.loading = false
      t.checked = data.assistcards.some((a) => a.user.email === t.user.email)
    })

    /** se cuenta la cantidad de tickets y la cantidad de extras de tipo menores */
    const maxTravelers =
      data.tickets +
      state.data.extras
        .filter((t) => t.type === 'extra_minor')
        .reduce((prev, extra) => prev + extra.qty, 0)

    /** obtengo los travelers pendientes de agregar */
    const pendingTravelers = maxTravelers - data.travelers.length

    /** agrego el espacio disponible en blanco para que pueda agregar la info */
    // eslint-disable-next-line no-unused-vars
    for (const x of Array(pendingTravelers).keys()) {
      data.travelers.push({
        empty: true,
        loading: true,
        checked: false,
        user: {
          email: null,
          firstname: null,
          lastname: null,
        },
      })
    }

    state.data.travelers = data.travelers
  },
  SET_RESERVATION_EMERGENCY_CONTACT: (state, data) => {
    state.data.emergency_contact = {
      user: {
        uuid: data.uuid,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone,
      },
    }
  },
  SET_INSURANCES: (state, data) => {
    const traveler = state.data.travelers.find((t) => t.user.uuid === data.uuid)

    for (const [key, value] of Object.entries(data)) {
      if (key !== 'uuid' && Reflect.has(traveler, key)) {
        traveler[key] = value
      }
    }
  },
  SET_RESERVATION_FORM: (state, data) => {
    state.data.reservation.tickets = data.tickets
    state.data.reservation.departure_date.start = data.date
    state.data.reservation.departure_date.open = data.open

    /** los elementos `included` | `mandatory` deben coincidir en cantidad con los tickets */
    const types = ['extras', 'insurances', 'rooms']
    types.forEach((type) => {
      state.data[type]
        .filter((t) => t.group.included || t.group.mandatory)
        .forEach((t) => (t.qty = data.tickets))
    })

    state.data.flights
      .filter((t) =>
        t.groups.some((m) => m.group.included || m.group.mandatory)
      )
      .forEach((t) => (t.qty = data.tickets))

    // === update sidebar
    for (const [, items] of Object.entries(state.data.order.products)) {
      items.forEach((item) => {
        if (item.parent.uuid === null && item.code === data.code) {
          item.qty = data.tickets
          item.date = data.date
          item._shopping_cart.departure_date.start = data.date
          item._shopping_cart.departure_date.end = data.end
          item.open = data.open
        }
      })
    }
  },
  SET_RESERVATION_DATE: (state, data) => {
    state.data.reservation.departure_date.start = data.start
    state.data.reservation.departure_date.end = data.end
  },
  REMOVE_ITEMS: (state, type) => {
    state.data[type] = []
  },
  RESET_ITEMS: (state, type) => {
    if (type === 'flights') {
      state.data[type] = state.data[type].filter((t) =>
        t.groups.some((m) => m.group.included || m.group.mandatory)
      )
    } else {
      state.data[type] = state.data[type].filter(
        (t) => t.group.included || t.group.mandatory
      )
    }
  },
  UPDATE_ITEM: (state, { type, data }) => {
    const item = state.data[type].find((t) => t.uuid === data.uuid) || {
      is_new: true,
    }
    for (const [key, value] of Object.entries(data)) {
      if (key !== 'is_new') {
        /** el precio solo se actualiza la vez que se agrega, no cuando se aumenta qty */
        if (key === 'price' && item.is_new) {
          item[key] = value
        } else if (key !== 'price') {
          item[key] = value
        }
      }
    }

    if (!state.data[type].some((t) => t.uuid === data.uuid)) {
      state.data[type].push(item)
    } else if (!item.checked) {
      state.data[type] = state.data[type].filter((t) => t.uuid !== data.uuid)
    }
  },
  UPDATE_PAYMENT_LOGS: (state, { uuid, token }) => {
    const payment = state.data.payments.find((t) => t.uuid === uuid)

    if (payment) {
      payment.logs.push({ token })
    }
  },
  UPDATE_PRODUCTS: (state) => {
    const types = ['extras', 'insurances', 'rooms', 'flights']

    let parent = null
    let index = null
    for (const [, items] of Object.entries(state.data.order.products)) {
      items.forEach((item, i) => {
        if (
          item.parent.uuid === null &&
          item.code !== null &&
          item.code === state.data.reservation.code
        ) {
          parent = item
          index = i
        }
      })
    }

    types.forEach((type) => {
      /** agregando/editando productos asociados */
      state.data[type].forEach((product) => {
        const item = state.data.order.products[type].find(
          (t) =>
            product.uuid === t.uuid &&
            t.parent.uuid === parent.uuid &&
            t.parent.index === index
        )

        if (item) {
          /** se edita un producto asociado */
          item.qty = product.qty
          item.unit = product.unit
        } else {
          /** se agrega uno nuevo */
          state.data.order.products[type].push({
            uuid: product.uuid,
            is_new: true,
            price: product.price,
            qty: product.qty,
            unit: product.unit,
            parent: {
              uuid: parent.uuid,
              index,
            },
            has_coupons: false,
            promotions: [],
            coupons: [],
            _shopping_cart: {
              name: product.name,
            },
          })
        }
      })

      const list = state.data[type].map((t) => t.uuid)

      state.data.order.products[type] = state.data.order.products[type]
        /** asociados al padre actual */
        .filter(
          (t) => t.parent.uuid === parent.uuid && t.parent.index === index
        )
        /** excluyendo los que ya no estan seleccionados */
        .filter((t) => list.includes(t.uuid))
    })
  },
  SET_CHECK_UP: (state, data) => {
    state.data.check_up = data
  },
}

export const getters = {}

export const actions = {
  removeItems({ commit }) {
    const types = ['extras', 'insurances', 'rooms', 'flights']
    types.forEach((type) => commit('REMOVE_ITEMS', type))
  },
  resetItems({ commit }) {
    const types = ['extras', 'insurances', 'rooms', 'flights']
    types.forEach((type) => commit('RESET_ITEMS', type))
  },
  updateExtra({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'extras', data })
  },
  updateInsurance({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'insurances', data })
  },
  updateRoom({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'rooms', data })
  },
  updateFlight({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'flights', data })
  },

  setReservationTravelers({ commit }, data) {
    commit('SET_RESERVATION_TRAVELERS', data)
  },
  setReservationInsurances({ commit }, data) {
    commit('SET_INSURANCES', data)
  },
  setReservationForm({ commit }, data) {
    commit('SET_RESERVATION_FORM', data)
  },
  setReservationDate({ commit }, data) {
    commit('SET_RESERVATION_DATE', data)
  },
  setReservationEmergencyContact({ commit }, data) {
    commit('SET_RESERVATION_EMERGENCY_CONTACT', data)
  },
  setReservationAssistcards({ commit }, data) {
    commit('SET_RESERVATION_ASSISTCARDS', data)
  },
  toogleSave({ commit }) {
    commit('SET_SAVE')
  },
  enableBtnSave({ commit }, data) {
    commit('SET_ENABLE_BTN_SAVE', data)
  },
  isSameLiquidationDate({ commit }, data) {
    commit('HANDLE_IS_SAME_LIQUIDATION_DATE', data)
  },
  updatePaymentLogs({ commit }, data) {
    commit('UPDATE_PAYMENT_LOGS', data)
  },
  updateProducts({ commit }) {
    commit('UPDATE_PRODUCTS')
  },
  async getReservationDetail({ commit }, form) {
    commit('SET_DEFAULT')

    let permalink = null
    let name = null
    let currency = null
    let response = 'error'
    const url = form.is_auth
      ? `/api/v1.5/orders/details/${form.code}`
      : `/api/v1.5/orders/show/${form.code}/public`

    try {
      const { data, status } = await this.$axios.get(url)

      if (status === 'success') {
        const updateItems = (data) => {
          /** actualizando extras */
          data.extras.map((e) => {
            e.checked = true
            e.is_new = false
            e.price = parseFloat(e.price)

            e.qty = parseInt(e.qty)
            e._qty = parseInt(e.qty)
          })

          /** actualizando rooms */
          data.rooms.map((e) => {
            e.checked = true
            e.is_new = false
            e.price = parseFloat(e.price)

            e.qty = parseInt(e.qty)
            e._qty = parseInt(e.qty)

            e.unit = parseInt(e.unit)
            e._unit = parseInt(e.unit)
          })

          /** actualizando insurances */
          data.insurances.map((e) => {
            e.checked = true
            e.is_new = false
            e.price = parseFloat(e.price)

            e.qty = parseInt(e.qty)
            e._qty = parseInt(e.qty)

            e.unit = parseInt(e.unit)
            e._unit = parseInt(e.unit)
          })

          /** actualizando flights */
          data.flights.map((e) => {
            e.checked = true
            e.is_new = false
            e.price = parseFloat(e.price)

            e.qty = parseInt(e.qty)
            e._qty = parseInt(e.qty)
          })
        }

        if (form.is_detail) {
          /** convirtiendo la fecha de los seguros */
          data.assistcards.map((t) => {
            t.initial_effective_date = this.$dayjs(
              t.initial_effective_date,
              'YYYY-MM-DD'
            )
            t.final_effective_date = this.$dayjs(
              t.final_effective_date,
              'YYYY-MM-DD'
            )
          })

          if (data.emergency_contact) {
            data.emergency_contact = {
              user: {
                uuid: data.emergency_contact.uuid,
                email: data.emergency_contact.email,
                firstname: data.emergency_contact.firstname,
                lastname: data.emergency_contact.lastname,
                phone: data.emergency_contact.phone,
              },
            }
          } else {
            data.emergency_contact = {
              user: {
                uuid: null,
                email: null,
                firstname: null,
                lastname: null,
                phone: {
                  dial: null,
                  number: null,
                },
              },
            }
          }

          /** actualizando attachments */
          data.attachments.map((e) => {
            e.attachment.created_at = this.$dayjs(
              e.attachment.created_at,
              'YYYY-MM-DD HH:mm:ss'
            )
          })

          /** actualizando pagos */
          data.payments.map((payment) => {
            payment.created_at = this.$dayjs(
              payment.created_at,
              'YYYY-MM-DD HH:mm:ss'
            )
            payment.status = payment.status ? payment.status : '-'
          })
        } else if (data.emergency_contact) {
          /** actualizar contacto de emergencia */
          data.emergency_contact = {
            user: {
              uuid: data.emergency_contact.uuid,
              email: data.emergency_contact.email,
              firstname: data.emergency_contact.firstname,
              lastname: data.emergency_contact.lastname,
              phone: data.emergency_contact.phone,
            },
          }
        } else {
          data.emergency_contact = {
            user: {
              uuid: null,
              email: null,
              firstname: null,
              lastname: null,
              phone: {
                dial: null,
                number: null,
              },
            },
          }
        }

        permalink = data.product.permalink // || data.product.uuid
        name = data.product.name
        currency = data.currency.code

        updateItems(data)

        /** las promos que tiene codigo, se usa el codigo en vez del nombre */
        data.promotions.filter((t) => t.code).map((t) => (t.name = t.code))

        data.timer = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')

        data.travelers.map((t) => {
          if (hasOwnProperties(t, 'passport.expiry') && !!t.passport.expiry) {
            t.passport.expiry = this.$dayjs(
              t.passport.expiry,
              'YYYY-MM-DD'
            ).format('YYYY-MM-DD')
          }
        })

        /** @todo solucion temporal mientras se acomoda la vista detalles */
        data.reservation.pending_amount = data.order.pending_amount

        commit('SET_RESERVATION_DATA', data)
        commit('SET_RESERVATION_TRAVELERS', {
          travelers: data.travelers,
          tickets: data.reservation.tickets,
          assistcards: data.assistcards,
        })

        response = 'success'
      }
    } catch (error) {}

    return { permalink, name, currency, status: response }
  },
  async getReservationCheckUp({ commit }, form) {
    const url = form.is_auth
      ? `/api/v1.5/orders/show/${form.code}/check-up`
      : `/api/v1.5/orders/show/${form.code}/public/check-up`

    try {
      const { data, status } = await this.$axios.get(url)

      if (status === 'success') {
        commit('SET_CHECK_UP', data)
      }
    } catch (error) {}
  },
}
