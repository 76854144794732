import { getUserAgentData } from '@/utils/user-agent'

/** https://nuxtjs.org/guide/routing/#middleware */
export default async ({ isHMR, req, store }) => {
  // if (isHMR || process.static || process.client) return

  const data = getUserAgentData(req)

  /** cuando navego entre las paginas cierro cualquier posible modal que haya quedado abierto */
  store.dispatch('modals/setDefault')
  await store.dispatch('device/init', data)
}
