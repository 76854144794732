export const strict = process.env.NODE_ENV !== 'production'

const callback = (item) => {
  return {
    uuid: item.uuid,
    type: item.type ? item.type : 'experience',
    title: item.name,
    description: item.headline,
    days: item.duration,
    categories: item.categories || [],
    total_amount: parseFloat(item.sales_plan.total_amount),
    reviews: {
      count: parseFloat(item.reviews.count),
      value: parseFloat(item.reviews.value),
    },
    starting_point: item.starting_point,
    permalink: item.permalink,
    promotions: item.promotions,
    country: item.country,
    media: {
      type: 'image',
      data: {
        url: item.cover.url,
        srcset: item.cover.srcset,
        uuid: item.cover.uuid,
      },
    },
    is_lad: item.is_lad,
    includes: item.includes,
    languages: item.languages,
  }
}

const generateTag = (title) => {
  const seasons = {
    verano: 'summer',
    summer: 'summer',
    otono: 'fall',
    fall: 'fall',
    invierno: 'winter',
    winter: 'winter',
    primavera: 'spring',
    spring: 'spring',
  }

  const normalizedTitle = title
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()

  for (const [key, value] of Object.entries(seasons)) {
    if (normalizedTitle.includes(key)) {
      return value
    }
  }

  return 'unknown'
}

export const state = () => ({
  breadcrumbs: { continent: { name: null, slug: null } },
  country: {
    alpha_2_code: null,
    alpha_3_code: null,
    dial_code: null,
    flag: null,
    flag_code: null,
    name: null,
    slug: null,
    average_score: 0,
    total_reviews: 0,
  },
  header: {
    name: null,
    description: null,
    permalink: { default: null, es: null, en: null },
    media: {
      src: null,
    },
  },
  metadata: {
    title: null,
    description: null,
  },
  faqs: [],
  rainfalls: [],
  temperatures: [],
  travel_information: [],
  when_travel: [],
  reviews: [],
  durations: [],
  featured: [],
  outlet: [],
  tours: [],
})

export const mutations = {
  SET_DEFAULT: (state) => {
    state.breadcrumbs = { continent: { name: null, slug: null } }
    state.country = {
      alpha_2_code: null,
      alpha_3_code: null,
      dial_code: null,
      flag: null,
      flag_code: null,
      name: null,
      slug: null,
      average_score: 0,
      total_reviews: 0,
    }

    state.header = {
      name: null,
      description: null,
      permalink: { default: null, es: null, en: null },
      media: {
        src: null,
      },
    }

    state.metadata = {
      title: null,
      description: null,
    }

    state.faqs = []
    state.rainfalls = []
    state.temperatures = []
    state.travel_information = []
    state.when_travel = []
    state.reviews = []
    state.durations = []
    state.featured = []
    state.outlet = []
    state.tours = []
  },
  SET_BREADCRUMBS: (state, data) => {
    state.breadcrumbs = data || {}
  },
  SET_COUNTRY: (state, data) => {
    state.country = data || {}
  },
  SET_FAQS: (state, data) => {
    state.faqs = data || []
  },
  SET_HEADER: (state, data) => {
    state.header = data || {}
  },
  SET_METADATA: (state, data) => {
    state.metadata = data || {}
  },
  SET_RAINFALLS: (state, data) => {
    state.rainfalls = data || []
  },
  SET_TEMPERATURES: (state, data) => {
    state.temperatures = data || []
  },
  SET_TRAVEL_INFORMATION: (state, data) => {
    state.travel_information = data || []
  },
  SET_WHEN_TRAVEL: (state, data) => {
    state.when_travel = (data || []).map((item) => ({
      ...item,
      slug: generateTag(item.title),
    }))
  },
  SET_REVIEWS: (state, data) => {
    state.reviews = data || []
  },
  SET_DURATIONS: (state, data) => {
    state.durations = data || []
  },
  SET_FEATURED: (state, data) => {
    state.featured = (data || []).map((item) => callback(item))
  },
  SET_OUTLET: (state, data) => {
    state.outlet = (data || []).map((item) => callback(item))
  },
  SET_TOURS: (state, data) => {
    state.tours = (data || []).map((item) => callback(item))
  },
}

export const getters = {}

export const actions = {
  async getData({ commit }, { country }) {
    try {
      const url = `/api/countries/${country}/information`

      const { status, data } = await this.$axios.get(url)

      if (status === 'success') {
        for (const [key, value] of Object.entries(data)) {
          const index = key.toUpperCase()

          try {
            commit(`SET_${index}`, value)
          } catch (error) {}
        }
      } else {
        commit('SET_DEFAULT', false)
      }
    } catch (error) {
      commit('SET_DEFAULT', false)
    }
  },
  setDefault({ commit }, data) {
    commit('SET_DEFAULT', false)
  },
}
