export default ({ app }, inject) => {
  const zohoSurvey = {
    init: (survey, settings = {}) => {
      /* eslint-disable */
      ;((w, d, s, f, m, n, o) => {
        o = 'https://survey.zohopublic.com'
        w[f] =
          w[f] ||
          function () {
            ;(w[f].p = w[f].p || []).push(arguments)
          }
        ;(m = d.createElement(s)), (n = d.getElementsByTagName(s)[0])
        m.async = 1
        m.src = o + `/api/v1/public/livesurveys/${survey}/popup/script`
        n.parentNode.insertBefore(m, n)
        zs_intercept(o, survey, settings)
      })(window, document, 'script', 'zs_intercept')
      /* eslint-enable */
    },
  }

  inject('zohoSurvey', zohoSurvey)
}
