import Vue from 'vue'
import LazyLoading from '@/components/lazy-loading'
import LadAffix from '@/components/affix'
import LadImage from '@/components/frontends/Image/index'
import LadPrice from '@/components/frontends/Price/index'

Vue.component(LazyLoading.name, LazyLoading)
Vue.component(LadAffix.name, LadAffix)
Vue.component(LadImage.name, LadImage)
Vue.component(LadPrice.name, LadPrice)

Vue.component('LadBestPrice', () =>
  import(
    /* webpackChunkName: "lad-best-price" */ '@/components/frontends/Price/best-price'
  )
)
