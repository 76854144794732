export default (req, res, next) => {
  /** solo los request realizados a los `callback` de `social-auth` se deben procesar xq `custom-login` tambien tiene un POST */
  if (
    req.method === 'POST' &&
    (/\/auth\/.*callback$/.test(req.url) || /\/social-auth/.test(req.url))
  ) {
    let body = ''

    const parseQueryString = (queryString) => {
      const params = {}
      const pairs = queryString.split('&')

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=')

        params[key] = value
      })

      return params
    }

    req.on('data', (chunk) => {
      body += chunk.toString()
    })

    req.on('end', () => {
      if (req.headers['content-type'].endsWith('/x-www-form-urlencoded')) {
        req.body = parseQueryString(body)
      } else if (req.headers['content-type'].endsWith('/json')) {
        req.body = JSON.parse(body)
      } else {
        req.body = {}
      }

      return next()
    })
  } else {
    return next()
  }
}
