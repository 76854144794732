import load from '@/utils/load-promise'

const sherpaPlugin = (ctx, inject) => {
  const sherpa = {
    init: (settings) => {
      // const options = {
      //   requirementsApiKey: process.env.SHERPA_API_KEY, // individual assigned API keyu
      //   affiliateId: process.env.SHERPA_AFFILIATE_ID, // individual assigned affiliateId
      // }

      // window.$sherpa.V1.createWidget(Object.assign({}, options, settings))
      const options = {
        placement: 'mmb',
      }

      window.$sherpa.V2.createElement(
        'trip',
        Object.assign({}, options, settings)
      ).mount('#sherpa-widget')
    },
    load: (settings) => {
      const src = `https://sdk.joinsherpa.io/widget.js?appId=${process.env.SHERPA_APP_ID}`
      if (window.$sherpa) {
        /** @todo verificar como eliminar el warning de inicializado en la navegacion con SPA */
        sherpa.init(settings)
      } else {
        load
          .js(src, 'sherpa-js')
          .then(() => sherpa.init(settings))
          .catch(() => {})
      }
    },
  }

  inject('sherpa', sherpa)
}

export default sherpaPlugin
