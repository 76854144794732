export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  logo: 'LocalAdventures',
  locale: process.env.APP_LOCALE,
  currency: process.env.APP_CURRENCY,
  i18nKeys: [
    /**
     * almaceno las keys de los idiomas agregados en `plugins/i18n/index.js`
     * si despues de auth, no tengo ese idioma cargado para i18n debo recargar la pagina
     * */
  ],

  locales: [{ code: 'es_MX' }, { code: 'en_US' }, { code: 'es_CO' }],
  currencies: [{ code: 'USD' }, { code: 'MXN' }, { code: 'COP' }],
  countries: [],
  popular_countries: [],
  popular_cities: {},
  continents: [],
  gateway_enabled: {
    dlocal: false,
    openpay: true,
  },
  exchange_rate: [],
})

export const mutations = {
  SET_LOCALE(state, locale) {
    const normalized = (locale || '').replace('-', '_')

    /** if temporal, DEBE quitarse eventualmente */
    if (process.env.APP_URL === process.env.APP_URL_EN_US) {
      if (state.locales.map((t) => t.code).includes(normalized)) {
        state.locale = normalized
      }
    }
  },
  SET_CURRENCY(state, currency) {
    const normalized = (currency || '').toUpperCase()

    if (state.currencies.map((t) => t.code).includes(normalized)) {
      state.currency = normalized
    }
  },
  SET_LOCALES(state, data) {
    state.locales = Array.isArray(data) ? data : []
  },
  SET_CURRENCIES(state, data) {
    state.currencies = Array.isArray(data) ? data : []
  },
  SET_COUNTRIES(state, data) {
    state.countries = Array.isArray(data) ? data : []
  },
  SET_POPULAR_COUNTRIES(state, data) {
    state.popular_countries = Array.isArray(data) ? data : []
  },
  SET_POPULAR_CITIES(state, data) {
    state.popular_cities = data
  },
  SET_CONTINENTS(state, data) {
    state.continents = Array.isArray(data) ? data : []
  },
  SET_GATEWAY_ENABLED(state, data) {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.gateway_enabled, key)) {
        state.gateway_enabled[key] = value
      }
    }
  },
  SET_EXCHANGE_RATE(state, data) {
    state.exchange_rate = Array.isArray(data) ? data : []
  },
  SET_I18N_KEYS(state, data) {
    state.i18nKeys = Array.isArray(data) ? data : []
  },
  SET_LOGO(state, data) {
    state.logo = data
  },
}

export const getters = {
  language(state) {
    return state.locale.split('_')[0]
  },
}

export const actions = {
  async hydrate({ commit }) {
    try {
      const { data, status } = await this.$axios.get(`/api/settings/client`)

      if (status === 'success') {
        commit('SET_LOCALES', data.localizations)
        commit('SET_CURRENCIES', data.currencies)
        commit('SET_COUNTRIES', data.countries)
        commit('SET_CONTINENTS', data.continents)
        commit('SET_EXCHANGE_RATE', data.exchange_rate)
        commit('SET_POPULAR_COUNTRIES', data.popular_countries)
        commit('SET_POPULAR_CITIES', data.popular_cities)
        // commit('SET_GATEWAY_ENABLED', data.gateway_enabled)
      }
    } catch (error) {}
  },
  init({ commit }, { locale = null, currency = null }) {
    commit('SET_LOCALE', locale)
    commit('SET_CURRENCY', currency)
  },
  setLocale({ commit }, data) {
    commit('SET_LOCALE', data)
  },
  setCurrency({ commit }, data) {
    commit('SET_CURRENCY', data)
  },
  setI18nKeys({ commit }, data) {
    commit('SET_I18N_KEYS', data)
  },
  setLogo({ commit }, data) {
    commit('SET_LOGO', data)
  },
}
