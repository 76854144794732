<template>
  <div :class="classObj" class="app-wrapper">
    <LadCustomLoading v-if="loading.enabled" v-bind="loading" />

    <div v-if="isValidOneTap" id="g_id_onload" v-bind="googleOneTap" />

    <div v-if="!isOnline" class="wrapper-pwa-offline">
      <el-alert
        :title="$t('pwa.offline')"
        type="error"
        :closable="false"
        show-icon
      />
    </div>

    <LazyLoading
      :key="`sidebar`"
      :loaded="lazy.sidebar"
      @loaded="(e) => (lazy.sidebar = e)"
    >
      <LadSidebar v-if="lazy.sidebar" />
    </LazyLoading>

    <div class="main-container">
      <LadNavbar v-if="!(isMobile && isLandingNavbar)" />

      <LadCountDown
        v-if="enableCountDown || isPpcv1"
        v-bind="{ builder, ...advertisement }"
        :route-name="routeName"
      />

      <LadCountDown
        v-else-if="enableCountDownReservation"
        :builder="countDownOrder"
        :cta="orderDeathLine.cta"
        :route-name="routeName"
      />

      <section id="app-main" class="app-main">
        <Nuxt />
      </section>

      <!-- <LadBackToTop
        cache-prefix="footer"
        :cache-key="cacheKey"
        cache-type="static"
      /> -->

      <LadFooter />

      <template v-if="isMobile && !isIOS && !isLandingNavbar">
        <LadPromptPermission />
        <LadPromptInstall />
      </template>

      <template
        v-if="
          isMobile &&
          (visibleBottomNavbar ||
            isBottomNavbarPwa ||
            isPpcv1 ||
            isSearch ||
            isCountry)
        "
      >
        <LadExperienceNavbarSummary v-if="(isExperienceNavbar  || isPpcv1)" />
        <LadLandingNavbarSummary v-else-if="!isMobile && isLandingNavbar" />
        <LadCheckoutNavbarSummary
          v-else-if="isCheckoutNavbar && !checkSuccessComplete"
          :is-checkout-layaway="isCheckoutLayaway"
        />

        <LadShoppingNavbarSummary v-else-if="isShoppingCart" />

        <LadFiltersNavbarSummary v-else-if="isSearch" />

        <LadCountryCta v-else-if="isCountry" />

        <LadBottomNavbar v-else>
          <LadReservationEditNavbar v-if="isReservationEditNavbar" />
        </LadBottomNavbar>
      </template>
    </div>

    <LadAuthModal v-if="isAuthModalOpen" />

    <LadTripsModal v-if="isTripsModalOpen" />

    <LadSearchMyTour v-if="isFindMyTourModalOpen" />

    <template v-if="isCheckoutNavbar">
      <LadPaymentAtratoWrapper />
    </template>

    <div id="fb-root"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import { debounce } from '@/utils'
import ResizeMixin from '@/utils/mixin/resize-handler'
import OptinmonsterMixin from '@/utils/mixin/optinmonster'
import {
  SEARCH_TYPE_ROUTER,
  SEARCH_COUNTRY_TYPE_ROUTER,
} from '@/utils/static-router-alias'
import { encryptSha256 } from '@/utils/crypto'
import LadCustomLoading from '@/components/loading/index'

export default {
  name: 'Layout',
  components: {
    LadCustomLoading,
    LadBottomNavbar: () =>
      import(
        /* webpackChunkName: "lad-bottom-navbar" */ '@/components/layout/BottomNavbar'
      ),
    LadExperienceNavbarSummary: () =>
      import(
        /* webpackChunkName: "lad-experience-summary-drawer" */ '@/components/navbars/experience-summary'
      ),
    LadLandingNavbarSummary: () =>
      import(
        /* webpackChunkName: "lad-landing-summary-drawer" */ '@/components/navbars/landing-summary'
      ),
    LadCheckoutNavbarSummary: () =>
      import(
        /* webpackChunkName: "lad-checkout-summary-drawer" */ '@/components/navbars/checkout-summary'
      ),
    LadShoppingNavbarSummary: () =>
      import(
        /* webpackChunkName: "lad-shopping-summary-drawer" */ '@/components/navbars/shopping-summary'
      ),
    LadFiltersNavbarSummary: () =>
      import(
        /* webpackChunkName: "lad-filters-summary-drawer" */ '@/components/navbars/filters-summary'
      ),
    LadCountryCta: () =>
      import(
        /* webpackChunkName: "lad-country-cta-drawer" */ '@/components/navbars/country-cta'
      ),
    LadNavbar: () =>
      import(
        /* webpackChunkName: "lad-navbar" */ '@/components/layout/Navigation/Navbar'
      ),
    LadSidebar: () =>
      import(
        /* webpackChunkName: "lad-sidebar" */ '@/components/layout/Navigation/Sidebar'
      ),
    LadAuthModal: () =>
      import(
        /* webpackChunkName: "lad-auth-modal" */ '@/components/AuthCustom'
      ),
    LadFooter: hydrateWhenVisible(
      () =>
        import(
          /* webpackChunkName: "lad-footer" */ '@/components/layout/Footer'
        ),
      { observerOptions: { rootMargin: '100px' } }
    ),
    // LadBackToTop: hydrateWhenVisible(
    //   () =>
    //     import(
    //       /* webpackChunkName: "lad-back-to-top" */ '@/components/layout/back-to-top'
    //     ),
    //   { observerOptions: { rootMargin: '100px' } }
    // ),
    LadTripsModal: () =>
      import(
        /* webpackChunkName: "lad-trips-modal" */ '@/components/trips-modal'
      ),
    LadReservationEditNavbar: () =>
      import(
        /* webpackChunkName: "lad-reservation-edit-navbar" */ '@/components/navbars/reservation-edit'
      ),
    LadCountDown: () =>
      import(/* webpackChunkName: "lad-countdown" */ '@/components/countdown'),
    LadPromptInstall: () =>
      import(
        /* webpackChunkName: "lad-prompt-install" */ '@/components/pwa/prompt-install'
      ),
    LadPromptPermission: () =>
      import(
        /* webpackChunkName: "lad-prompt-permission" */ '@/components/pwa/prompt-permission'
      ),
    LadSearchMyTour: () =>
      import(
        /* webpackChunkName: "lad-search-my-tour" */ '@/components/pages/home/partials/search-my-tour'
      ),
    LadPaymentAtratoWrapper: () =>
      import(
        /* webpackChunkName: "lad-atrato-wrapper" */ '@/components/payment-methods/components/atrato/wrapper'
      ),
    // LadPaymentZenkiWrapper: () =>
    //   import(
    //     /* webpackChunkName: "lad-zenki-wrapper" */ '@/components/payment-methods/components/zenki/wrapper'
    //   ),
  },
  mixins: [ResizeMixin, OptinmonsterMixin],
  data() {
    return {
      advertisement: {
        cta: [
          {
            page: [
              'index',
              'continent',
              'country',
              'city',
              'mountains-in-mexico',
              'search',
            ],
            link: '/outlet',
            text: this.$t('route.outlet_index'),
          },
          { page: ['outlet'], link: null, text: null },
          {
            page: ['experience', 'package'],
            link: '#lad-landing--form',
            text: this.$t('general.sign_up_here'),
          },
          {
            page: ['landing', 'flight-landing', 'hotel-landing'],
            link: '#lad-landing--form',
            text: this.$t('general.sign_up_here'),
          },
        ],
      },
      orderDeathLine: {
        cta: [
          {
            page: ['user-reservation-code-tab', 'public-reservation-code-tab'],
            link: null,
            text: null,
          },
        ],
      },
      timer: null,
      lazy: {
        sidebar: false,
      },
      routes: 0,
      loading: {
        enabled: false,
        source: null,
      },
    }
  },
  computed: {
    isPpcv1() {
      return this.$route.params.test === 'ppcv1'
    },
    ...mapGetters('settings', ['language']),
    ...mapState('settings', ['currency', 'locale']),
    ...mapState('advertisement', ['builder']),
    ...mapState('advertisement', {
      countDownOrder: 'orders',
    }),
    ...mapState('app', ['sidebar', 'wrapperClassName', 'othersClassName']),
    ...mapState('app', {
      isOnline: (state) => state.pwa.isOnline,
      isBottomNavbarVisible: (state) => state.bottomNavbar.opened,
      isBottomNavbarPwa: (state) => state.bottomNavbar.showPromptPwa,
    }),
    ...mapState('device', ['oldBrowsers', 'os']),
    ...mapState('checkout', {
      checkSuccessComplete: (state) => state.success.complete,
    }),
    ...mapGetters('device', ['isMobile', 'isIOS']),
    ...mapState('modals', {
      isAuthModalOpen: (state) => state.auth.opened,
      isTripsModalOpen: (state) => state.trips.opened,
      isFindMyTourModalOpen: (state) => state.findMyTour.opened,
    }),
    ...mapState('profile', {
      reservation: (state) => ({
        status: state.data.reservation.status,
        confirmation_date: state.data.reservation.confirmation_date,
        cancellation_date: state.data.reservation.cancellation_date,
      }),
    }),
    routeName() {
      const fullName = this.$route.name.toLowerCase()
      let name = fullName.split('-')

      const regex = /^b-(country|city|continent)-type$/
      if (regex.test(fullName)) {
        name = ['search']
      }

      if (fullName === 'product-permalink' || this.isPpcv1) {
        name[0] = 'experience'
      } else if (fullName === 'checkout-layaway' && this.checkSuccessComplete) {
        name = ['checkout-success']
      } else if (
        fullName.startsWith('user-') ||
        fullName.startsWith('public-reservation') ||
        fullName === 'shopping-cart'
      ) {
        name = [fullName]
      } else if (
        fullName.startsWith('l-') ||
        fullName.startsWith('p-') ||
        fullName.startsWith('influencer-')
      ) {
        name = ['landing']
      } else if (fullName.startsWith('f-')) {
        name = ['flight-landing']
      } else if (fullName.startsWith('h-')) {
        name = ['hotel-landing']
      } else if (fullName.startsWith('c-')) {
        name.shift()
        name = [name.join('-')]
      } else if (name.length > 0) {
        if (
          name[0] === 'search' &&
          typeof this.$route.params.type === 'string'
        ) {
          /** obtengo el tipo de busqueda que se esta realizando */
          name = [this.$route.params.type]
        }
      }

      return name[0]
    },
    isExperienceNavbar() {
      return this.routeName === 'experience'
    },
    isLandingNavbar() {
      return this.routeName === 'landing'
    },
    isCheckoutLayaway() {
      return this.routeName.startsWith('checkout')
    },
    isShoppingCart() {
      return this.routeName.startsWith('shopping-cart')
    },
    isSearch() {
      /** todas rutas de search */
      const types = Object.values(SEARCH_TYPE_ROUTER.groups).flat()
      types.push(
        'search',
        'outlet',
        'buen-fin',
        'mountains-in-mexico',
        'outlet-test'
      )

      return types.includes(this.routeName)
    },
    isCountry() {
      const types = SEARCH_COUNTRY_TYPE_ROUTER.groups.country

      return types.includes(this.routeName)
    },
    isCheckoutNavbar() {
      return [
        'checkout',
        'user-reservation-code-tab',
        'public-reservation-code-tab',
      ].includes(this.routeName)
    },
    isReservationEditNavbar() {
      return this.routeName === 'user-reservation-code-edit'
    },
    classObj() {
      const data = {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.isMobile,
        showBottomNavbar: true,
        oldBrowsers: this.oldBrowsers,
        [`page-${this.routeName}`]: true,
        [this.wrapperClassName]: true,
      }

      this.othersClassName.forEach((c) => (data[c] = true))

      return data
    },
    visibleBottomNavbar() {
      const available = [
        'checkout',
        'experience',
        'landing',
        'shopping-cart',
        'user-reservation-code-tab',
        'public-reservation-code-tab',
        'user-reservation-code-edit',
      ]

      return available.includes(this.routeName)
    },
    enableCountDown() {
      return this.builder.name !== null
        ? this.advertisement.cta.some((t) => t.page.includes(this.routeName))
        : false
    },
    enableCountDownReservation() {
      return (
        this.orderDeathLine.cta.some((t) => t.page.includes(this.routeName)) &&
        this.countDownOrder.show_count_down
      )
    },
    cacheKey() {
      return `default-${this.language}-${this.currency}--${
        this.isMobile ? 'm' : 'd'
      }`
    },
    isValidOneTap() {
      const _routeNames = ['index'].concat(
        SEARCH_TYPE_ROUTER.groups.continent,
        SEARCH_TYPE_ROUTER.groups.country,
        SEARCH_TYPE_ROUTER.groups.city
      )

      return (
        !this.isAuth &&
        _routeNames.includes(this.routeName) &&
        !this.$route.query.redirect
      )
    },
    googleOneTap() {
      let path =
        process.env.NODE_ENV === 'production' || process.server
          ? process.env.APP_URL
          : window.location.href

      if (!path.endsWith('/')) {
        path += '/'
      }

      return {
        'data-client_id': process.env.GOOGLE_CLIENT_ID,
        'data-login_uri': `${path}social-auth`,
        // 'data-cancel_on_tap_outside': false,
        'data-auto_prompt': true,
        'data-use_fedcm_for_prompt': true,
      }
    },
  },
  watch: {
    locale: {
      immediate: true,
      handler(newValue) {
        /** se tuvo que mover del middleware `check-auth` debido a que no se actualizaba bien el cambio de idioma */
        try {
          /** adicionando idioma por default */
          const value = (newValue || '').startsWith('es') ? 'es' : 'en'

          this.$dayjs.locale(value)
        } catch (error) {}
      },
    },
    isAuth: {
      immediate: true,
      handler(newValue) {
        if (process.client && this.$bugsnag) {
          if (newValue) {
            const user = this.$store.state.auth.user
            this.$bugsnag.setUser(
              user.uuid,
              user.email,
              `${user.profile.firstname} ${user.profile.lastname}`
            )

            if (typeof this.$tiktok !== 'undefined') {
              this.$tiktok.identify(
                encryptSha256({
                  email: user.email,
                  phone_number: `${user.profile.phone.dial}${user.profile.phone.number}`,
                  external_id: user.uuid,
                })
              )
            }
          } else {
            this.$bugsnag.setUser(null)
          }
        }
      },
    },
    reservation: {
      immediate: true,
      async handler(data) {
        let countDownData = { show_count_down: false }

        if (['new'].includes(data.status) && data.cancellation_date) {
          countDownData = {
            name: this.$t(`profile.details.first_payment`),
            description: this.$t(`profile.details.first_payment_description`),
            show_count_down: true,
            start: data.confirmation_date,
            end: data.cancellation_date,
          }
        }
        await this.$store.dispatch(
          'advertisement/setOrderCountDown',
          countDownData
        )
      },
    },
    $route: {
      immediate: true,
      async handler(route) {
        if (process.server) {
          /** Cambia el logo por uno personalizado segun el utm_source */
          if (route.query.utm_source === 'flair') {
            await this.$store.dispatch('settings/setLogo', 'FlairAirlines')
          }

          return false
        }

        const query = route.query || {}
        const data = {}

        /** la idea es cargar stripe en la primera vez que navega el usuario o directamente en las paginas de pagos */
        if (
          ++this.routes === 2 ||
          [
            'user-reservation-code-tab',
            'public-reservation-code-tab',
            'checkout',
          ].includes(this.routeName)
        ) {
          this.LadNextTick(() => this.$stripe.load(), 'Layout > Stripe')
          this.LadNextTick(() => this.$openPay.load(), 'Layout > OpenPay')
          this.LadNextTick(
            () => this.$mercadoPago.load(),
            'Layout > MercadoPago'
          )

          this.routes = 2
        }

        /** guardando params utm_ en local */
        Object.keys(query)
          .filter((key) => key.startsWith('utm_'))
          .forEach((key) => {
            data[key] = query[key]
          })

        /** solo para landings de unfluencers */
        if (
          route.name.toLowerCase().startsWith('influencer-') &&
          this.isLandingNavbar
        ) {
          const item = await this.$store.dispatch('influencers/getByCode', {
            code: route.params.influencer,
            page: route.name,
          })

          /** las landing pages de influencers tienen utm especiales */
          if (item.isValid) {
            for (const [key, value] of Object.entries(item.page.utms)) {
              data[`utm_${key}`] = value
            }
          }
        }

        try {
          if (localStorage && Object.keys(data).length > 0) {
            localStorage.setItem(
              'lad-utm',
              JSON.stringify({
                date: this.$dayjs().toISOString(),
                utm: data,
              })
            )
          }
        } catch (error) {}
      },
    },
  },
  beforeMount() {
    this.__handleScroll = debounce(this.handleScroll, 100)
    this.__networkStatus = debounce(this.handleNetworkChange, 100)

    // eslint-disable-next-line nuxt/no-globals-in-created
    window.addEventListener('scroll', this.__handleScroll)
  },
  mounted() {
    this.$store.dispatch('shopping-cart/init')

    window.addEventListener('online', this.__networkStatus)
    window.addEventListener('offline', this.__networkStatus)

    this.$zohoWidget.load()

    try {
      /** first visit after 2020-10-10 aprox */
      if (localStorage) {
        if (localStorage.getItem('lad-first-visit')) {
          /** 2da visita */
          this.$store.dispatch('app/setPromptInstall', {
            showPromptInstall: true,
            source: 'second_visit',
          })
        } else {
          const today = this.$dayjs().toISOString()
          localStorage.setItem('lad-first-visit', today)
        }
      }
    } catch (error) {}

    this.$store.dispatch(
      'app/setPwaEnabled',
      (navigator && navigator.standalone) ||
        matchMedia('(display-mode: standalone)').matches
    )

    this.LadNextTick(() => (this.lazy.sidebar = true), 'Layout')
  },
  created() {
    if (process.client) {
      // eslint-disable-next-line nuxt/no-globals-in-created
      document.querySelector('html').classList.add(`is-${this.os}`)

      this.$nuxt.$on('custom-loading', (event) => {
        this.loading = event
      })
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)

    window.removeEventListener('scroll', this.__handleScroll)
    window.removeEventListener('online', this.__networkStatus)
    window.removeEventListener('offline', this.__networkStatus)

    this.$nuxt.$off('custom-loading')
  },
  methods: {
    handleScroll(e) {
      /** en el area de disponibilidad se oculta el bottom-navbar */
      let hiddenNavbar = document.querySelector(
        '#experience-wrapper--main-availability'
      )
      let isVisible = false
      if (!hiddenNavbar) {
        hiddenNavbar = document.querySelector('.page-landing .section-footer')
      }

      if (hiddenNavbar) {
        isVisible = window.scrollY < hiddenNavbar.offsetTop - 100

        if (
          window.scrollY + window.innerHeight + 100 >=
          document.body.scrollHeight
        ) {
          isVisible = false
        }
      }

      this.$store.dispatch('app/toggleBottomNavbar', isVisible)
    },
    handleNetworkChange() {
      this.$store.dispatch('app/setPwaisOnline', !!navigator.onLine)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/styles/scss/mixin.scss';

.app-wrapper {
  @include clearfix;

  position: relative;
  width: 100%;

  &.mobile.openSidebar {
    top: 0;
  }

  .wrapper-pwa-offline {
    .el-alert {
      border-radius: 0;
    }
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.app-main {
  min-height: calc(100vh - 150px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.page-main {
  .app-main {
    display: flex;
  }
}
</style>
