var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--price-with-promotions",class:{ 'has-discount': _vm.hasDiscount }},[(_vm.showHeader)?_vm._t("header",function(){return [_c('div',{staticClass:"wrapper--price-top"},[(_vm.hasDiscount)?[_c('div',{staticClass:"container-tags"},[(_vm.promoType === 'promo_dates')?_c('span',{staticClass:"discount-value discount-promo"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(`general.promo_type.${_vm.promoType}`, {
                  discount: _vm.promoDiscount,
                })
              )}})]):_c('span',{staticClass:"discount-value discount-promo"},[_c('span',[_vm._v(_vm._s(_vm.promoDiscount))])]),_vm._v(" "),_c('LadPriceMSI',{attrs:{"product":_vm.product}})],1),_vm._v(" "),_c('div',{staticClass:"container-price-before"},[_c('span',{staticClass:"price-from"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.from`)))]),_vm._v(" "),_c('span',{staticClass:"price--old"},[_vm._v(_vm._s(_vm.withoutDiscount))])]),_vm._v(" "),_c('div',{staticClass:"you-save"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.you_save`, {
              discount: _vm.discountWithFormat,
            }))+"\n        ")])]:[_c('LadPriceMSI',{attrs:{"product":_vm.product}}),_vm._v(" "),_c('div',{staticClass:"container-price-before"},[_c('span',{staticClass:"price-from"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.from`)))])])]],2)]}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"wrapper-sidebar--price-middle d--grid"},[_c('LadPriceAmount',{attrs:{"price":_vm.price,"discount-amount":_vm.discountAmount,"currency":_vm.currency}})],1),_vm._v(" "),(_vm.showFooter)?_vm._t("footer",function(){return [_c('div',{staticClass:"wrapper--price-bottom",class:{ 'per-person': _vm.showPerPerson || _vm.hasDateRestriction }},[(_vm.showPerPerson || _vm.hasDateRestriction)?_c('span',{staticClass:"price--unit"},[_vm._v(_vm._s(_vm.$t('general.promo_type.per_person')))]):_c('div',{staticClass:"discount-value"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(`general.promo_type.${_vm.promoType}`, { discount: _vm.promoDiscount })
          )}})])])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }