export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  experience: {
    uuid: null,
    is_lad: false,
    can_open_date: true,
    with_flight: false,
    name: null,
    location: null,
    headline: null,
    keywords: null,
    duration: 0,
    number_of_travelers: 0,
    brief_description: null,
    redirect_url: null,
    country: { slug: null, name: null, alpha_2_code: null, alpha_3_code: null },
    starting_point: null,
    ending_point: null,
    destinations: [],
    permalink: { default: null, es: null, en: null },
    zoho_form_lead: false,
    facebook_group_id: null,
    videos: {
      website: null,
      conversation: null,
    },
    type: null,
    main: { uuid: null, type: null, duration: 0 },
    availability: { dates: false, recommended: false },
    favorite: false,
  },
  cover: { srcset: null, title: null, url: null, uuid: null },
  gallery: [],
  breadcrumbs: [],
  map: {
    lat: null,
    lng: null,
    zoom: 15,
    description: null,
  },
  highlights: [],
  itinerary: [],
  extras: [],
  rooms: [],
  flights: [],
  insurances: [],
  promotions: [],
  local: {
    name: null,
    description: null,
    profile_picture: { src: null, srcset: null },
    // travelers: 0
  },
  platform: {
    uuid: null,
    link: null,
    logo: null,
    name: null,
    created_at: null,
    updated_at: null,
  },
  reviews: { count: 0, value: 0 },
  sales_plan: { layaway_amount: 0, total_amount: 0 },
  whats_included: [],
  for_me: [],
  faqs: [],
  categories: [],
  badges: [],
  seasons: [],
  story: { uuid: null },
  lead: {
    preventDoubleSubmit: false,
    sent: false,
  },
})

export const mutations = {
  SET_DEFAULT: (state, resetCover = true) => {
    state.experience = {
      uuid: null,
      is_lad: false,
      can_open_date: true,
      with_flight: false,
      name: null,
      location: null,
      headline: null,
      keywords: null,
      duration: 0,
      number_of_travelers: 0,
      brief_description: null,
      redirect_url: null,
      country: {
        slug: null,
        name: null,
        alpha_2_code: null,
        alpha_3_code: null,
      },
      starting_point: null,
      ending_point: null,
      destinations: [],
      permalink: { default: null, es: null, en: null },
      facebook_group_id: null,
      videos: {
        website: null,
        conversation: null,
      },
      type: null,
      main: { uuid: null, type: null, duration: 0 },
      availability: { dates: false, recommended: false },
      favorite: false,
    }
    state.gallery = []
    state.breadcrumbs = []
    if (resetCover) {
      state.cover = { srcset: null, title: null, url: null, uuid: null }
    }

    state.map = {
      lat: 0,
      lng: 0,
      zoom: 15,
      description: null,
    }

    state.highlights = []
    state.itinerary = []
    state.extras = []
    state.rooms = []
    state.flights = []
    state.insurances = []
    state.promotions = []
    state.local = {
      name: null,
      description: null,
      profile_picture: { src: null, srcset: null },
      // travelers: 0
    }
    state.platform = {
      uuid: null,
      link: null,
      logo: null,
      name: null,
      created_at: null,
      updated_at: null,
    }
    state.reviews = { count: 0, value: 0 }
    state.sales_plan = { layaway_amount: 0, total_amount: 0 }
    state.recommended_dates = []
    state.whats_included = []
    state.for_me = []
    state.faqs = []
    state.categories = []
    state.badges = []
    state.seasons = []
    state.story = { uuid: null }

    state.lead = { preventDoubleSubmit: false }
  },
  SET_DURATION: (state, data) => {
    /** las experiencias no LAD pueden tener diferente duracion, se pasa por url para el checkout */
    if (!state.experience.is_lad) {
      state.experience.duration = data
    }
  },
  SET_EXPERIENCE: (state, data) => {
    state.experience = data
  },
  SET_GALLERY: (state, data) => {
    if (!Array.isArray(data)) data = []

    data.forEach((item) => {
      /** solo para desarrollo ...cuando esta publicado en cdn debe funcionar sin problemas */
      if (item.url && !item.url.startsWith('http')) {
        item.url = `${process.env.MIX_API_BASE_URL}${item.url}`
      }
    })

    state.gallery = data
  },
  SET_HIGHLIGHTS: (state, data) => {
    state.highlights = data
  },
  SET_BREADCRUMBS: (state, data) => {
    state.breadcrumbs = data
  },
  SET_PLATFORM: (state, data) => {
    state.platform = data
  },
  SET_COVER: (state, data) => {
    state.cover = data
  },
  SET_EXTRAS: (state, data) => {
    if (!Array.isArray(data)) data = []

    data.forEach((d) => {
      if (!Reflect.has(d, 'qty')) d.qty = 1
      if (!Reflect.has(d, 'checked')) d.checked = false
    })

    state.extras = data
  },
  SET_ROOMS: (state, data) => {
    if (!Array.isArray(data)) data = []

    data.forEach((d) => {
      if (!Reflect.has(d, 'qty')) d.qty = 1
      if (!Reflect.has(d, 'unit')) d.unit = 1
      if (!Reflect.has(d, 'checked')) d.checked = false
    })

    state.rooms = data
  },
  SET_FLIGHTS: (state, data) => {
    if (!Array.isArray(data)) data = []

    data.forEach((d) => {
      if (!Reflect.has(d, 'qty')) d.qty = 1
      if (!Reflect.has(d, 'checked')) d.checked = false
    })

    state.flights = data
  },
  SET_INSURANCES: (state, data) => {
    if (!Array.isArray(data)) data = []

    data.forEach((d) => {
      if (!Reflect.has(d, 'qty')) d.qty = 1
      if (!Reflect.has(d, 'unit')) d.unit = 1
      if (!Reflect.has(d, 'checked')) d.checked = false
    })

    state.insurances = data
  },
  SET_PROMOTIONS: (state, data) => {
    data.forEach((d) => {
      if (!Reflect.has(d, 'amount')) d.amount = 0
    })

    state.promotions = data
  },
  SET_ITINERARY: (state, data) => {
    if (!Array.isArray(data)) data = []

    data.forEach((itinerary) => {
      /** solo para desarrollo ...cuando esta publicado en cdn debe funcionar sin problemas */
      itinerary.images.forEach((img) => {
        img = {
          url: img.url,
          srcset: img.srcset,
          uuid: img.uuid,
        }
      })
    })

    state.itinerary = data
  },
  SET_LOCAL: (state, data) => {
    data.profile_picture = {
      src: data.profile_picture.src,
      srcset: data.profile_picture.srcset,
    }

    state.local = data
  },
  SET_REVIEWS: (state, data) => {
    let value = '0.00'
    try {
      value = data.value.toFixed(2)
    } catch (e) {
      value = parseFloat(data.value).toFixed(2)
    }

    state.reviews = {
      count: parseInt(data.count),
      value: parseFloat(parseFloat(value).toFixed(1)),
    }
  },
  SET_SALES_PLAN: (state, data) => {
    state.sales_plan = {
      layaway_amount: parseFloat(data.layaway_amount),
      total_amount: parseFloat(data.total_amount),
    }
  },
  SET_MAP: (state, data) => {
    state.map = data
      ? {
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
          zoom: parseFloat(data.zoom),
          description: data.description,
        }
      : {
          lat: 0,
          lng: 0,
          zoom: 15,
          description: null,
        }
  },
  SET_WHATS_INCLUDED: (state, data) => {
    state.whats_included = data
  },
  SET_FOR_ME: (state, data) => {
    state.for_me = data
  },
  SET_FAQS: (state, data) => {
    state.faqs = data
  },
  SET_CATEGORIES: (state, data) => {
    state.categories = data
  },
  SET_BADGES: (state, data) => {
    state.badges = data
  },
  SET_SEASONS: (state, data) => {
    state.seasons = data
  },
  SET_STORY: (state, data) => {
    state.story = data
  },
  SET_LEAD: (state, data) => {
    state.lead = data
  },
  RESET_ITEMS: (state, type) => {
    state[type].forEach((d) => {
      d.qty = 1
      d.unit = 1
      d.checked = false
    })
  },
  UPDATE_ITEM: (state, { type, data }) => {
    const item = state[type].find((t) => t.uuid === data.uuid) || {
      is_new: true,
    }

    for (const [key, value] of Object.entries(data)) {
      if (key !== 'is_new') {
        item[key] = value
      }
    }

    if (!state[type].some((t) => t.uuid === data.uuid)) {
      state[type].push(item)
    } else if (!item.checked) {
      state[type] = state[type].filter((t) => t.uuid !== data.uuid)
    }
  },
  UPDATE_PROMOS_AMOUNT: (state, data) => {
    data.forEach((item) => {
      const promo = state.promotions.find((t) => t.uuid === item.uuid)

      if (promo) {
        promo.amount = item.amount
      }
    })
  },

  TOGGLE_FAVORITE: (state) => {
    state.experience.favorite = !state.experience.favorite
  },
}

export const getters = {}

export const actions = {
  setDefault({ commit }) {
    commit('SET_DEFAULT', false)
  },
  setDuration({ commit }, data) {
    commit('SET_DURATION', data)
  },
  resetItems({ commit }) {
    const types = ['extras', 'insurances', 'rooms', 'flights']
    types.forEach((type) => commit('RESET_ITEMS', type))
  },
  updateExtra({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'extras', data })
  },
  updateInsurance({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'insurances', data })
  },
  updateRoom({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'rooms', data })
  },
  updateFlight({ commit }, data) {
    commit('UPDATE_ITEM', { type: 'flights', data })
  },

  updatePromosAmount({ commit }, data) {
    commit('UPDATE_PROMOS_AMOUNT', data)
  },
  setExtras({ commit }, data) {
    commit('SET_EXTRAS', data)
  },
  setRooms({ commit }, data) {
    commit('SET_ROOMS', data)
  },
  setInsurances({ commit }, data) {
    commit('SET_INSURANCES', data)
  },
  setFlights({ commit }, data) {
    commit('SET_FLIGHTS', data)
  },
  setMap({ commit }, data) {
    commit('SET_MAP', data)
  },
  setCover({ commit }, data) {
    commit('SET_COVER', data)
  },
  setLead({ commit }, data) {
    commit('SET_LEAD', data)
  },
  async getData(
    { commit },
    { permalink, draft = false, settings = {}, overwrite = {} }
  ) {
    commit('SET_STORY', { uuid: null })
    let response = 'error'

    try {
      /** en SSR cuando la url no esta bien codificada lanza un error */
      permalink = process.server ? encodeURI(permalink) : permalink
      let url = `/api/v1.5/experiences/show/${permalink}`
      if (draft) {
        url += `/order`
      }

      /** obteniendo datos de la experiencia */
      // eslint-disable-next-line
      let { data, status } = await this.$axios.get(
        url + '?headers=' + JSON.stringify(settings)
      )

      if (status === 'success') {
        Object.keys(overwrite).forEach((k) => {
          if (typeof overwrite[k] === 'object') {
            Object.keys(overwrite[k]).forEach(
              (t) => (data[k][t] = overwrite[k][t])
            )
          }
        })

        commit('SET_EXPERIENCE', data.product)
        commit('SET_GALLERY', data.gallery)
        commit('SET_HIGHLIGHTS', data.highlights)
        commit('SET_BREADCRUMBS', data.breadcrumbs || [])
        commit('SET_PLATFORM', data.platform)
        commit('SET_ITINERARY', data.itinerary)
        commit('SET_LOCAL', data.local)
        commit('SET_REVIEWS', data.reviews)
        commit('SET_SALES_PLAN', data.sales_plan)
        commit('SET_MAP', data.map)

        commit('SET_EXTRAS', data.extras)
        commit('SET_ROOMS', data.rooms)
        commit('SET_FLIGHTS', data.flights)
        commit('SET_INSURANCES', data.insurances)

        commit('SET_PROMOTIONS', data.promotions)
        commit('SET_COVER', data.cover)
        commit('SET_WHATS_INCLUDED', data.whats_included)
        commit('SET_FOR_ME', data.for_me)
        commit('SET_FAQS', data.faqs)
        commit('SET_CATEGORIES', data.categories || [])
        commit('SET_BADGES', data.badges || [])
        commit('SET_SEASONS', data.seasons || [])

        response = 'success'
      }
    } catch (error) {
      response = error.response
    }

    return response
  },
  setStory({ commit }, data) {
    commit('SET_STORY', data)
  },
  toggleFavorite({ commit }) {
    commit('TOGGLE_FAVORITE')
  },
}
