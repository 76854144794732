export default async ({ isHMR, app, store }) => {
  // if (isHMR || process.static || process.client) return

  const isAuth = await store.state.auth.loggedIn
  if (isAuth) {
    const settings = {
      locale:
        store.state.auth.user.settings.locale || store.state.settings.locale,
      currency:
        store.state.auth.user.settings.currency ||
        store.state.settings.currency,
      _fallback: true,
    }

    await store.dispatch('settings/init', settings)

    /** https://medium.com/@helena.wuv/how-to-internationalize-your-next-nuxt-project-using-vue-i18n-d9c51e28a564 */
    app.i18n.locale = store.state.settings.locale
  }
}
