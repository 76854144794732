export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  _queue: [],
  auth: { opened: false, data: null },
  trips: { opened: false, data: null },
  availability: { opened: false, data: null },
  findMyTour: { opened: false, data: null },
  shoppingCartWithChanges: { opened: false, data: null },
  askQuestion: { opened: false, data: null },
})

export const mutations = {
  SET_DEFAULT: (state) => {
    state._queue = []
    state.auth = { opened: false, data: null }
    state.trips = { opened: false, data: null }
    state.availability = { opened: false, data: null }
    state.findMyTour = { opened: false, data: null }
    state.shoppingCartWithChanges = { opened: false, data: null }
    state.askQuestion = { opened: false, data: null }
  },
  TOGGLE_MODAL: (state, data) => {
    if (typeof data === 'string') {
      /** reseteo los valores que pudieron haber estado en el modal abierto */
      const current = Object.keys(state)
        .filter((t) => Reflect.has(state[t], 'opened'))
        .find((t) => state[t].opened)
      if (current) state[current].data = null

      state[data].opened = !state[data].opened

      if (!state[data].opened && state._queue.length > 0) {
        const modal = state._queue.pop()

        // setTimeout(_ => state[modal].opened = !state[modal].opened, 300)
        state[modal].opened = !state[modal].opened
      }
    } else {
      if (Reflect.has(data, 'queue')) {
        state[data.queue].opened = !state[data.queue].opened
        state._queue.push(data.queue)
      }

      state[data.open].data = Reflect.has(data, 'data') ? data.data : null

      // setTimeout(_ => state[data.open].opened = !state[data.open].opened, 300)
      state[data.open].opened = !state[data.open].opened
    }
  },
}

export const getters = {}

export const actions = {
  setDefault({ commit }) {
    commit('SET_DEFAULT')
  },
  toggle({ commit }, modal) {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_MODAL', modal)
      resolve()
    })
  },
  /** obtiene cualquier dato almacenado del modal abierto */
  getData() {
    let data = {}

    Object.keys(this.state.modals).forEach((k) => {
      if (
        Reflect.has(this.state.modals[k], 'opened') &&
        this.state.modals[k].opened
      ) {
        data = this.state.modals[k].data
      }
    })

    return data
  },
}
