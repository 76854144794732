import Bowser from 'bowser'

export function getUserAgentData(req) {
  /** https://stackoverflow.com/questions/31569518/how-to-detect-facebook-in-app-browser */
  const ua = process.server
    ? req.headers['user-agent'] || ''
    : navigator.userAgent || navigator.vendor || navigator.opera || ''

  if (!ua) {
    return {
      device: null,
      os: null,
      isSafari: false,
      isBot: true,
      isFacebookApp: false,
      oldBrowsers: false,
    }
  }

  const isFacebookApp = ua.includes('FBAN') || ua.includes('FBAV')
  const browser = Bowser.getParser(ua)

  // let isInstagramApp = ua.indexOf('Instagram') > -1
  const isValidBrowser = browser.satisfies({
    // declare browsers per OS
    windows: {
      'internet explorer': '>11',
      edge: '>15',
    },
    macos: {
      safari: '>10.1',
    },

    // per platform (mobile, desktop or tablet)
    mobile: {
      safari: '>10.2',
      'android browser': '>4.4',
    },

    firefox: '>52',
    chrome: '>50',
    opera: '>=22',
    edge: '>=79',
  })
  const result = browser.parsedResult

  return {
    device: result.platform.type,
    os: (result.os.name || '').toLowerCase().replace(/\s+/g, '-'),
    isSafari: /^((?!chrome|android).)*safari/i.test(ua),
    isBot: /bot|googlebot|crawler|spider|robot|crawling|headlesschrome/i.test(
      ua
    ),
    isFacebookApp,
    oldBrowsers: !isValidBrowser,
  }
}
