<script>
import LadPricePromoMixin from '@/components/frontends/Price/components/mixin/promo'
import LadAbstractPriceInline from '@/components/frontends/Price/components/abstract/inline'

export default {
  name: 'LadPriceInline',
  extends: LadAbstractPriceInline,
  mixins: [LadPricePromoMixin],
  props: {
    price: { type: Number, required: true },
    currency: { type: String, required: true },
    promotions: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/no-boolean-default
    showZeroPrice: { type: Boolean, default: true },
  },
}
</script>
