export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  device: 'mobile',
  os: null,
  isSafari: false,
  isBot: false,
  isFacebookApp: false,
  oldBrowsers: false,
})

export const mutations = {
  SET_INIT: (state, data) => {
    /** solo se actualiza info diferente */
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state, key) && state[key] !== value) {
        state[key] = value
      }
    }
  },
  TOGGLE_DEVICE: (state, device) => {
    /** solo se actualiza info diferente */
    if (state.device !== device) {
      state.device = device
    }
  },
}

export const getters = {
  isMobile(state) {
    return state.device === 'mobile'
  },
  isIOS(state) {
    return state.os === 'ios' || state.isSafari
  },
}

export const actions = {
  init({ commit }, device) {
    commit('SET_INIT', device)
  },
  toggle({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
}
