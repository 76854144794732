<template>
  <div class="wrapper-lazy-loading">
    <template v-if="enableClientOnly">
      <client-only>
        <slot v-if="loaded" />
        <slot v-if="loading && mounted" name="css-skeleton" />
      </client-only>
    </template>
    <template v-else>
      <slot v-if="loaded" />
      <slot v-if="loading" name="css-skeleton" />
    </template>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import LadComponentNameMixin from '@/utils/mixin/custom-component-name.js'

export default {
  name: 'LazyLoading',
  mixins: [LadComponentNameMixin],
  props: {
    loaded: { type: Boolean, required: true, default: false },
    // eslint-disable-next-line vue/no-boolean-default
    loading: { type: Boolean, default: true },
    // eslint-disable-next-line vue/no-boolean-default
    enableClientOnly: { type: Boolean, default: true },
  },
  data() {
    return {
      mounted: false,
    }
  },
  beforeMount() {
    this.__handleScroll = debounce(this.handleScroll, 100)

    window.addEventListener('scroll', this.__handleScroll)
  },
  mounted() {
    const _this = this

    try {
      /** no quitar ...para correcta inicializacion de lazy-loading */
      window.dispatchEvent(new Event('scroll'))

      /** no quitar ...para correcta inicializacion de los .affix */
      window.dispatchEvent(new Event('resize'))

      _this.mounted = true

      if (!(`IntersectionObserver` in window)) {
        // Immediately load the component if
        // `IntersectionObserver` is not available.
        _this.LadNextTick(
          () => _this.$emit('loaded', true),
          `LazyLoading 1 - ${_this.getComponentName()}`
        )
        return
      }

      /**
       * se utiliza este timeout debido a que cuando se hace navegacion
       * hacia otras paginas no funciona correctamente el observer
       */
      const timer = setTimeout(() => {
        clearTimeout(timer)

        const observer = new IntersectionObserver((entries) => {
          // Use `intersectionRatio` because of Edge 15's
          // lack of support for `isIntersecting`.
          // See: https://github.com/w3c/IntersectionObserver/issues/211
          if (entries[0].intersectionRatio <= 0) return

          if (_this.$el) {
            observer.unobserve(_this.$el)
          }

          _this.LadNextTick(
            () => _this.$emit('loaded', true),
            `LazyLoading 2 - ${_this.getComponentName()}`
          )
          window.removeEventListener('scroll', _this.__handleScroll)
        })

        if (_this.$el) {
          observer.observe(_this.$el)
        }
      }, 250)
    } catch (error) {
      _this.LadNextTick(
        () => _this.$emit('loaded', true),
        `LazyLoading with error - ${_this.getComponentName()}`
      )
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.__handleScroll)
  },
  methods: {
    handleScroll() {
      if (this.isInViewport() && !this.loaded) {
        this.LadNextTick(
          () => this.$emit('loaded', true),
          `LazyLoading 3 - ${this.getComponentName()}`
        )
        window.removeEventListener('scroll', this.__handleScroll)
      }
    },
    getElementOffset(el) {
      let top = -1
      let left = -1
      let right = -1

      if (typeof el.getBoundingClientRect !== 'undefined') {
        const distance = el.getBoundingClientRect()

        top = distance.top
        left = distance.left
        right = distance.right
      }

      return { top, left, right }
    },
    isInViewport() {
      const element = this.getElementOffset(this.$el)

      return (
        element.top === -1 ||
        (element.top >= 0 &&
          element.left >= 0 &&
          element.top <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          element.right <=
            (window.innerWidth || document.documentElement.clientWidth))
      )
    },
  },
}
</script>
