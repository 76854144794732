import Bowser from 'bowser'
import { debounce } from '@/utils'

export default {
  watch: {
    $route(route) {
      if (this.device === 'mobile' && this.isSidebarOpen) {
        this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
      }
    },
  },
  methods: {
    detectIsMobile() {
      const ua =
        window.navigator.userAgent ||
        window.navigator.vendor ||
        window.navigator.opera
      const browser = Bowser.getParser(ua)

      let isMobile = browser.parsePlatform().type === 'mobile'

      if (!isMobile) {
        const { body } = document
        const rect = body.getBoundingClientRect()
        const WIDTH = 1024
        const RATIO = 3

        isMobile = rect.width - RATIO < WIDTH
      }

      return isMobile
    },
    async handleResize() {
      if (process.client && !document.hidden) {
        const isMobile = this.detectIsMobile()
        await this.$store.dispatch(
          'device/toggle',
          isMobile ? 'mobile' : 'desktop'
        )

        /** por algun motivo el open sidebar genera un resize y se lanza esta parte, dejarla comentada de momento */
        // if (isMobile) {
        //   await this.$store.dispatch('app/closeSideBar', {
        //     withoutAnimation: true,
        //   })
        // }
      }
    },
  },
  beforeMount() {
    if (process.client) {
      this.__handleResize = debounce(this.handleResize, 100)

      window.addEventListener('resize', this.__handleResize)
    }
  },
  async mounted() {
    await this.$store.dispatch('app/closeSideBar', { withoutAnimation: true })
    // this.LadNextTick(this.__handleResize, 'ResizeHandler')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.__handleResize)
  },
}
