import Vue from 'vue'
import VueI18n from 'vue-i18n'
import SPANISH_COUNTRIES from '@/utils/spanish-countries'
import CRAWLER_USER_AGENT from '@/utils/crawler-user-agents'

/** fix */
// import VueI18nMemoryLeakFix from './fix'

export default async ({ app, store, req, $axios }) => {
  Vue.use(VueI18n)

  const appLocale = process.env.APP_LOCALE

  /** para que SSR salga en el idioma que esta haciendo el request el usuario */
  if (process.server) {
    const ua = req.headers['user-agent'] || ''
    const isBufferAgent = !!req.headers['x-bufferbot']
    const isGetMethod = req.method === 'GET'

    /** no se puede pasar para el middleware `user-agent` xq esto se ejecuta antes */
    const isCrawlerUserAgents =
      isBufferAgent ||
      (isGetMethod &&
        CRAWLER_USER_AGENT.some((userAgent) =>
          ua.toLowerCase().includes(userAgent.toLowerCase())
        ))

    const parseCookies = (request) => {
      const list = {}
      const rc = request?.headers?.cookie || null

      rc &&
        rc.split(';').forEach((cookie) => {
          const parts = cookie.split('=')
          list[parts.shift().trim()] = decodeURI(parts.join('='))
        })

      return list
    }

    /** obtengo idioma segun pais */
    const getLanguage = (country) => {
      let language = 'es_MX'

      switch (country.toUpperCase()) {
        case 'CO':
          language = 'es_CO'
          break
        case 'US':
          language = 'en_US'
          break
        default:
          language = SPANISH_COUNTRIES.includes(country) ? 'es_MX' : 'en_US'
          break
      }

      return language
    }

    /** obtengo currency segun pais */
    const getCurrency = (country) => {
      let currency = 'USD'

      switch (country.toUpperCase()) {
        case 'MX':
          currency = 'MXN'
          break
        case 'CO':
          currency = 'COP'
          break
      }

      return currency
    }

    /** solo cuando tengo el token del usuario almacenado es que puedo obtener estos datos */
    const cookies = parseCookies(req)

    const strategy = cookies['auth.strategy'] || 'custom-login'

    const appCurrency = process.env.APP_CURRENCY
    const userCountry =
      req.headers['CF-IPCountry'] ||
      req.headers['cf-ipcountry'] ||
      req.headers['Cf-Ipcountry'] ||
      undefined

    const settings = { locale: appLocale, currency: appCurrency }

    if (cookies) {
      let isValidToken = false
      try {
        /** intento autenticarme con el token de la cookie para obtener datos actualizados del profile */
        if (
          typeof cookies[`auth._token.${strategy}`] !== 'undefined' &&
          !['false', '', 'undefined'].includes(
            cookies[`auth._token.${strategy}`]
          )
        ) {
          $axios.setBaseURL(process.env.LAD_PRIVATE_API_BASE_URL)

          const {
            data: { data, status },
          } = await $axios.get('/api/me', {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: cookies[`auth._token.${strategy}`],
              'X-localization': cookies.language
                ? cookies.language
                : settings.locale,
              'X-currency': cookies.currency
                ? cookies.currency
                : settings.currency,
            },
          })

          if (status === 'success') {
            settings.locale = data.settings.locale
            settings.currency = data.settings.currency

            isValidToken = true
          }
        }
      } catch (error) {}

      if (!isValidToken) {
        /** hubo un error con el token de auth o no existia esa cookie */
        const currency = cookies.currency
        const locale = cookies.language

        if (isCrawlerUserAgents) {
          /** es un bot */
        } else if (
          typeof userCountry !== 'undefined' &&
          userCountry !== null &&
          (!locale || !currency)
        ) {
          /** no hay cookies y si header de country */
          settings.locale = locale || getLanguage(userCountry)
          settings.currency = currency || getCurrency(userCountry)
        } else {
          if (locale) settings.locale = locale
          if (currency) settings.currency = currency
        }
      }
    } else if (typeof userCountry !== 'undefined' && userCountry !== null) {
      /** no existe cookies, se procede a obtener segun datos de CloudFlare */
      settings.locale = getLanguage(userCountry)
      settings.currency = getCurrency(userCountry)
    }

    await store.dispatch('settings/init', settings)
  }

  const locale = store.state.settings.locale || appLocale

  const messages = {}
  messages[locale] = await import(`@/locales/${locale}.json`)

  if (locale !== appLocale) {
    messages[appLocale] = await import(`@/locales/${appLocale}.json`)
  }

  // Set i18n instance on app
  app.i18n = new VueI18n({ locale, fallbackLocale: appLocale, messages })

  await store.dispatch('settings/setI18nKeys', Object.keys(messages))
}
