export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  wrapperClassName: 'wrapper--app',
  othersClassName: [],
  sidebar: {
    opened: false,
    withoutAnimation: false,
  },
  bottomNavbar: {
    opened: false,
    showPromptPwa: false,
  },
  breadcrumb: [],
  country: process.env.APP_COUNTRY,
  scriptLoaded: {
    isMapboxLoaded: false,
    atratoPago: false,
    zenkiPay: false,
  },
  pwa: {
    isOnline: true,
    isEnabled: false,
    installManually: false,
    showPromptInstall: false,
    source: 'default',
    deferredPrompt: null,
  },
  permission: {
    notification: {
      isEnabled: false,
    },
    geolocation: {
      isEnabled: false,
    },
  },
})

export const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
  },
  UPDATE_WRAPPER_CLASSNAME: (state, data) => {
    state.wrapperClassName = data || 'wrapper--app'
  },
  TOGGLE_OTHERS_CLASSNAME: (state, { action, list }) => {
    if (action === 'add') {
      list
        .filter((t) => !state.othersClassName.some((h) => h === t))
        .forEach((t) => state.othersClassName.push(t))
    } else {
      state.othersClassName = state.othersClassName.filter(
        (t) => !list.some((h) => h === t)
      )
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_BOTTOMNAVBAR: (state, data) => {
    state.bottomNavbar.opened = data
  },
  SHOW_BOTTOMNAVBAR_PWA: (state, data) => {
    state.bottomNavbar.showPromptPwa = data
  },
  SET_BREADCRUMB: (state, data) => {
    state.breadcrumb = data
  },
  UPDATE_SCRIPT_LOADED: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.scriptLoaded, key)) {
        state.scriptLoaded[key] = value
      }
    }
  },
  SET_PROMPT_INSTALL: (state, data) => {
    /** solo hago un cambio cuando hay un nuevo estado */
    if (state.pwa.showPromptInstall !== data.showPromptInstall) {
      for (const [key, value] of Object.entries(data)) {
        if (Reflect.has(state.pwa, key)) {
          state.pwa[key] = value
        }
      }
    }
  },
  SET_INSTALL_MANUALLY: (state, data) => {
    state.pwa.installManually = data
  },
  SET_DEFERRED_PROMPT: (state, data) => {
    state.pwa.deferredPrompt = data
  },
  SET_PWA_ENABLED: (state, data) => {
    state.pwa.isEnabled = data
  },
  SET_PWA_ONLINE: (state, data) => {
    state.pwa.isOnline = data
  },
}

export const getters = {}

export const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  updateWrapper({ commit }, data) {
    commit('UPDATE_WRAPPER_CLASSNAME', data)
  },
  toggleOthersClassName({ commit }, data) {
    commit('TOGGLE_OTHERS_CLASSNAME', data)
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  toggleBottomNavbar({ commit }, data) {
    commit('TOGGLE_BOTTOMNAVBAR', data)
  },
  showBottomNavbarPwa({ commit }, data) {
    commit('SHOW_BOTTOMNAVBAR_PWA', data)
  },
  setBreadcrumb({ commit }, data) {
    commit('SET_BREADCRUMB', data)
  },
  updateScriptLoaded({ commit }, data) {
    commit('UPDATE_SCRIPT_LOADED', data)
  },
  setPromptInstall({ commit }, data) {
    commit('SET_PROMPT_INSTALL', data)
  },
  setInstallManually({ commit }, data) {
    commit('SET_INSTALL_MANUALLY', data)
  },
  setDeferredPrompt({ commit }, data) {
    commit('SET_DEFERRED_PROMPT', data)
  },
  setPwaEnabled({ commit }, data) {
    commit('SET_PWA_ENABLED', data)
  },
  setPwaisOnline({ commit }, data) {
    commit('SET_PWA_ONLINE', data)
  },
}
