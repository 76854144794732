import common from './common'

export default {
  extends: common,
  props: {
    /** revisar components/frontends/Price/index.vue */
    parentData: {
      type: Object,
      default: () => ({ date: null, season: null, language: null }),
    },
  },
  computed: {
    /** define si la promo tiene reglas de validacion */
    hasPromoWithRules() {
      return this.promotion.rules.length > 0
      // return this.promotions.reduce((prev, promo) => prev + promo.rules.length, 0) > 0
    },
    /** define si es una promocion general */
    hasDiscount() {
      return (
        this.promotion.value > 0 &&
        ['promo_general', 'promo_dates'].includes(this.promoType) &&
        this.showDiscountOrPromo
      )
      // return this.promotions.reduce((prev, promo) => prev + promo.value, 0) > 0
    },
    showDiscountOrPromo() {
      const parentWithValidData = Object.keys(this.parentData).some(
        (k) => this.parentData[k] !== null
      )

      if (!this.hasPromoWithRules) {
        return true
      } else if (parentWithValidData) {
        return this.hasValidDiscountForParent
      } else {
        return true
      }
    },
    hasValidDiscountForParent() {
      return this.validateDate(this.parentData)
    },
    discountAmount() {
      let value = 0

      if (this.hasDiscount) {
        if (this.promotion.discount_type === 'promotion_percent') {
          value += (parseFloat(this.price) * this.promotion.value) / 100
        } else {
          /** descuento por monto */
        }
      }

      if (!this.showDiscountOrPromo) value = 0

      return value

      // return this.promotions.reduce((prev, promo) => {

      //     if (promo.discount_type === 'promotion_percent') {
      //         prev += (parseFloat(this.price) * promo.value) / 100
      //     } else {
      //         /** descuento por monto */
      //     }

      //     return prev
      // }, 0)
    },
    discountWithFormat() {
      return this.$options.filters.ladFnCurrency(
        this.discountAmount,
        this.currency,
        true,
        ''
      )
    },
    /** label del descuento en % */
    promoDiscount() {
      return this.promotion.discount_type === 'promotion_percent'
        ? `-${this.promotion.value}%`
        : ''
    },
    /** precio real sin descuento */
    withoutDiscount() {
      return this.$options.filters
        .ladFnCurrency(this.price, this.currency, false, '')
        .format(true)
    },
  },
}
