import load from '@/utils/load-promise'

export default (ctx, inject) => {
  const version = 'v2'

  const stripe = {
    instance: null,
    loaded: false,
    init: () => {
      stripe.loaded = true
      let limit = 30

      const interval = setInterval(() => {
        if (window.Stripe) {
          clearInterval(interval)

          if (version === 'v2') {
            window.Stripe.setPublishableKey('pk_live_1N9birN2XxzQ7EbJD6Fcq78f')
            stripe.instance = window.Stripe
          } else {
            stripe.instance = window.Stripe('pk_live_1N9birN2XxzQ7EbJD6Fcq78f')
          }
        } else {
          limit--
        }

        if (limit === 0) {
          clearInterval(interval)
        }
      }, 200)
    },
    load: () => {
      if (!stripe.loaded) {
        load
          .js(`https://js.stripe.com/${version}/`, 'stripe-src', 'defer')
          .then(() => stripe.init())
          .catch(() => {})
      }
    },
  }

  inject('stripe', stripe)
}
