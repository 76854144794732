/** https://nuxtjs.org/api/configuration-router/#scrollbehavior */
export default function (to, from, savedPosition) {
  const data = { y: 0 }

  /** @todo refactor here! */
  // if (
  //   to &&
  //   Reflect.has(to, 'name') &&
  //   to.name &&
  //   to.name.toLowerCase() === 'profiledetail'
  // ) {
  //   data = false
  // }

  return data
}
