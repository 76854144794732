import load from '@/utils/load-promise'

const fbsdkPlugin = (ctx, inject) => {
  const appId = '315719405879512'
  const cookie = 'true'
  const xfbml = '1'
  const locale = 'es_LA'
  const version = 'v5.0'

  const sdk = {
    init: () => {
      window.FB.init({
        appId,
        cookie: cookie === 'true',
        xfbml: xfbml === '1',
        locale,
        version,
      })
    },
    load: () => {
      const src = `https://connect.facebook.net/${locale}/sdk.js#xfbml=${xfbml}&version=${version}&appId=${appId}`

      if (window.FB) {
        /** @todo verificar como eliminar el warning de inicializado en la navegacion con SPA */
        sdk.init()
      } else {
        load.js(src, 'facebook-jssdk').catch(() => {})
      }
    },
  }

  /** inicializando FB */
  // window.fbAsyncInit = () => sdk.init()

  inject('fbsdk', sdk)
}

export default fbsdkPlugin
