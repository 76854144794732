// https://davidwalsh.name/javascript-loader
const load = (() => {
  // Function which returns a function: https://davidwalsh.name/javascript-functions
  const _load = (tag) => {
    return (url, id, jstype = 'defer') => {
      // This promise will be used by Promise.all to determine success or failure
      return new Promise(function (resolve, reject) {
        const exist = document.getElementById(id)
        if (exist) {
          exist.parentNode.removeChild(exist)
        }

        const element = document.createElement(tag)
        let parent = 'body'
        let attr = 'src'

        // Important success and error for the promise
        element.onload = () => resolve(url)
        element.onerror = () => reject(url)

        // Need to set different attributes depending on tag type
        switch (tag) {
          case 'script':
            if (jstype === 'defer') element.defer = true
            if (jstype === 'async') element.async = true
            break
          case 'link':
            element.type = 'text/css'
            element.rel = 'stylesheet'
            attr = 'href'
            parent = 'head'
        }

        element.id = id

        // Inject into document to kick off loading
        element[attr] = url
        document[parent].appendChild(element)
      })
    }
  }

  return {
    css: _load('link'),
    js: _load('script'),
    img: _load('img'),
  }
})()

export default load
