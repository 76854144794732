<template>
  <div>
    <Nuxt />
  </div>
</template>

<script>
export default {
  name: 'LayoutError',
}
</script>
