<template>
  <div
    v-if="price > 0"
    class="wrapper--price-with-promotions price-inline"
    :class="{ 'has-discount': hasDiscount }"
  >
    <p>
      <strong>{{ amount }}</strong>

      <span v-if="hasDiscount" class="price--old">{{ withoutDiscount }}</span>

      <span v-if="showPerPerson" class="per-person">{{
        $t('general.promo_type.per_person')
      }}</span>

      <span v-if="hasDiscount" class="discount--amount"
        >{{
          $t(`experience.sidebars.main.you_save`, {
            discount: discountWithFormat,
          })
        }}
      </span>

      <span class="container-tags">
        <span v-if="showPercent || !showPerPerson" class="discount-value">
          <span v-if="promoType === 'promo_general'">{{ promoDiscount }}</span>
          <span
            v-else
            v-html="
              $t(`general.promo_type.${promoType}`, { discount: promoDiscount })
            "
          ></span>
        </span>

        <LadPriceMSI :product="product" />
      </span>
    </p>
  </div>
</template>

<script>
import LadPriceMSI from '../msi'

export default {
  name: 'LadAbstractPriceInline',
  components: { LadPriceMSI },
  props: {
    product: { type: Object, default: () => ({ is_lad: false }) },
  },
  computed: {
    amountCurrency() {
      return this.$options.filters
        .ladFnCurrency(this.price, this.currency, false)
        .subtract(this.discountAmount)
    },
    amount() {
      return this.showZeroPrice && this.amountCurrency.dollars() === 0
        ? '-'
        : this.amountCurrency.format(true)
    },
    showPerPerson() {
      return !(
        this.promoType.startsWith('promo_2x1') ||
        this.promoType.startsWith('promo_3x1')
      )
    },
    hasDateRestriction() {
      return this.promoType.endsWith('_dates') && !this.showDiscountOrPromo
    },
    showPercent() {
      return Math.abs(parseFloat(this.promoDiscount)) > 0
    },
  },
}
</script>

<style lang="scss">
.wrapper--price-with-promotions {
  &.price-inline {
    .price--old {
      text-decoration: none;
      font-style: inherit;
      font-size: 0.7em;
      display: inline-block;
    }

    .discount--amount {
      font-size: 0.8em;
      display: block;
    }

    .per-person {
      margin-left: 2px;
      font-size: 0.7em;
      display: block;
    }

    .discount-value {
      padding: 0 6px;
      font-size: 0.8em;
    }

    p {
      margin-bottom: 0;
    }

    small {
      font-size: 100%;
    }
  }
}
</style>
