<template>
  <span v-if="showMSI" class="wrapper-msi-promotion">
    <span v-for="month in monthsMSI" :key="month" class="discount-value">
      <span
        >{{
          $t(`general.promo_type.msi`, {
            month,
          })
        }}
      </span>
    </span>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LadPriceMSI',
  props: {
    product: { type: Object, default: () => ({ is_lad: false }) },
  },
  computed: {
    ...mapState('settings', ['currency']),
    /** meses sin intereses */
    showMSI() {
      const range = process.env.EBANX_MSI_DATE_RANGE.split(',')
      const start = this.$dayjs(range[0]).startOf('day')
      const end = this.$dayjs(range[1]).endOf('day')
      const currencies = process.env.EBANX_MSI_CURRENCY.split(',')

      const inRange = this.$dayjs().isBetween(start, end, null, '[]')

      /** revisar `components/payment-methods/components/card.vue` en caso de cambios con currency */
      return inRange && currencies.includes(this.currency)
    },
    /** lista meses sin intereses */
    monthsMSI() {
      const list = this.product.is_lad
        ? process.env.EBANX_MSI_AVAILABLE_MONTHS_LAD
        : process.env.EBANX_MSI_AVAILABLE_MONTHS_OTHERS

      return list.split(',')
    },
  },
}
</script>

<style lang="scss">
.wrapper-msi-promotion {
  .discount-value {
    & + .discount-value {
      margin-left: 4px;
    }
  }
}
</style>
