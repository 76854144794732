import Cookies from 'js-cookie'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('experience', {
      exExperience: (state) => state.experience,
      totalAmount: (state) => state.sales_plan.total_amount,
      promotions: 'promotions',
      sent: (state) => state.lead.sent,
    }),
    ...mapState('settings', ['currency']),
  },
  methods: {
    async sendAnalytics(data) {
      if (process.browser && typeof this.$gtag !== 'undefined') {
        if (this.exExperience.uuid) {
          this.$gtag('event', 'generate_lead', {
            event_label: this.exExperience.name,
            currency: this.currency,
            page_title: this.$metaInfo.title,
            page_location: window.location.href,
          })

          this.$gtag('event', 'generate_lead_itinerary', {
            event_label: this.exExperience.name,
            currency: this.currency,
            page_title: this.$metaInfo.title,
            page_location: window.location.href,
          })
        }

        this.$gtag('event', 'conversion', {
          send_to: `${process.env.GA_AW_CONVERSION_ID}/QZ5wCJvM0YAYEPis6vso`,
          value: 1.0,
          currency: this.currency,
        })

        this.$gtag('event', 'conversion', {
          send_to: `${process.env.GA_AW_CONVERSION_ID}/CW21CNfF0YMYEPis6vso`,
          value: 1.0,
          currency: this.currency,
        })

        // eslint-disable-next-line
        let params = {
          ...(this.exExperience.uuid && {
            content_type: 'destination',
            content_ids: [this.exExperience.uuid],
          }),
          travel_start: null /** solo aplica cuando hay filtro de rango de fecha */,
          travel_end: null /** solo aplica cuando hay filtro de rango de fecha */,
          // city: this.exExperience
          //   .starting_point /** solo aplica cuando se busca por ciudad */,
          region: null /** region or state => no aplica de momento */,
          // country: this.exExperience.country
          //   .name /** se obtiene del primer item */,
          // num_adults: 1,
          num_children: null /** if applicable> */,
          content_name: this.exExperience.name,
          currency: this.currency,
          event_id: `${this.deviceId}-${Date.now()}`,
        }

        const conversion = {
          type: 'lead',
          source_url: window.location.href,
          cookies: {
            _fbp: Cookies.get('_fbp') || '',
            _fbc: Cookies.get('_fbc') || '',
          },
          params: this.$route.query || {},
        }

        try {
          Object.keys(params)
            .filter((t) => !params[t])
            .map((t) => delete params[t])
        } catch (error) {}

        // eslint-disable-next-line
        let fbqData = {
          em: null,
          fn: null,
          ln: null,
          ph: null,
          external_id: null,
        }

        for (const [key, value] of Object.entries(data)) {
          switch (key) {
            case 'name':
              fbqData.fn = value
              break
            case 'email':
              fbqData.em = value
              break
            case 'phone':
              fbqData.ph = value
              break
            default:
              break
          }
        }

        this.$fb.setData(fbqData)

        Object.keys(fbqData)
          .filter((t) => !fbqData[t])
          .map((t) => delete fbqData[t])

        conversion.user_data = fbqData

        if (typeof this.$fb !== 'undefined') {
          this.$fb.track('Lead', params)
        }

        await this.$axios.post(
          `/api/conversion/${this.isAuth ? 'user' : 'guest'}`,
          { ...params, ...conversion }
        )
      }
    },
  },
  mounted() {
    const onSuccess = (event) => {
      this.sendAnalytics(event.detail.Optin.data.fields)
    }

    document.addEventListener('om.Optin.success', onSuccess)

    document.addEventListener('om.DisplayRules.afterRun', (event) => {
      const DisplayRules = event.detail.DisplayRules
      if (this.sent) DisplayRules.show = false
    })
  },
}
