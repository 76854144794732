import { getUserAgentData } from '@/utils/user-agent'

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({})

export const mutations = {}

export const getters = {
  currentUserAuth(state) {
    return state.auth.user
  },
}

export const actions = {
  /**
   * This is run ONLY from the backend side.
   *
   * > If the action nuxtServerInit is defined in the store, Nuxt.js will call it with the context
   * > (only from the server-side).
   * > It's useful when we have some data on the server we want to give directly to the client-side.
   *
   * https://nuxtjs.org/guide/vuex-store#the-nuxtserverinit-action
   * https://github.com/clarkdo/hare/blob/dev/client/store/index.js
   * https://github.com/nuxt/docs/blob/master/en/guide/vuex-store.md
   *
   * https://stackoverflow.com/questions/59292838/nuxt-differvence-nuxtserverinit-vs-mddleware-vs-plugin
   * https://github.com/nuxt/nuxt.js/issues/2660
   * https://github.com/nuxt/nuxt.js/issues/2521
   */
  async nuxtServerInit({ commit }, { req, store }) {
    const data = getUserAgentData(req)
    await store.dispatch('device/init', data)

    await store.dispatch('settings/hydrate')
    await store.dispatch('advertisement/get')

    /** inicializando valores a partir de las settings del usuario */
    if (store.state.auth.loggedIn) {
      const settings = {
        locale:
          store.state.auth.user.settings.locale || store.state.settings.locale,
        currency:
          store.state.auth.user.settings.currency ||
          store.state.settings.currency,
      }

      await store.dispatch('settings/init', settings)
    }
  },
}
