const omappapi = () => {
  /* eslint-disable */
  if (typeof window !== 'undefined') {
    ;(function (d, u, ac) {
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.src = 'https://a.omappapi.com/app/js/api.min.js'
      s.async = true
      s.dataset.user = u
      s.dataset.account = ac
      d.getElementsByTagName('head')[0].appendChild(s)
    })(document, 42038, 47839)
  }
  /* eslint-enable */
}

export default omappapi
