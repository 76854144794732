import Vue from 'vue'

import { mapState } from 'vuex'

const Mixins = {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        ...mapState('auth', {
          isAuth: 'loggedIn',
        }),
        deviceId() {
          const nav = window.navigator
          const screen = window.screen
          let deviceId = nav.mimeTypes.length
          Object.values(navigator.mimeTypes).forEach(
            (p) => (deviceId += p.type)
          )
          deviceId += nav.userAgent.replace(/\D+/g, '') // Only use digits
          deviceId += nav.plugins.length
          Object.values(navigator.plugins).forEach(
            (p) => (deviceId += p.filename)
          )
          deviceId += screen.height || ''
          deviceId += screen.width || ''
          deviceId += screen.pixelDepth || ''

          const checksum = (s) => {
            let hash = 0
            const strlen = s.length
            let i
            let c

            if (strlen === 0) return hash

            for (i = 0; i < strlen; i++) {
              c = s.charCodeAt(i)
              hash = (hash << 5) - hash + c
              hash = hash & hash // Convert to 32bit integer
            }

            return Math.abs(hash)
          }

          return checksum(deviceId)
        },
      },
      methods: {
        LadNextTick(callback, name = '') {
          try {
            console.log('>', name)
            this.$nextTick(callback)
          } catch (error) {
            if (
              typeof this.$bugsnag === 'object' &&
              typeof this.$bugsnag.notify === 'function'
            ) {
              this.$bugsnag.notify(
                new Error(this._name || name ? `<${name}>` : 'undefined')
              )
            }

            setTimeout(() => callback(), 10)
          }
        },
      },
    })
  },
}

Vue.use(Mixins)
