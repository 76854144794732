<template>
  <component
    :is="componentPriceName"
    :price="price"
    :currency="currency"
    :promotions="promotions"
    :show-header="showHeader"
    :show-footer="showFooter"
    :parent-data="parentData"
    :product="product"
  />
</template>

<script>
import LadPriceInline from './components/price/inline'
import LadPriceWithPromo from './components/price/with-promo'

export default {
  name: 'LadPrice',
  components: { LadPriceInline, LadPriceWithPromo },
  props: {
    price: { type: Number, required: true },
    currency: { type: String, required: true },
    promotions: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/no-boolean-default
    showHeader: { type: Boolean, default: true },
    // eslint-disable-next-line vue/no-boolean-default
    showFooter: { type: Boolean, default: true },
    inline: { type: Boolean, default: false },
    /** revisar components/frontends/Price/components/mixin/promo.js */
    parentData: {
      type: Object,
      default: () => ({ date: null, season: null, language: null }),
    },
    product: { type: Object, default: () => ({ is_lad: false }) },
  },
  computed: {
    componentPriceName() {
      return this.inline ? 'LadPriceInline' : 'LadPriceWithPromo'
    },
  },
}
</script>
