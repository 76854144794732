export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  step: 'general_info',
  before_next_step: false /** controla cambio del paso actual */,
  wizard: [
    { key: 'general_info', icon: 'far fa-address-card' },
    { key: 'payment_methods', icon: 'fas fa-credit-card' },
  ],
  general_info: {
    form: {
      firstname: null,
      lastname: null,
      email: null,
      phone: { dial: null, number: null },
      dni: null,
      currency: null,
      locale: null,
    },
  },
  payment_methods: { current_method: '' },
  resume: {
    amount_due: 0,
    total_amount: 0,
    currency_code: null,
    /** revisar en `utils/mixin/checkout-data.js` si hay cambios */
    analytics: {
      items: [],
      promotions: [],
      coupons: [],
    },
  },
  disablePay: false,
  success: { complete: false, response: {} },
  termsAndConditions: false,
  isValidPaymentForm: false,
  processing: false,
})

export const mutations = {
  SET_DEFAULT: (state, data) => {
    state.step = data.step || 'general_info'

    state.before_next_step = false

    state.general_info.form = {
      firstname: null,
      lastname: null,
      email: null,
      phone: { dial: null, number: null },
      dni: null,
      currency: data.currency,
      locale: data.locale,
    }

    state.payment_methods.current_method = ''

    state.resume.amount_due = 0
    state.resume.total_amount = 0
    state.resume.currency_code = data.currency
    state.resume.analytics = { items: [], promotions: [], coupons: [] }

    state.disablePay = false
    state.success = { complete: false, response: {}, products: [] }

    state.processing = false
  },
  SET_STEP: (state, data) => {
    state.step = data
  },
  TOGGLE_BEFORE_NEXT_STEP: (state) => {
    state.before_next_step = !state.before_next_step
  },
  SET_AMOUNT_DUE: (state, data) => {
    state.resume.amount_due = parseFloat(data)
  },
  SET_CURRENCY_CODE: (state, data) => {
    state.resume.currency_code = data
  },
  SET_TOTAL_AMOUNT: (state, data) => {
    state.resume.total_amount = parseFloat(data)
  },
  SET_CURRENT_METHOD: (state, data) => {
    state.payment_methods.current_method = data
  },
  SET_SUCCESS: (state, data) => {
    state.success = data
  },
  SET_GENERAL_INFO_FORM: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (Reflect.has(state.general_info.form, key)) {
        state.general_info.form[key] = value
      }
    }
  },
  SET_ANALYTICS: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (typeof state.resume.analytics[key] !== 'undefined') {
        state.resume.analytics[key] = value
      }
    }
  },
  SET_DISABLE_PAY: (state, data) => {
    state.disablePay = data
  },
  SET_TERMS_ACCEPTED: (state, data) => {
    state.termsAndConditions = data
  },
  SET_VALID_PAYMENT_FORM: (state, data) => {
    state.isValidPaymentForm = data
  },
  SET_PROCESSING: (state, data) => {
    state.processing = data
  },
}

export const actions = {
  setDefault({ commit }, data = {}) {
    commit('SET_DEFAULT', data)
  },
  prevStep({ commit }, data) {
    commit('SET_STEP', data)
  },
  nextStep({ commit }, data) {
    commit('SET_STEP', data)
    commit('TOGGLE_BEFORE_NEXT_STEP')
  },
  setTotalAmount({ commit }, data) {
    commit('SET_TOTAL_AMOUNT', data)
  },
  setAmountDue({ commit }, data) {
    commit('SET_AMOUNT_DUE', data)
  },
  setCurrencyCode({ commit }, data) {
    commit('SET_CURRENCY_CODE', data)
  },
  setCurrentMethod({ commit }, data) {
    commit('SET_CURRENT_METHOD', data)
  },
  setSuccess({ commit }, data) {
    commit('SET_SUCCESS', data)
  },
  beforeNextStep({ commit }) {
    commit('TOGGLE_BEFORE_NEXT_STEP')
  },
  setGeneralInfoForm({ commit }, data) {
    commit('SET_GENERAL_INFO_FORM', data)
  },
  setAnalytics({ commit }, data) {
    commit('SET_ANALYTICS', data)
  },
  setDisablePay({ commit }, data) {
    commit('SET_DISABLE_PAY', data)
  },
  setTermsAccepted({ commit }, data) {
    commit('SET_TERMS_ACCEPTED', data)
  },
  setValidPaymentForm({ commit }, data) {
    commit('SET_VALID_PAYMENT_FORM', data)
  },
  setProcessing({ commit }, data) {
    commit('SET_PROCESSING', data)
  },
}
