const applicationLdjson = () => {
  /* eslint-disable */
  if (typeof window !== 'undefined') {
    ;(function (w, d) {
      let newScript = document.createElement('script')
      newScript.type = 'application/ld+json'

      const locale = (process.env.APP_LOCALE || 'es_mx').toLowerCase()
      const contactInfo = require(`@/db/contact/${locale}`).default
      const [lang, country] = process.env.APP_LOCALE.split('_')

      const json = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: `${process.env.APP_URL}`,
        logo: `${process.env.APP_URL}/img/web/mapmarker.png`,
        name: `${process.env.APP_NAME}`,
        email: contactInfo.customer_service.email,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: contactInfo.customer_service.phone,
          contactType: 'reservations',
          areaServed: country,
          availableLanguage: lang,
        },
        potentialAction: {
          '@type': 'SearchAction',
          target: `${process.env.APP_URL}/search?q={search_term_string}`,
          'query-input': 'required name=search_term_string',
        },
      }

      let inlineScript = document.createTextNode(JSON.stringify(json))
      let target = d.getElementsByTagName('script')[0]

      newScript.appendChild(inlineScript)
      if (target) {
        target.parentNode.insertBefore(newScript, target)
      }
    })(window, document)
  }
  /* eslint-enable */
}

export default applicationLdjson
