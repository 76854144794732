<template>
  <div v-if="enabled" class="wrapper--custom-loading">
    <LadRotateSquare2 />

    <div class="random-text">
      <p>{{ $t(`${base}.${source}.${currentKey}`) }}</p>
    </div>
  </div>
</template>

<script>
import LadRotateSquare2 from './components/rotate-square-2'

export default {
  name: 'LadCustomLoading',
  components: {
    LadRotateSquare2,
  },
  props: {
    source: { type: String, default: '' },
  },
  data() {
    return {
      base: 'custom_loading',
      keys: {
        search: ['one', 'two', 'three', 'four', 'five'],
      },
      index: 0,
      timer: null,
      enabled: false,
    }
  },
  computed: {
    currentKey() {
      return this.keys[this.source][this.index]
    },
  },
  beforeMount() {
    this.index = Math.floor(Math.random() * this.keys[this.source].length)
  },
  mounted() {
    document.querySelector('body').classList.add('overflow-h')

    setTimeout(() => (this.enabled = true), 300)

    this.timer = setInterval(() => {
      const length = this.keys[this.source].length - 1

      if (this.index < length) {
        this.index++
      } else {
        this.index = 0
      }
    }, 3000)
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('overflow-h')
    clearInterval(this.timer)
  },
}
</script>

<style lang="scss">
.wrapper--custom-loading {
  position: fixed;
  z-index: 2100;
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .random-text {
    max-width: 50%;
    margin-top: 10px;

    p {
      text-align: center;
      line-height: 1.4;
      font-style: italic;
    }

    @media (max-width: 768px) {
      max-width: 90%;

      p {
        font-size: 1.2em;
      }
    }
  }
}
</style>
