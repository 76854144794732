import {
  SEARCH_TYPE_ROUTER,
  SEARCH_COUNTRY_TYPE_ROUTER,
  // eslint-disable-next-line import/no-relative-parent-imports
} from '../../utils/static-router-alias'

const axios = require('axios')

/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default async (req, res, next) => {
  const language = process.env.APP_LOCALE.toLowerCase().split('_')[0]

  // Crear un objeto URL para facilitar la manipulación
  const parsedUrl = new URL(req.url, `http://${req.headers.host}`)

  // Obtener la ruta sin el dominio
  const sections = parsedUrl.pathname.split('/').reduce((acc, curr) => {
    if (curr !== '') {
      acc.push(curr)
    }
    return acc
  }, [])
  const firstSection = sections[0]

  const baseType =
    SEARCH_TYPE_ROUTER.base[firstSection] ||
    SEARCH_COUNTRY_TYPE_ROUTER.base[firstSection]

  const keys = [].concat(
    Object.keys(SEARCH_TYPE_ROUTER.groups),
    Object.keys(SEARCH_COUNTRY_TYPE_ROUTER.groups)
  )

  const groups = {
    ...SEARCH_TYPE_ROUTER.groups,
    ...SEARCH_COUNTRY_TYPE_ROUTER.groups,
  }
  const alias = {
    ...SEARCH_TYPE_ROUTER.alias[language],
    ...SEARCH_COUNTRY_TYPE_ROUTER.alias[language],
  }

  if (/\.(png|jpg|jpeg|svg)$/.test(parsedUrl.pathname)) {
    return next()
  } else if (keys.includes(baseType)) {
    let value = groups[baseType].includes(firstSection) ? sections[1] : null
    const regex = new RegExp('^\\d?\\d')

    if (baseType === 'country' && sections.length > 2) {
      /** version antigua de rutas de country; se debe hacer el redirect */
      const tabs = [
        Object.values(SEARCH_COUNTRY_TYPE_ROUTER.tabs.es),
        Object.values(SEARCH_COUNTRY_TYPE_ROUTER.tabs.en),
      ].flat()

      if (sections.length === 4) {
        /** cuando se tiene dias, se debe hacer el redirect */
        const days = sections[3].match(regex)
        let newLocation = `/search?type=${baseType}&value=${value}`

        if (days !== null) {
          newLocation = `/search?type=${baseType}&value=${value}&duration=${days[0]}&duration=${days[0]}`
        }

        res.writeHead(301, { Location: newLocation })
        return res.end()
      } else if (sections.length === 3 && tabs.includes(sections[2])) {
        /** cualquier tab que venga, sin importar el idioma */
        sections.splice(2)
      } else {
        /** cualquier ruta que no sea una ruta de tabs */
        res.writeHead(404, { Location: '/404' })
        return res.end()
      }
    } else if (baseType === 'country' && sections.length === 2) {
      /** version actual de rutas de country */

      const prefixesToReplace = [
        SEARCH_COUNTRY_TYPE_ROUTER.travelTo.en,
        SEARCH_COUNTRY_TYPE_ROUTER.travelTo.es,
      ]
      value = prefixesToReplace.reduce(
        (val, prefix) =>
          val.startsWith(prefix) ? val.replace(prefix, '') : val,
        sections[1]
      )
    } else {
      /** version antigua de rutas de country y actual de continent and city */
    }

    if (value === null) {
      res.writeHead(404, { Location: '/404' })
      return res.end()
    }

    // Initiate axios
    const instance = axios.create({
      baseURL: process.env.LAD_PRIVATE_API_BASE_URL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-localization': process.env.APP_LOCALE,
        'X-currency': process.env.APP_CURRENCY,
      },
    })

    let newLocation = null

    try {
      const {
        data: { data, status },
      } = await instance.post('/api/settings/validate-slug', {
        type: baseType,
        slug: value,
      })

      if (status === 'success') {
        let withChanges = false

        /**
         * se busca que un slug no este en el idioma actual
         * y se cambie el idioma a la que corresponde; se excluye APP_URL_EN_US que acepta ambos idiomas
         * tambien puede darse el caso de un slug legacy por lo que debe cambiarse a la version actual
         */
        if (process.env.APP_URL === process.env.APP_URL_EN_US) {
          if (!Object.values(data.current).includes(value)) {
            withChanges = true
          }
        } else if (data.current.default !== value) {
          withChanges = true
        }

        if (withChanges) {
          sections[0] = firstSection

          if (baseType === 'country' && sections.length === 2) {
            /** version actual de rutas de country */
            sections[1] = `${SEARCH_COUNTRY_TYPE_ROUTER.travelTo[language]}${data.current.default}`
          } else {
            sections[1] = data.current.default
          }
        } else if (
          sections[1] !==
          `${SEARCH_COUNTRY_TYPE_ROUTER.travelTo[language]}${value}`
        ) {
          if (baseType === 'country' && sections.length === 2) {
            sections[1] = `${SEARCH_COUNTRY_TYPE_ROUTER.travelTo[language]}${value}`
          }
        }

        /**
         * se busca que las urls de dias tenga un formato correcto
         * y se elimina el ultimo elemento si NO es un dia
         */
        if (sections.length === 4) {
          const days = sections[3].match(regex)

          if (days === null) {
            sections.splice(sections.length - 1, 1)
          }
        }

        /**
         * se busca que la base de la url sea la misma que la que corresponde
         * y se cambia la base de la url a la que corresponde
         */
        if (
          process.env.APP_URL !== process.env.APP_URL_EN_US &&
          alias[baseType] !== firstSection
        ) {
          sections[0] = alias[baseType]
        }

        sections.unshift('')

        newLocation = sections.join('/')
      }
    } catch (error) {}

    if (newLocation !== null && newLocation !== parsedUrl.pathname) {
      res.writeHead(301, { Location: `${newLocation}${parsedUrl.search}` })
      return res.end()
    }

    return next()
  } else {
    return next()
  }
}
