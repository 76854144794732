/** @todo refactorizar para que quede igual que los demas plugins antiguos */
export default ({ app }, inject) => {
  const kiwi = {
    init: (settings, v = 'v1') => {
      ;((d, s, id) => {
        let fjs = d.getElementsByTagName(s)[0]

        const exist = d.getElementById(id)
        if (exist) {
          exist.parentNode.removeChild(exist)
        }
        fjs = d.getElementsByTagName(s)[0]

        const js = d.createElement(s)
        js.id = id
        js.src = `https://${
          v === 'v1' ? 'widget' : 'widgets'
        }.kiwi.com/scripts/widget-search-iframe.js?v=${Math.random()}`
        js.async = true

        /** set widget */
        js.setAttribute('data-affilid', process.env.KIWI_AFFIL_ID)
        js.setAttribute('data-apigee-key', process.env.KIWI_KEY)

        /** add settings */
        Object.keys(settings).forEach((key) => {
          js.setAttribute(`data-${key}`, settings[key])
        })

        js.setAttribute('data-width', '100%')

        setTimeout(() => {
          if (fjs) {
            fjs.parentNode.insertBefore(js, fjs)
          }
        }, 200)
      })(document, 'script', 'kiwi-script-src')
    },
  }

  inject('kiwi', kiwi)
}
