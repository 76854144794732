export default async ({ app, isHMR, route, store, redirect, params }) => {
  if (
    /* isHMR ||
    process.static ||
    process.server || */
    !/^(user|public)-reservation(.*)/.test(route.name)
  )
    return

  const reservation = {
    user: '/user/reservation/',
    public: '/public/reservation/',
  }
  const url = route.fullPath
  const separator = url.includes('?') ? '&' : '?'
  const isAuth = await store.state.auth.loggedIn

  if (isAuth) {
    if (route.path.startsWith(reservation.public)) {
      let newUrl = url.replace(reservation.public, reservation.user)
      newUrl += `${separator}redirect_from=public`

      redirect(newUrl)
    }
  } else if (route.path.startsWith(reservation.user)) {
    let newUrl = url.replace(reservation.user, reservation.public)
    newUrl += `${separator}redirect_from=private`

    redirect(newUrl)
  }
}
