import Cookies from 'js-cookie'

const TokenKey = 'auth._token.custom-login'

export function getToken(name = null) {
  return Cookies.get(name || TokenKey)
}

export function setToken(token, name = null) {
  return Cookies.set(name || TokenKey, token)
}

export function removeToken(name = null) {
  return Cookies.remove(name || TokenKey)
}

export function hasToken(name = null) {
  return !!getToken(name)
}
