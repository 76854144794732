import load from '@/utils/load-promise'

const zohoWidgetPlugin = (ctx, inject) => {
  const zohoWidget = {
    init: (settings) => {
      window.$zoho = window.$zoho || {}
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode:
          'siq54ceb806e0f8a34c652ce880d2c1b65bfb18a660157bcb3b15c1520112cbb7e0',
        values: {},
        ready() {},
      }
    },
    load: (settings) => {
      zohoWidget.init(settings)

      const list = [
        load.js('https://salesiq.zohopublic.com/widget', 'zsiqscript'),
      ]

      Promise.all(list)
        .then(() => {})
        .catch(() => {})
    },
    initForm: (settings) => {
      try {
        const f = document.createElement('iframe')
        f.src = `https://forms.zohopublic.com/localadventures/form/${settings.src}&zf_lang=${settings.lang}`
        f.frameBorder = 0
        f.align = 'top'

        const d = document.querySelector(`#${settings.formId}`)
        d.appendChild(f)
      } catch (e) {}
    },
    resizeForm: (event, formId) => {
      if (typeof event.data === 'string' && (event.data || '').includes('|')) {
        // eslint-disable-next-line camelcase
        const zf_ifrm_data = event.data.split('|')
        // eslint-disable-next-line camelcase
        const zf_perma = zf_ifrm_data[0]
        // eslint-disable-next-line camelcase
        const zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + 'px'
        const iframe = document
          .getElementById(formId)
          .getElementsByTagName('iframe')[0]

        if (
          iframe.src.indexOf('formperma') > 0 &&
          iframe.src.indexOf(zf_perma) > 0
        ) {
          const prevIframeHeight = iframe.style.height
          // eslint-disable-next-line camelcase
          if (prevIframeHeight !== zf_ifrm_ht_nw) {
            // eslint-disable-next-line camelcase
            iframe.style.height = zf_ifrm_ht_nw
          }
        }
      }
    },
    visitor: (email) => {
      try {
        window.$zoho.salesiq.visitor.email(email)
      } catch (error) {}
    },
  }

  inject('zohoWidget', zohoWidget)
}

export default zohoWidgetPlugin
