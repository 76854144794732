var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-wrapper",class:_vm.classObj},[(_vm.loading.enabled)?_c('LadCustomLoading',_vm._b({},'LadCustomLoading',_vm.loading,false)):_vm._e(),_vm._v(" "),(_vm.isValidOneTap)?_c('div',_vm._b({attrs:{"id":"g_id_onload"}},'div',_vm.googleOneTap,false)):_vm._e(),_vm._v(" "),(!_vm.isOnline)?_c('div',{staticClass:"wrapper-pwa-offline"},[_c('el-alert',{attrs:{"title":_vm.$t('pwa.offline'),"type":"error","closable":false,"show-icon":""}})],1):_vm._e(),_vm._v(" "),_c('LazyLoading',{key:`sidebar`,attrs:{"loaded":_vm.lazy.sidebar},on:{"loaded":(e) => (_vm.lazy.sidebar = e)}},[(_vm.lazy.sidebar)?_c('LadSidebar'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"main-container"},[(!(_vm.isMobile && _vm.isLandingNavbar))?_c('LadNavbar'):_vm._e(),_vm._v(" "),(_vm.enableCountDown || _vm.isPpcv1)?_c('LadCountDown',_vm._b({attrs:{"route-name":_vm.routeName}},'LadCountDown',{ builder: _vm.builder, ..._vm.advertisement },false)):(_vm.enableCountDownReservation)?_c('LadCountDown',{attrs:{"builder":_vm.countDownOrder,"cta":_vm.orderDeathLine.cta,"route-name":_vm.routeName}}):_vm._e(),_vm._v(" "),_c('section',{staticClass:"app-main",attrs:{"id":"app-main"}},[_c('Nuxt')],1),_vm._v(" "),_c('LadFooter'),_vm._v(" "),(_vm.isMobile && !_vm.isIOS && !_vm.isLandingNavbar)?[_c('LadPromptPermission'),_vm._v(" "),_c('LadPromptInstall')]:_vm._e(),_vm._v(" "),(
        _vm.isMobile &&
        (_vm.visibleBottomNavbar ||
          _vm.isBottomNavbarPwa ||
          _vm.isPpcv1 ||
          _vm.isSearch ||
          _vm.isCountry)
      )?[((_vm.isExperienceNavbar  || _vm.isPpcv1))?_c('LadExperienceNavbarSummary'):(!_vm.isMobile && _vm.isLandingNavbar)?_c('LadLandingNavbarSummary'):(_vm.isCheckoutNavbar && !_vm.checkSuccessComplete)?_c('LadCheckoutNavbarSummary',{attrs:{"is-checkout-layaway":_vm.isCheckoutLayaway}}):(_vm.isShoppingCart)?_c('LadShoppingNavbarSummary'):(_vm.isSearch)?_c('LadFiltersNavbarSummary'):(_vm.isCountry)?_c('LadCountryCta'):_c('LadBottomNavbar',[(_vm.isReservationEditNavbar)?_c('LadReservationEditNavbar'):_vm._e()],1)]:_vm._e()],2),_vm._v(" "),(_vm.isAuthModalOpen)?_c('LadAuthModal'):_vm._e(),_vm._v(" "),(_vm.isTripsModalOpen)?_c('LadTripsModal'):_vm._e(),_vm._v(" "),(_vm.isFindMyTourModalOpen)?_c('LadSearchMyTour'):_vm._e(),_vm._v(" "),(_vm.isCheckoutNavbar)?[_c('LadPaymentAtratoWrapper')]:_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"fb-root"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }