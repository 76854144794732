import AES from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'
import SHA256 from 'crypto-js/sha256'

const secretKey = process.env.SECRET_CRYPTO_KEY

export function encrypt(object) {
  return AES.encrypt(JSON.stringify(object), secretKey).toString()
}

export function decrypt(encrypted) {
  return JSON.parse(AES.decrypt(encrypted, secretKey).toString(enc))
}

export function encryptSha256(data) {
  Object.keys(data).forEach((key) => (data[key] = SHA256(data[key]).toString()))

  return data
}
