export default {
  customer_service: {
    whatsapp: ['+52 55 8525 9520'],
    phone: '+52 55 5351 0884',
    email: 'clientes@localadventures.mx',
    schedules: ['Lun - Dom 10am - 7pm'],
  },
  sales: {
    whatsapp: ['+52 55 8525 9520'],
    phone: '+52 55 5351 0884',
    email: 'info@localadventures.mx',
    schedules: ['Lun - Dom 9am - 8pm'],
  },
  address: {
    company: 'EMX EMPREMEXICO SAPI DE CV',
    address: `AV. Ignacio L. Vallarta 3155,<br />Vallarta Poniente,<br />Guadalajara,<br />Jalisco,<br />CP:44110`,
  },
  country: {
    alpha_3_code: 'mex',
  },
  toky: {
    emergency:
      'https://app.toky.co/app/09c9f44f-76c0-11eb-865c-065a460efe03/41571',
  },
}
