import currencyJS from 'currency.js'
import { v4 } from 'uuid'

export function ladFnCapitalize(value) {
  const text = (value ? value.toString().toLowerCase().trim() : '').split('')

  return text
    .map((letter, i) => (i === 0 ? letter.toUpperCase() : letter), '')
    .join('')
}

/** filtro para convertir un valor en precio con currency */
export function ladFnCurrency(
  value,
  currency = '',
  format = true,
  symbol = '$',
  precision = 2
) {
  const params = { separator: ',', decimal: '.', symbol, precision }
  const amount = typeof value === 'string' ? parseFloat(value) : value

  switch (currency) {
    case 'COP':
      Object.assign(params, { separator: '.', decimal: ',' })
      break
    case 'USD':
    case 'MXN':
    default:
      break
  }

  return format
    ? currencyJS(amount, params).format(true)
    : currencyJS(amount, params)
}

/** filtro para convertir un valor en formato */
export function ladFnNumber(value, currency = '') {
  return ladFnCurrency(value, currency, true, '', 0)
}

/** imprime una fecha en el formato definido */
export function ladFnStrDate(
  date,
  format = 'MMMM DD, YYYY',
  defaultResponse = ''
) {
  let value =
    date && typeof date === 'object' && date.isValid()
      ? ladFnCapitalize(date.format(format).replace('.', ''))
      : defaultResponse === ''
      ? date
      : defaultResponse
  value = typeof value === 'object' ? '' : value

  return value
}

export function tabScrollLeft(ref, currentTab) {
  if (ref) {
    Array.from(ref.$el.querySelectorAll('.el-tabs__nav')).forEach((wrapper) => {
      let scrollLeft = 0
      let totalWidth = 0
      let hasId = false

      Array.from(ref.$el.querySelectorAll('.el-tabs__item')).forEach((t) => {
        totalWidth += t.offsetWidth

        if (!hasId) {
          hasId = t.id.endsWith(currentTab)
          scrollLeft += parseInt(t.offsetWidth * 1.1)
        }
      })

      let proportion = scrollLeft / totalWidth
      const percent = proportion > 0.8 ? proportion : 0.5
      let scroll = scrollLeft * percent

      const toLeft = wrapper.scrollLeft > 0 && scroll < wrapper.scrollLeft
      const toRight = scrollLeft > ref.$el.offsetWidth && !toLeft
      if (toRight || toLeft) {
        /** cuando se va moviendo a la izquierda */
        if (toLeft) {
          proportion = scroll / wrapper.scrollLeft
          scroll = scrollLeft * (1 - proportion)
        }

        wrapper.scrollLeft = scroll
      } else if (wrapper.scrollLeft > 0) {
        wrapper.scrollLeft = 0
      }
    })
  }
}

export function phoneHref(phone) {
  return `tel:${phone.replace(/[^0-9.+]/g, '')}`
}

export function emailHref(email) {
  return `mailto:${email}`
}

export function whatsappHref(phone) {
  return `https://wa.me/${phone.replace(/[^0-9.]/g, '')}`
}

export function whatsappCustomerHappinessHref(phone, message) {
  return `https://wa.me/${phone.replace(/[^0-9.]/g, '')}?text=${message}`
}

export function uuidv4() {
  return v4()
}
/** https://stackoverflow.com/a/2117523 */
// export function uuidv4() {
//   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
//     (
//       c ^
//       (Crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
//     ).toString(16)
//   )
// }

export function downloadFile({ url = null, blob = null }, fileName) {
  const link = document.createElement('a')

  link.href = url || URL.createObjectURL(blob)
  link.download = fileName
  link.target = '_blank'

  document.body.append(link)

  link.click()
  link.remove()

  window.addEventListener(
    'focus',
    (e) => {
      return URL.revokeObjectURL(link.href)
    },
    { once: true }
  )
}

/** convierte un objeto a un query string */
export function objet2qs(object) {
  return Object.entries(object)
    .map(([key, value]) =>
      Array.isArray(value)
        ? `${key}=${value.join('&' + key + '=')}`
        : `${key}=${value}`
    )
    .join('&')
}

/** Elimina etiquetas de HTML dentro de un string */
export function toText(html) {
  if (html === null || html === '') return false
  else html = html.toString()
  return html.replace(/(<([^>]+)>)/gi, '')
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * define si un objeto tiene una secuencia de properties
 *
 * https://gist.github.com/pianosnake/d8525fffd1282af66a011842f472d174
 * */
export function hasOwnProperties(target, path, value) {
  /** haciendo validaciones para cuando es null o no es un obj */
  if (
    typeof target !== 'object' ||
    target === null ||
    typeof target === 'undefined' ||
    !path
  )
    return false

  /** buscando recursivo por listado de properties */
  let exist = true
  try {
    const pathList = path.split('.')
    const totalSubpath = pathList.length - 1

    pathList.forEach((property, i) => {
      exist = exist && Reflect.has(target, property)

      if (!exist) throw new Error('StopIteration')
      /** actualizando pivote */
      target = target[property]

      /** haciendo validaciones para cuando es null o undefined los path intermedios */
      if (
        i < totalSubpath &&
        (target === null || typeof target === 'undefined')
      ) {
        exist = false
        throw new Error('StopIteration')
      }
    })
  } catch (e) {}

  return value ? target === value : exist
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}
