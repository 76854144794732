/* eslint-disable */

export default (ctx, inject) => {
  /**
   * Only run on client-side and only in production mode
   */
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }

  gtag('js', new Date())
  let gaData = {
    optimize_id: 'GTM-N9PV7LC',
  }

  /** adicionando usuario autenticado al GA */
  const currentUserAuth = ctx.store.getters.currentUserAuth
  if (!!currentUserAuth) {
    gaData.user_id = currentUserAuth.uuid
  }

  gtag('config', 'UA-65738414-4', gaData) /** GA_MEASUREMENT_ID */
  gtag('config', 'AW-827890211')
  gtag('config', 'AW-10997110392')

  inject('gtag', gtag)

  /**
   * Every time the route changes (fired on initialization too)
   */
  // ctx.app.router.afterEach((to, from) => {})
}

/* eslint-enable */
