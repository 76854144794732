import { hasOwnProperties, ladFnCapitalize } from '@/utils'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({
  availability: {
    start: null, // fecha minima de disponibilidad
    end: null, // fecha maxima de disponibilidad
    total: 0, // total de meses donde hay disponibilidad
    ranges: [], // { current: true, items: [{ id: 1, name: '', availability: [] }], item: 0 }
    bestPrice: {
      item: {
        days: 0,
        layaway: 0,
        liquidate_before: 0,
        datetime: {
          start: '',
          end: '',
        },
      },
    },
  },
  availabilityByCode: {},
  exist: false,
  recommended_dates: [],
  holidays: [],
  hasRecommendedAvailability: false,
})

export const mutations = {
  /** @todo lanzar un `SET_DEFAULT` cuando a la experiencia se le haga el `SET_DEFAULT` */
  SET_DEFAULT: (state) => {
    state.availability = {
      start: null, // fecha minima de disponibilidad
      end: null, // fecha maxima de disponibilidad
      total: 0, // total de meses donde hay disponibilidad
      ranges: [],
      bestPrice: {
        item: {
          days: 0,
          layaway: 0,
          liquidate_before: 0,
          datetime: {
            start: '',
            end: '',
          },
        },
      },
    }

    state.availabilityByCode = {}

    state.exist = false

    state.recommended_dates = []
    state.holidays = []

    state.hasRecommendedAvailability = false
  },

  SET_AVAILABILITY: (state, data) => {
    state.availability = data
  },
  SET_AVAILABILITY_BY_CODE: (state, data) => {
    state.availabilityByCode = {
      ...state.availabilityByCode,
      [data.code]: data.availabilities,
    }
  },
  SET_CURRENT_AVAILABILITY: (state, id) => {
    // quitando current
    state.availability.ranges.forEach((range) => {
      if (range.current) {
        range.current = false
        range.item = 0
      }

      const isCurrent = range.items.find((item) => item.id === id)
      if (isCurrent) {
        range.current = true
        range.item = id
      }
    })
  },
  SET_RECOMMENDED_DATES: (state, data) => {
    state.recommended_dates = data
  },
  HAS_AVAILABILITY: (state, data) => {
    state.exist = data
  },

  SET_HOLIDAYS: (state, data) => {
    state.holidays = data
  },

  SET_HAS_RECOMMENDED_AVAILABILITY: (state, data) => {
    state.hasRecommendedAvailability = data
  },
}

export const getters = {}

export const actions = {
  setDefault({ commit }) {
    commit('SET_DEFAULT')
  },
  setCurrent({ commit }, data) {
    commit('SET_CURRENT_AVAILABILITY', data.id)
  },
  async getData({ commit }, pdata) {
    const processData = (response) => {
      const data = response.availability || response.data.availability
      const format = 'YYYY-MM-DD'
      const bestPrice = {
        item: {
          days: 0,
          layaway: 0,
        },
      }

      data.forEach((item, i) => {
        const start = dayjs(item.datetime.start, format)
        const end = dayjs(item.datetime.end, format)

        // obtengo la diferencia de dias que va a ser la duracion de la experiencia
        const diffDays = dayjs.duration(end.diff(start)).asDays() + 1
        item.days = Math.floor(diffDays)
        item.open = false

        item.layaway = parseFloat(parseFloat(item.layaway).toFixed(2))
        item.price = parseFloat(parseFloat(item.price).toFixed(2))

        if (
          item.type === 'recommended' &&
          (bestPrice.item.layaway === 0 ||
            bestPrice.item.layaway > item.layaway)
        ) {
          bestPrice.item = item
        }
      })

      // ordeno por la fecha de inicio de menor a mayor
      data.sort(
        (a, b) => new Date(a.datetime.start) - new Date(b.datetime.start)
      )

      const months = { start: null, end: null, total: 0, ranges: [] }
      const recommended = []

      // si existen fechas disponibles
      if (data.length) {
        months.start = data[0].datetime.start
        months.end = data[data.length - 1].datetime.start

        let totalMonths =
          dayjs(months.end).diff(dayjs(months.start), 'month') + 1

        let first = true
        let month = dayjs(months.start).startOf('month')
        let monthLimit = month.endOf('month')
        let id = 0
        const limitRange = [1]

        do {
          // generando rango
          const range = limitRange.reduce((list, i) => {
            // obtengo listado de disponibilidad por cada mes
            const availability = data.filter((item) => {
              return dayjs(item.datetime.start).isBetween(
                month,
                monthLimit,
                null,
                '[]'
              )
            })

            // creo el nuevo rango de elementos con id, nombre y listado de disponibilidad
            const name = month.format('MMMM YYYY').replace('.', '')
            if (availability.length > 0) {
              list = list.concat([
                { id, name: ladFnCapitalize(name), availability },
              ])
            }

            month = month.add(i, 'month')
            monthLimit = month.endOf('month')
            id++

            return list
          }, [])

          // total de meses donde hay disponibilidad
          const availabilityTotalRange = range.reduce((total, r) => {
            total += r.availability.length

            return total
          }, 0)

          // si no hay disponibilidad en el rango actual no se adiciona nada
          if (availabilityTotalRange) {
            months.ranges.push({
              current: first,
              items: range,
              item: first ? range[0].id : -1,
            })
          }

          first = false
          totalMonths -= limitRange.length
        } while (totalMonths >= 0)

        // total de meses donde hay disponibilidad
        months.total = months.ranges.reduce((total, range, x) => {
          total += range.items.length

          // seleccionando fechas marcadas como recomendadas
          range.items.forEach((item, y) => {
            item.availability.forEach((a, z) => {
              if (a.type === 'recommended') {
                recommended.push({ x, y, z })
              }
            })
          })

          return total
        }, 0)
      }

      commit('HAS_AVAILABILITY', months.total > 0)
      commit('SET_HAS_RECOMMENDED_AVAILABILITY', recommended.length > 0)

      commit('SET_AVAILABILITY', { ...months, bestPrice })
      commit('SET_RECOMMENDED_DATES', recommended.slice(0, 3))
    }

    if (hasOwnProperties(pdata, 'experience')) {
      if (!hasOwnProperties(pdata, 'withRestriction')) {
        pdata.withRestriction = false
      }
      if (!hasOwnProperties(pdata, 'withCodes')) {
        pdata.withCodes = false
      }

      try {
        const url = `/api/v1.5/experiences/show/${pdata.experience}/availability/${pdata.withRestriction}/${pdata.withCodes}`
        const response = await this.$axios.get(url)

        processData(response)
      } catch (error) {}
    } else if (hasOwnProperties(pdata, 'availability')) {
      processData(pdata)
    }
  },
  async getAvailabilityByCode({ commit }, data) {
    const processData = (response, code) => {
      const availabilities = response.availability || response.data.availability
      const format = 'YYYY-MM-DD'

      availabilities.forEach((item, i) => {
        const start = dayjs(item.datetime.start, format)
        const end = dayjs(item.datetime.end, format)

        // obtengo la diferencia de dias que va a ser la duracion de la experiencia
        const diffDays = dayjs.duration(end.diff(start)).asDays() + 1
        item.days = Math.floor(diffDays)
        item.open = false

        item.layaway = parseFloat(parseFloat(item.layaway).toFixed(2))
        item.price = parseFloat(parseFloat(item.price).toFixed(2))
      })

      // ordeno por la fecha de inicio de menor a mayor
      availabilities.sort((a, b) => new Date(a) - new Date(b))

      commit('SET_AVAILABILITY_BY_CODE', {
        availabilities,
        code,
      })
    }

    try {
      const url = `/api/v1.5/experiences/tourradar/${data.permalink}/availability/${data.code}`
      const response = await this.$axios.get(url)

      processData(response, data.code)
    } catch (error) {}
  },
  async getDataWihtHolidays({ commit }, pdata) {
    try {
      const url = `/api/v1.5/experiences/show/${pdata.experience}/availability-with-holidays`
      const { data, status } = await this.$axios.get(url)

      if (status === 'success') {
        commit('SET_HOLIDAYS', data.holidays || [])
      }
    } catch (error) {}
  },
  hasAvailability({ commit }, data) {
    commit('HAS_AVAILABILITY', data)
  },
  hasRecommendedAvailability({ commit }, data) {
    commit('SET_HAS_RECOMMENDED_AVAILABILITY', data)
  },
}
