export default {
  computed: {
    /** si se aplica la primera promocion */
    promotion() {
      const promo = { ...this.promotions[0] } || { value: 0, rules: [] }

      // eslint-disable-next-line no-prototype-builtins
      if (!promo.hasOwnProperty('rules')) promo.rules = []

      /** estas reglas no son necesarias aqui */
      promo.rules = promo.rules.filter(
        (rule) =>
          ![
            'promotion_rule_currencies',
            'promotion_rule_by_platforms',
          ].includes(rule.type)
      )

      return promo
    },
    /** define el tipo de promocion que se muestra */
    promoType() {
      let unit = 'promo_general'

      const rulesTicketsQty = this.promotion.rules.find(
        (t) => t.type === 'promotion_rule_tickets_qty'
      )
      const rulesTicketsMultiples = this.promotion.rules.find(
        (t) => t.type === 'promotion_rule_tickets_multiples'
      )
      const rulesDates = this.promotion.rules.find((t) =>
        [
          'promotion_rule_dates',
          'promotion_rule_by_date_seasons',
          'promotion_rule_by_date_languages',
        ].includes(t.type)
      )

      if (rulesTicketsQty) {
        if (rulesTicketsMultiples) {
          /** promo estilo 2x1, 3x1, 4x3 */
          unit = `promo_${rulesTicketsQty.condition}x1`

          if (rulesDates) {
            unit += '_dates'
          }
        }
      }

      if (unit === 'promo_general' && !!rulesDates) {
        unit = 'promo_dates'
      }

      return unit
    },
  },
  methods: {
    validateDate({ date, season, language }) {
      let isValid = false

      this.promotion.rules.forEach((rule, i) => {
        /** si la primera regla no es valida, no tiene sentido seguir haciendo validaciones */
        if (i > 0 && !isValid) return

        switch (rule.type) {
          case 'promotion_rule_dates':
            switch (rule.operator) {
              case 'equal':
                isValid = rule.condition.includes(date)
                break
              default:
                break
            }
            break
          case 'promotion_rule_by_date_seasons':
            switch (rule.operator) {
              case 'equal':
                isValid = rule.condition.includes(season)
                break
              default:
                break
            }
            break
          case 'promotion_rule_by_date_languages':
            switch (rule.operator) {
              case 'equal':
                isValid = rule.condition.includes(language)
                break
              default:
                break
            }
            break
          default:
            break
        }
      })

      return isValid
    },
  },
}
