import { hasOwnProperties } from '@/utils'

/** leyendo fichero para enviar a API */
export function readUploadedFileBase64(inputFile) {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    reader.onload = () => {
      const extension = inputFile.name.toLowerCase().split('.')

      resolve({
        name: inputFile.name,
        type: inputFile.type ? inputFile.type : 'image/jpeg',
        extension: extension.length === 2 ? extension[1] : 'jpg',
        base64: reader.result,
      })
    }
    reader.readAsDataURL(inputFile)
  })
}

export function getMessageFromResponse(error) {
  const message = hasOwnProperties(error, 'response.data')
    ? error.response.data.message
    : error.message

  return message
}
