export default {
  customer_service: {
    whatsapp: ['+57 60 1514 0338'],
    phone: '+57 60 1744 2382',
    email: 'clientes@localadventures.travel',
    schedules: ['Lun - Dom 10am - 7pm'],
  },
  sales: {
    whatsapp: ['+57 60 1514 0338'],
    phone: '+57 60 1744 2382',
    email: 'info@localadventures.travel',
    schedules: ['Lun - Dom 9am - 8pm'],
  },
  address: {
    company: 'EMX EMPREMEXICO SAPI DE CV',
    address: `AV. Ignacio L. Vallarta 3155,<br />Vallarta Poniente,<br />Guadalajara,<br />Jalisco,<br />CP:44110`,
  },
  country: {
    alpha_3_code: 'col',
  },
  toky: {
    emergency:
      'https://app.toky.co/app/09c9f44f-76c0-11eb-865c-065a460efe03/41571',
  },
}
