export default async ({ isHMR, store, route }) => {
  // if (isHMR || process.static) return

  /** es posible que llegue por parametros el step y los tickets */
  const data = Object.assign({
    currency: store.state.settings.currency,
    locale: store.state.settings.locale,
  })

  await store.dispatch('checkout/setDefault', data)
}
